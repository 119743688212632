import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from './Sidebars/LeftSidebar';

const FAQWrapper = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  max-width: 60em;
  font-size: .85em;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: .5rem;
`

const Content = styled.div`
  font-size: 1em;
`

const Question = styled.div`
  font-weight: bold;
  padding-top: 75px;
  margin-top: -75px;
`

const Answer = styled.div`
  margin-bottom: 1.5em;
`

const FAQ = () => {
  const pageTitle = "Oracle's Elixir - Frequently Asked Questions";
  const pageDescription = "Answers to common questions about Oracle's Elixir's esports stats and coverage.";

  return (
    <>
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TimSevenhuysen" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
    <LeftSidebar />
    <FAQWrapper className="mainContent">
      <Title>
        Frequently Asked Questions
      </Title>
      <div style={{marginBottom: "1em", fontSize: ".9em"}}>
        <em>Have a question that isn't listed here? <a href="https://discord.gg/KY6XHaF" target="_blank" rel="noopener noreferrer"> Ask on the Oracle's Elixir Discord server</a></em>.
      </div>
      <Content>
        <Question id='mismatches'>
          I saw a stat on [insert broadcast/website/article here], and the numbers at OraclesElixir.com didn't match! What's wrong?
        </Question>
        <Answer>
          I am very confident in the accuracy of the data on <em>Oracle's Elixir</em>. If I ever suspect a possible error,
          I avoid using the affected numbers, and I drop everything and work to diagnose and solve the problem.
          <br /><br />
          That said, the most common reason you might see non-matching numbers on, say, an LCS broadcast, is that the broadcasts
          calculate some of their numbers differently than I do. (The definitions for the stats I report can be found on
          the <Link to="/definitions">Definitions page</Link>.) I use averages in some stats, to reduce the influence of game
          length, while the LCS broadcasts do not. For example, I calculate wards per minute (WPM) and Damage Share (DMG%) by
          separately calculating a player's WPM and Dmg% for each individual game, then averaging across all games. In the past,
          LCS broadcasts have based their DMG% on adding up the totals of all games, then dividing, which causes longer games to
          influence the results more than shorter games. Neither approach is inherently correct or incorrect, but it can lead to
          small differences.
        </Answer>

        <Question id='addmore'>
          Can you add this [league/season/statistic] to the site and/or match data downloads?
        </Question>
        <Answer>
          Oracle's Elixir covers virtually every professional League of Legends competition in the world. If you are interested
          in stats for a league that you can't find on the site, feel free to ping me and ask about it, preferably with a link
          to the league's Leaguepedia page!
          <br /><br />
          Adding more variables to the stats tables and/or downloads is a question of balancing value against usability.
          I try to keep the stats tables and CSVs from becoming too bloated or difficult to navigate. Feedback is very important
          here, too, so if you have a strong interest in a certain stat, jump into the{" "}
          <a href="https://discord.gg/KY6XHaF" target="_blank" rel="noopener noreferrer">OE Discord</a> and bring it up!
        </Answer>

        <Question id='datasources'>
          Where does Oracle's Elixir's data come from?
        </Question>
        <Answer>
          I compile data from several different sources, including Match History pages,{" "}
          <a href="https://lolesports.com" target="_blank" rel="noopener noreferrer">lolesports.com</a>,{" "}
          <a href="https://lpl.qq.com" target="_blank" rel="noopener noreferrer">lpl.QQ.com</a>,{" "}
          <a href="https://lol.fandom.com" target="_blank" rel="noopener noreferrer">Leaguepedia</a>,{" "}
          the Riot Games solo queue APIs, and more.
        </Answer>

        <Question id='datasources'>
          What tech stack does Oracle's Elixir run on?
        </Question>
        <Answer>
          My data collection pipelines and statistical modeling are written primarily in Python.
          <br /><br />
          I use MariaDB for my databases, connected to an Express API (Node.js). The website itself
          is built using React.js. The full stack is deployed to AWS.
        </Answer>

      </Content>
    </FAQWrapper>
    </>
  )
}

export default FAQ;
