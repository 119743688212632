import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from '../Sidebars/LeftSidebar';
import PlayerSearch from './PlayerSearch';
import PlayerRow from './PlayerRow';
import ColumnHeader from './ColumnHeader';

import SpinnerComponent from "../Shared/Spinners";

const Wrapper = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  max-width: 60em;
`;

const Title = styled.div`
  margin-bottom: 0.1em;
  font-size: 1.2em;
  font-weight: bold;
`

const Content = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

const PlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .85em;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  overflow-x: hidden;
  min-height: 1.3em;
  margin-top: 0.5em;
  margin-right: ${(props) =>
    props.safari === true
      ? "0"
      : "17px"}; // To sync with scrollbar width of table below
  font-size: .85em;

  @media (max-width: 768px) {
    overflow-y: scroll;
    margin-right: 0;
  }
`;

const PlayerListContainer = styled.div`
  max-height: 35em;
  overflow-y: scroll;
`

const PlayerListEmptyContainer = styled.div`
  height: 15em;
  line-height: 15em;
  font-size: 1.2em;
  text-align: center;
`

const Players = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const searchTerm = props.match.params.search;
  const playersInView = props.AppStore.playersInView;
  const playersAreFetching = props.AppStore.playersAreFetching.get();

  const columns = ['name', 'realName', 'position', 'teamName', 'residency'];

  useEffect(() => {
    if ((searchTerm && !(searchTerm in props.AppStore.playerSearchesFetched)) && !playersAreFetching) {
      props.AppStore.fetchPlayers(searchTerm);
    } else if ((searchTerm && (searchTerm in props.AppStore.playerSearchesFetched))) {
      props.AppStore.setPlayersInView(props.AppStore.playerSearchesFetched[searchTerm])
    }
  }, [
    props.AppStore,
    searchTerm,
    playersAreFetching,
    playersInView
  ]);

  const pageTitle = "Players - Oracle's Elixir";
  const pageDescription = "Find players from the LCS, LEC, LCK, LPL, and the rest of global League of Legends.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <Title>
          Players
        </Title>
        <Content>
          <PlayerSearch />
          {searchTerm ? (
            (playersAreFetching) ? (
              <SpinnerComponent />
            ) : (
                <>
                  <PlayersContainer>
                    {(!playersAreFetching && playersInView) && (
                      <>
                      <Header safari={isSafari}>
                          {columns.map(
                            (column, index) => {
                              return (
                                <ColumnHeader
                                    key={`${column}`}
                                    index={index}
                                    label={`${column}`}
                                    align={index < 2 ? 'left' : 'center'}
                                    sortEnabled='false'
                                />
                              );
                            }
                          )}
                      </Header>
                      <PlayerListContainer>
                        {playersInView.length > 0 ? (
                            playersInView.map(
                                (playerId, rowIndex) => {
                                    return (
                                    <PlayerRow
                                        playerId={playerId}
                                        rowIndex={rowIndex}
                                        key={`${playerId}-row`}
                                    />
                                    );
                                }
                            )
                        ) : (
                            <PlayerListEmptyContainer>
                                No players found. Try another search?
                            </PlayerListEmptyContainer>
                        )}
                      </PlayerListContainer>
                      </>
                    )}
                  </PlayersContainer>
                </>
              )
            ) : (
                <PlayerListEmptyContainer>
                    Search for players using the search box above.
                </PlayerListEmptyContainer>
            )
          }
        </Content>
      </Wrapper>
    </>
  )
}

export default inject('AppStore')(observer(Players));
