import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: 1px dashed #aaa;
  padding: 5px;
  font-size: 0.85em;

  & .chip {
  border-radius: 1px;
  padding: 2px;
  max-width: 100%;
  }

  & .searchBox {
  color: #999;
  }

  & .icon_cancel {
  margin-left: 1px;
  }

  > div > div > div {
  border-radius: 2px;
  }
`

const SearchContainer = styled.form`
  display: flex;
  flex-direction: row;
`

const SearchInput = styled.input`
  width: 12em;

  @media (max-width: 992px) {
    text-align: center;
  }
`

const SearchSubmit = styled.input`
  width: 2.5em;
  margin-left: .5em;
`

const SearchWarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: .5em;
`

const SearchWarning = styled.div`
  font-size: .8em;
  color: red;
`

const PlayerSearch = (props) => {
  const [playerSearchTerm, setPlayerSearchTerm] = useState("Search Players");
  const [warning, setWarning] = useState("");

  const doSearch = (e) => {
    e.preventDefault();
    if (playerSearchTerm.length === 1) {
      setWarning("Please enter at least 2 characters")
    } else {
      setWarning("")
      if (playerSearchTerm !== "") {
        props.history.push(`/players/${playerSearchTerm}`);
      }
    }
  }

  return (
    <Wrapper>
      <SearchContainer
        onSubmit={e => doSearch(e)}
      >
        <SearchInput
          type="text"
          placeholder={playerSearchTerm}
          onChange={e => { setPlayerSearchTerm(e.target.value) }}
          autoFocus
        />
        <SearchSubmit type="submit" value="Go" />
        <SearchWarningContainer>
          <SearchWarning>
            {warning}
          </SearchWarning>
        </SearchWarningContainer>
      </SearchContainer>
    </Wrapper>
  );
};

export default withRouter(inject("AppStore")(observer(PlayerSearch)));