import React, { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import styled from 'styled-components';

import SpinnerComponent from "../Shared/Spinners";

import { gradeMap } from '../../helpers';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14em;
  min-height: 7em;
  padding: 0 1px;

  @media (max-width: 768px) {
    overflow-y: scroll;
    max-height: 25em;
  }
`

const OverviewRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 5em;
`

const Box = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BoxValue = styled.div`
  flex: 1;
  font-size: 3em;
  font-weight: bold;
  margin-bottom: -.2em;
`

const BoxLabel = styled.div`
  flex: 1;
  height: 1em;
`

const CQOverview = (props) => {
  const leaderboardIsFetching = props.AppStore.cqLeaderboardIsFetching.get();
  const cqSplitOverview = toJS(props.AppStore.cqLeaderboardKeyedByPlayer[props.split]?.[props.playerId]);

  useEffect(() => {
    // Handle fetching, checking for navigation changes, etc.
  }, [
    props.AppStore,
    leaderboardIsFetching,
    cqSplitOverview
  ]);

  return (
    <>
      {(
        leaderboardIsFetching
        || props.playerId !== props.AppStore.playerProfileInView.get()
      ) ? (
        <SpinnerComponent />
      ) : (
        <Wrapper>
          <>
            <OverviewRowContainer>
              <Box>
                <BoxValue>{cqSplitOverview ? cqSplitOverview.Rank : '-'}</BoxValue>
                <BoxLabel>Rank</BoxLabel>
              </Box>
              <Box>
                <BoxValue>{gradeMap[cqSplitOverview?.['OE Rating']] ? gradeMap[cqSplitOverview['OE Rating']] : '-'}</BoxValue>
                <BoxLabel>OE Rating</BoxLabel>
              </Box>
            </OverviewRowContainer>
            <OverviewRowContainer>
              <Box>
                <BoxValue>{cqSplitOverview ? cqSplitOverview.GP : '-'}</BoxValue>
                <BoxLabel>Games Played</BoxLabel>
              </Box>
              <Box>
                <BoxValue>{cqSplitOverview ? cqSplitOverview['W%'] : '-'}</BoxValue>
                <BoxLabel>Win Rate</BoxLabel>
              </Box>
            </OverviewRowContainer>
          </>
        </Wrapper>
      )}
    </>
  )
}

export default inject("AppStore")(observer(CQOverview));