import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import TwitchEmbed from '../Shared/TwitchEmbed.js';
import YouTubeEmbed from '../Shared/YouTubeEmbed.js';

const VodEmbedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100%;
  margin: .5em 5em;

  @media (max-width: 1330px) {
    margin: .5em 0;
  }
`

const CannotEmbed = styled.div`
  width: 100%;
  height: 15em;
  padding-top: 5em;
  font-size: 1.5em;
  text-align: center;
  color: black;
`

const NoVod = styled.div`
  margin-top: .5em;
  width: 100%;
  color: #000;
  text-align: center;
`

const VOD = ({ vodUrl }) => {
  var constructedUrl = false;
  if (vodUrl.substring(0, 2) === '[{') {
    const vodJson = JSON.parse(vodUrl);
    const startTimeInSeconds = vodJson[0].startMillis / 1000;
    const hours = Math.floor(startTimeInSeconds / 3600);
    const minutes = Math.floor(startTimeInSeconds % 3600 / 60);
    const seconds = Math.floor(startTimeInSeconds % 60);
    switch (vodJson[0].provider) {
      case 'twitch':
        constructedUrl = `https://www.twitch.tv/videos/${vodJson[0].parameter}?t=${hours}h${minutes}m${seconds}s`
        break;
      case 'youtube':
        constructedUrl = `https://youtube.com/watch?v=${vodJson[0].parameter}&t=${hours}h${minutes}m${seconds}s`
        break;
      default:
        // No default needed
    }
  }

  return (
    <VodEmbedContainer>
      {vodUrl !== null ? (
        <>
          {
            vodUrl.indexOf('twitch') !== -1 ? (
              <TwitchEmbed url={constructedUrl ? constructedUrl : vodUrl} />
            ) : vodUrl.indexOf('yout') !== -1 ? (
              <YouTubeEmbed url={constructedUrl ? constructedUrl : vodUrl} />
            ) : (
              <CannotEmbed>VOD cannot be embedded: <a href={constructedUrl ? constructedUrl : vodUrl} target='_blank' rel='noopener noreferrer'>access it directly</a></CannotEmbed>
            )
          }
        </>
      ) : (
        <NoVod>No VOD available</NoVod>
      )}
    </VodEmbedContainer>
  )
}

export default inject("AppStore")(observer(VOD));