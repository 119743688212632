import { observable, action } from 'mobx';
 
class UserStore {
  /**
   * Signup/Signin form store
   */
  signInEmail = observable.box('');
  signInPassword = observable.box('');
  signInError = observable.box('');
  googleSignInError = observable.box('');

  signUpEmail = observable.box('');
  passwordOne = observable.box('');
  passwordTwo = observable.box('');
  signUpError = observable.box('');

  resetPasswordEmail = observable.box('');
  resetPasswordEmailHasBeenSent = observable.box(false);

  passwordHasBeenChanged = observable.box(false);
  changePasswordError = observable.box('');

  newUsername = observable.box('');
  newUsernameSet = observable.box(false);
  changeUsernameError = observable.box('');

  /**
   * Other user management stores
   */
  userInfo = observable.box(null);
  retryGettingUserInfo = observable.box(false);

  setUserInfo = action((userInfo) => {
    this.userInfo.set(userInfo);
    this.userQuickAccessLeagues.set(userInfo && userInfo.quickAccessLeagues ? userInfo.quickAccessLeagues : null);
    userInfo ? this.setSelectedLeagues(userInfo.quickAccessLeagues.split(',')) : this.setSelectedLeagues([]);
  })

  userQuickAccessLeagues = observable.box(null);
  userQuickAccessIsFetching = observable.box(false);
  userQuickAccessHasBeenFetched = observable.box(false);

  selectedQuickAccessLeagues = observable({});
  quickAccessChanged = observable.box(false);
  changeQuickAccessError = observable.box('');

  setSelectedLeagues = action((leagues) => {
    this.resetSelectedLeagues();
    for (let league of leagues) {
      this.selectedQuickAccessLeagues[league] = true;
    }
  })

  toggleSelectedLeagues = action((league) => {
    if (this.selectedQuickAccessLeagues.hasOwnProperty(league)) {
      delete this.selectedQuickAccessLeagues[league];
    } else {
      this.selectedQuickAccessLeagues[league] = true;
    }
  })

  resetSelectedLeagues = action(() => {
    for (let league of Object.keys(this.selectedQuickAccessLeagues)) {
      delete this.selectedQuickAccessLeagues[league];
    }
  })
 
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
}
 
export default UserStore;