import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import firebase from 'firebase/compat/app';
import { withFirebase } from '../Firebase';

import { createAccount } from '../../lib/userApi';

import { trackSignUp } from "../../services/eventTracking";

import { Helmet } from "react-helmet";
import LeftSidebar from "../Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
`

const FormWrapper = styled.div`
  max-width: 45em;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 15em;
    max-height: 7em;
  }
`

const FormInput = styled.input`
  margin-right: 1.5em;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const FormButton = styled.button`
  width: 8em;
`

const GoogleButton = styled.button`
  padding: 0;
  border: none;
`

const SignUpPage = (props) => {
  var provider = new firebase.auth.GoogleAuthProvider();

  const signUpEmail = props.AppStore.userStore.signUpEmail.get();
  const passwordOne = props.AppStore.userStore.passwordOne.get();
  const passwordTwo = props.AppStore.userStore.passwordTwo.get();
  const error = props.AppStore.userStore.signUpError.get();
  const googleSignInError = props.AppStore.userStore.googleSignInError.get();

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '' ||
    signUpEmail === '';

  const submitGoogleLogin = async (event) => {
    event.preventDefault();
    trackSignUp('google');

    try {
      firebase.auth().signInWithPopup(provider)
      props.AppStore.userStore.signUpEmail.set('');
      props.AppStore.userStore.passwordOne.set('');
      props.AppStore.userStore.passwordTwo.set('');
      props.AppStore.userStore.googleSignInError.set('');
      props.history.push("/profile");
    } catch (error) {
      props.AppStore.userStore.googleSignInError.set(error.message);
    };
  }

  const submitSignup = async (event) => {
    event.preventDefault();
    trackSignUp('email');

    try {
      const response = await props.firebase.doCreateUserWithEmailAndPassword(signUpEmail, passwordOne);
      const token = await response.user.getIdToken();
      props.AppStore.userStore.signUpEmail.set('');
      props.AppStore.userStore.passwordOne.set('');
      props.AppStore.userStore.passwordTwo.set('');
      props.AppStore.userStore.signUpError.set('');
      await createAccount(token, signUpEmail, signUpEmail);
      props.history.push("/profile");
    } catch (error) {
      props.AppStore.userStore.signUpError.set(error.message);
    };
  }

  const onChange = (event) => {
    props.AppStore.userStore[event.target.name].set(event.target.value);
  };

  useEffect(() => {

  }, [
    signUpEmail,
    passwordOne,
    passwordTwo,
    error
  ])

  const pageTitle = "League of Legends esports stats - Oracle's Elixir";
  const pageDescription = "Create an account for Oracle's Elixir: advanced League of Legends esports stats from LCS, LEC, LCK, LPL, and the rest of global pro LoL";

  return (
    <>
      <Helmet>
        <title>League of Legends esports stats - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <h2>Create An Account</h2>
        {!props.AppStore.sessionStore.authUser.get() ? (
          <>
            <FormWrapper>
              <Form onSubmit={e => submitSignup(e)}>
                <FormInput
                  name="signUpEmail"
                  value={signUpEmail}
                  onChange={e => onChange(e)}
                  type="text"
                  placeholder="Email Address"
                />
                <FormInput
                  name="passwordOne"
                  value={passwordOne}
                  onChange={e => onChange(e)}
                  type="password"
                  placeholder="Password"
                />
                <FormInput
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={e => onChange(e)}
                  type="password"
                  placeholder="Confirm Password"
                />
                <FormButton disabled={isInvalid} type="submit">
                  Sign Up
                </FormButton>

                {error && <p>{error}</p>}
              </Form>
            </FormWrapper>

            <form style={{ marginTop: "2rem", marginBottom: "2rem" }} onSubmit={e => submitGoogleLogin(e)}>
              <GoogleButton type="submit">
                <img src="/btn_google_signin_light_normal_web.png" alt="Sign up with a Google account" />
              </GoogleButton>

              {googleSignInError && <p>{googleSignInError}</p>}
            </form>
            <div>
              An <em>Oracle's Elixir</em> user account allows you to customize the Quick Access<br />
              menu and view live game stats on the Home page. More features and<br />
              benefits are being worked on and will be released in the future!
            </div>
          </>
        ) : (
          <>
            <div>Already signed in as {props.AppStore.sessionStore.authUser.get().email}</div>
          </>
        )}
      </Wrapper>
    </>
  )
};

export default inject("AppStore")(withRouter(withFirebase(observer(SignUpPage))));