import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import styled from "styled-components";

import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import SpinnerComponent from "../Shared/Spinners";
import ColumnHeader from "../Stats/ColumnHeader";
import StatsByYearRow from './StatsByYearRow';

const StatsByYearContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 15em;
  font-size: 0.68em;
`

const StatsByYearHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
  z-index: 1;
  overflow-x: hidden;
  min-height: 1.3em;
  margin-top: 0.5em;
  margin-right: ${(props) =>
    props.safari === true
      ? "0"
      : "17px"}; // To sync with scrollbar width of table below

  @media (max-width: 768px) {
    overflow-y: scroll;
    margin-right: 0;
  }
`

const StatsByYearBodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  max-height: ${(props) => (props.safari === true ? "none" : "40em")};

  @media (max-width: 768px) {
    max-height: 45em;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }
`

const StatsByYearBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const GroupingNote = styled.div`
  font-size: .7em;
  font-style: italic;
`

const NoStats = styled.div`
  text-align: center;
  padding-top: 5.5em;
  font-size: 1.5em;
`

const CareerStatsByYear = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const category = 'playerProfile';
  const view = 'statsByYear';

  const statsAreFetching = props.AppStore.statsAreFetching.get();

  const statsColumnsInView = toJS(props.AppStore.statsColumnsInView);
  const filteredStatsInView = toJS(props.AppStore.filteredStatsInView);

  useEffect(() => {
  }, ([
    props.AppStore,
    statsColumnsInView,
    filteredStatsInView
  ]))

  return (
    (props.tab === 'statsByYear') ? (
      statsAreFetching ? (
        <SpinnerComponent />
      ) : (
        <>
          <ScrollSync>
            <StatsByYearContainer>
              <ScrollSyncPane>
                <StatsByYearHeader safari={isSafari}>
                  {
                    statsColumnsInView.map((stat, index) => {
                      return (
                        <ColumnHeader
                          key={`${stat}`}
                          index={index}
                          label={`${stat}`}
                          category={category}
                          view={view}
                          sortEnabled='false'
                        >
                          {stat}
                        </ColumnHeader>
                      )
                    })
                  }
                </StatsByYearHeader>
              </ScrollSyncPane>
              <ScrollSyncPane>
                <StatsByYearBodyWrapper safari={isSafari}>
                  <StatsByYearBody>
                    {(filteredStatsInView.length > 0) ? (
                      filteredStatsInView.map((row, rowIndex) => {
                        return (
                          <React.Fragment key={`${row.Year}-${row.Team}-${row.Pos}-row`}>
                            <StatsByYearRow
                              key={row.Year + row.Team + row.Pos}
                              row={row}
                              rowIndex={rowIndex}
                            />
                          </React.Fragment>
                        )
                      })
                    ) : (
                      <NoStats>
                        No stats available
                      </NoStats>
                    )}
                  </StatsByYearBody>
                </StatsByYearBodyWrapper>
              </ScrollSyncPane>
            </StatsByYearContainer>
          </ScrollSync>
          <GroupingNote>Stats are grouped into years based on the start date of each tournament.</GroupingNote>
        </>
      )
    ) : (
      null
    )
  )
}

export default inject("AppStore")(observer(CareerStatsByYear));