import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import SpinnerComponent from "../Shared/Spinners";
import Filters from "../Stats/Filters";
import ColumnHeader from "../Stats/ColumnHeader";
import ChampionPoolRow from './ChampionPoolRow';


const ChampionPoolContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 30em;
    font-size: 0.68em;
`

const ChampionPoolHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    z-index: 1;
    overflow-x: hidden;
    min-height: 1.3em;
    margin-top: 0.5em;
    margin-right: ${(props) =>
        props.safari === true
            ? "0"
            : "17px"}; // To sync with scrollbar width of table below

    @media (max-width: 768px) {
        overflow-y: scroll;
        margin-right: 0;
    }
`

const ChampionPoolBodyWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    max-height: ${(props) => (props.safari === true ? "none" : "36em")};

    @media (max-width: 768px) {
        max-height: 45em;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        max-height: none;
    }
`

const ChampionPoolBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const NoStats = styled.div`
    text-align: center;
    padding-top: 3em;
    font-size: 1.5em;
`

const CareerChampionPool = (props) => {
    const isSafari =
        navigator.userAgent.indexOf("Safari") > -1 &&
        navigator.userAgent.indexOf("Chrome") === -1;

    const category = 'playerProfile';
    const view = 'championPool';

    const segment = props.playerId;

    const statsAreFetching = props.AppStore.statsAreFetching.get();

    return (
        (props.tab === 'championPool') ? (
            <>
                <Filters
                    segment={segment}
                />
                {statsAreFetching ? (
                    <ChampionPoolContainer>
                        <SpinnerComponent />
                    </ChampionPoolContainer>
                ) : (
                    <ScrollSync>
                        <ChampionPoolContainer>
                            <ScrollSyncPane>
                                <ChampionPoolHeader safari={isSafari}>
                                    {
                                        props.AppStore.statsColumnsInView.map((stat, index) => {
                                            return (
                                                <ColumnHeader
                                                    key={`${stat}`}
                                                    index={index}
                                                    label={`${stat}`}
                                                    category={category}
                                                    view={view}
                                                >
                                                    {stat}
                                                </ColumnHeader>
                                            )
                                        })
                                    }
                                </ChampionPoolHeader>
                            </ScrollSyncPane>
                            <ScrollSyncPane>
                                <ChampionPoolBodyWrapper safari={isSafari}>
                                    <ChampionPoolBody>
                                        {(props.AppStore.filteredStatsInView.length > 0) ? (
                                            props.AppStore.filteredStatsInView.map((row, rowIndex) => {
                                                return (
                                                    <React.Fragment key={`${row.Champion}-row`}>
                                                        <ChampionPoolRow
                                                            row={row}
                                                            rowIndex={rowIndex}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                        ) : (
                                            <NoStats>
                                                No stats available
                                            </NoStats>
                                        )}
                                    </ChampionPoolBody>
                                </ChampionPoolBodyWrapper>
                            </ScrollSyncPane>
                        </ChampionPoolContainer>
                    </ScrollSync>
                )}
            </>
        ) : (
            null
        )
    )
    /*
    return (
        <ChampionPoolContainer>
            <NoStats>
                Champion pools have been disabled due to abusive web-scraping.<br />
                <br />
                If you wish to collect data from Oracle's Elixir, the Tools and Downloads page is your friend.<br />
                <br />
                For more information, see <a href="https://oracleselixir.com/blog/post/13983/player-champion-pools-tab-may-be-disabled-due-to-abuse">this news post</a>.
            </NoStats>
        </ChampionPoolContainer>
    )
    */
}

export default inject("AppStore")(observer(CareerChampionPool));