import React from "react";

function YouTubeGetID(url) {
  // From https://gist.github.com/takien/4077195
  var ID = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  return ID;
}

const YoutubeEmbed = ({ url }) => {
  var embedId = YouTubeGetID(url);
  var embedTime = url.indexOf('?t=') !== -1 ? url.substring(url.indexOf('?t=') + 3, 100) : 0;

  const ytSrc = `https://www.youtube.com/embed/${embedId}/?start=${embedTime}`;

  return (
    <div className="video-responsive">
      <iframe
        width="853"
        height="480"
        src={ytSrc}
        start={embedTime}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YoutubeEmbed;