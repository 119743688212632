import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import styled from "styled-components";

import SpinnerComponent from "../Shared/Spinners";

import Upcoming from '../Shared/Upcoming';
import GameRow from '../Shared/GameRow';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 15em;
  font-size: 0.68em;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  overflow-x: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: ${(props) => (props.safari === true ? "none" : "40em")};

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }

  
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 1330px) {
    flex-direction: column;
    max-height: none;
  }
`

const UpcomingMatches = styled.div`
  width: 20%;
  max-height: 30em;
  margin-right: 1em;

  @media (max-width: 1330px) {
    width: 100%;
    max-height: 45em;
    overflow-y: scroll;
  }
`

const UpcomingMatchesHeader = styled.div`
  margin-bottom: .5em;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid black;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
`

const RecentGames = styled.div`
  flex: 1;
  min-height: 25em;
  max-height: 45em;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const RecentGamesSpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15em;
`

const RecentGamesHeader = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid black;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
`

const NoGames = styled.div`
  height: 10em;
  padding-top: 5.5em;
  text-align: center;
  font-size: 1.5em;
`

const Overview = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const upcomingIsFetched = props.AppStore.upcomingMatchesAreFetched.get();
  const gamesAreFetching = props.AppStore.gamesAreFetching.get();
  const gamesAreFetched = Boolean(props.AppStore.teamProfileDataMap[props.teamId].gameDetails);

  const gamesInView = toJS(props.AppStore.gamesInView);

  useEffect(() => {
  }, [
    props.AppStore,
    upcomingIsFetched,
    gamesAreFetched,
    gamesInView
  ])

  return (
    (props.tab === 'overview') ? (
      <Container>
        <Header safari={isSafari} />
        <BodyWrapper safari={isSafari}>
          <Body>
            <UpcomingMatches>
              <UpcomingMatchesHeader>
                Upcoming Matches
              </UpcomingMatchesHeader>
              <Upcoming upcomingStyle='teamProfile' teamId={props.teamId} />
            </UpcomingMatches>
            <RecentGames>
              <RecentGamesHeader>
                Recent Games <span style={{fontStyle: 'italic', fontWeight: 'normal'}}></span>
              </RecentGamesHeader>
              {(!gamesAreFetched || gamesAreFetching || props.teamId !== props.AppStore.teamProfileInView.get()) ? (
                <RecentGamesSpinnerContainer>
                  <SpinnerComponent />
                </RecentGamesSpinnerContainer>
              ) : (
                (gamesInView.length > 0) ? (
                  gamesInView.map((game, rowIndex) => {
                    return (
                      <React.Fragment key={`${game.gameId}-row`}>
                        <GameRow
                          key={game.gameId}
                          type='teamPerspective'
                          game={game}
                          rowIndex={rowIndex}
                        />
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NoGames>
                    No games available
                  </NoGames>
                )
              )}
            </RecentGames>
          </Body>
        </BodyWrapper>
      </Container>
    ) : (
    null
  )
  )
}

export default inject("AppStore")(observer(Overview));