import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp, faArrowsAltV } from "@fortawesome/free-solid-svg-icons";

const ColumnHeaderContainer = styled.div`
  flex: ${props => props.flex ? props.flex : 1};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  padding: 1px;
  padding-left: ${props => props.align === 'left' ? '3px' : '1px'};
  min-width: ${props => props.minWidth ? props.minWidth : '5em'};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: ${props => props.index === 0 ? '-webkit-sticky' : 'static'};
  position: ${props => props.index === 0 ? 'sticky' : 'static'};
  left: ${props => props.index === 0 ? '0' : 'auto'};
  background: ${props => props.index === 0 ? 'white' : 'inherit'};
  z-index: ${props => props.index === 0 ? '100' : null};
  cursor: ${props => props.sortEnabled === 'false' ? 'default' : 'pointer'};
`

const ColumnNameContainer = styled.div`
  width: 100%;
  text-align: ${props => props.align ? props.align : 'center'};
`

const SortIcon = styled.span`
  width: 0;
  position: relative;
  right: .6em;
  font-size: .8em;
`

const DefinitionBody = styled(Popover.Content)`
  font-size: .9em;
`

class ColumnHeader extends Component {
  constructor(props) {
    super(props);

    this.handleColumnHeaderClick = this.handleColumnHeaderClick.bind(this);
  }

  handleColumnHeaderClick() {
    /* Special handling for sorting first column */
    if (this.props.label === this.props.AppStore.statsColumnsInView[0]) {
      if (this.props.label === this.props.AppStore.sortColumn.get()) {
        /* If we're currently sorting by first column, just flip the sort */
        this.props.AppStore.setSortOrder(this.props.AppStore.sortOrder.get() * -1);
      } else if (this.props.AppStore.sortColumn.get() !== '') {
        /* If we're currently sorting by some other column, sort desc/inverted */
        this.props.AppStore.setSortColumn(this.props.label);
        this.props.AppStore.setSortOrder(-1);
      } else {
        /* If we're currently not sorting by anything, sort asc/inverted */
        this.props.AppStore.setSortColumn(this.props.label);
        this.props.AppStore.setSortOrder(1);
      }
      this.props.AppStore.sortStatsInView();
      return
    }

    if (this.props.label === this.props.AppStore.sortColumn.get()) {
      /* Sorting within same column multiple times in a row */
      if (this.props.AppStore.sortOrder.get() === 1) {
        /* Reset sorting if moving from ascending to none */
        const defaultSortColumn =
          this.props.view === 'championPool' ? this.props.AppStore.statsColumnsInView[2]
          : this.props.view === 'leaderboard' ? this.props.AppStore.statsColumnsInView[3]
          : this.props.AppStore.statsColumnsInView[0]
          ;

        this.props.AppStore.setSortColumn(defaultSortColumn);
        this.props.AppStore.setSortOrder(-1);
        this.props.AppStore.sortStatsInView();
        this.props.AppStore.setSortColumn('');
        this.props.AppStore.setSortOrder(0);
        return
      } else {
        this.props.AppStore.setSortOrder(
          this.props.AppStore.sortOrder.get() === 0 ? -1
            : this.props.AppStore.sortOrder.get() === -1 ? 1
              : 0
        );
        this.props.AppStore.sortStatsInView();
      }
    } else {
      /* Sorting within a new column */
      this.props.AppStore.setSortColumn(
        this.props.label
      );
      this.props.AppStore.setSortOrder(-1);
      this.props.AppStore.sortStatsInView();
      return
    }
  }

  render() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? true
      : false;

    let align = null;
    let minWidth = null;
    let flex = null;

    if (this.props.index === 0) {
      align = 'left';
      minWidth =
        this.props.category === 'teams' ? '10em' :
          this.props.category === 'champions' ? '10em' :
            this.props.category === 'leaderboard' ? '4em' :
              this.props.view === 'championPool' ? '10.5em' :
                this.props.view === 'statsByYear' ? '6em' :
                  this.props.view === 'statsBySplit' ? '12em' :
                    '7.5em'
        ;
    }

    if (this.props.index === 1 && this.props.category === 'players') {
      align = 'left';
      minWidth = '11em';
    }

    if (this.props.index === 0 && this.props.category === 'leaderboard') {
      align = 'center';
      flex = '0';
    }
    else if (this.props.index === 1 && this.props.category === 'leaderboard') {
      align = 'left';
      minWidth = '7em';
    }
    else if (this.props.category === 'leaderboard') {
      minWidth = '4em';
    }

    if (this.props.index === 1 && this.props.view === 'soloQueueMatchups') {
      align = 'left';
      minWidth = '9em';
    }

    if (this.props.index === 1 && (this.props.category === 'playerProfile' && (this.props.view === 'statsBySplit' || this.props.view === 'statsByYear'))) {
      minWidth = '9em';
    }

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        delay={{ show: 300, hide: 200 }}
        key={this.props.label}
        placement='top'
        overlay={
          <Popover id={`popover-${this.props.label}`}>
            <DefinitionBody>
              {this.props.AppStore.statsDefinitionsMap.get(this.props.label) ? this.props.AppStore.statsDefinitionsMap.get(this.props.label) : 'No definition available'}
            </DefinitionBody>
          </Popover>
        }
      >
        <ColumnHeaderContainer
          align={align}
          minWidth={minWidth}
          index={this.props.index}
          onClick={this.props.sortEnabled !== 'false' ? this.handleColumnHeaderClick : null}
          sortEnabled={this.props.sortEnabled}
          flex={flex}
        >
          <ColumnNameContainer align={align}>
            {(this.props.label === 'OE Rating' && isMobile) ? 'OE Rtg' : this.props.label}
          </ColumnNameContainer>
          {this.props.sortEnabled !== 'false' && (
            <SortIcon>
              <FontAwesomeIcon icon={
                (this.props.label === this.props.AppStore.sortColumn.get()) ? (
                  this.props.AppStore.sortOrder.get() === -1 ? faLongArrowAltDown : faLongArrowAltUp
                ) : faArrowsAltV
              } />
            </SortIcon>
          )}
        </ColumnHeaderContainer>
      </OverlayTrigger>
    )
  }
}

export default inject('AppStore')(observer(ColumnHeader))
