import React from 'react';
import styled from 'styled-components';

import Disclaimer from './Disclaimer';
import Gamepedia from './Gamepedia';

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em 0.5em 0.5em 20em;
  min-height: 3em;
  background: black;
  color: white;
  font-size: 0.6rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    padding: 0.5em;
  }
`

const FooterBox = styled.div`
  flex: 1;
  padding: 1.5em;
  max-width: 40%;

  &.half-width {
    max-width: 20%; 
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;

    &.half-width {
      width: 100%;
      max-width: 100%;
    }
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterBox>
        <Disclaimer />
      </FooterBox>
      <FooterBox className="half-width">
        <Gamepedia />
      </FooterBox>
    </FooterContainer>
  )
}

export default Footer;
