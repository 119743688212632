import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { toJS } from 'mobx';
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { withFirebase } from '../Firebase';

import { fetchUserInfo } from '../../services/fetchUserInfo';
import { changeUsername, changeQuickAccess } from '../../lib/userApi';

import { trackUsernameChange, trackQuickAccessChange } from "../../services/eventTracking";

import { Helmet } from "react-helmet";
import LeftSidebar from "../Sidebars/LeftSidebar";

import SpinnerComponent from '../Shared/Spinners';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
`

const WidgetTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
`

const ChangeUsernameWrapper = styled.div`
  width: 100%;
  height: 4em;
  margin-top: 1em;
`

const ChangePasswordWrapper = styled.div`
  width: 100%;
  margin-top: 1em;
  font-size: 1.1em;
  font-weight: bold;
`

const DeleteAccountWrapper = styled.div`
  width: 100%;
  margin-top: 1em;
  font-size: 1.1em;
  font-weight: bold;
`

const ChangeQuickAccessWrapper = styled.div`
  width: 100%;
  margin-top: 2em;
`

const ChangeQuickAccessOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 60em;
`

const LeagueOptionWrapper = styled.div`
  margin-bottom: .5em;
  width: 8em;
`
const LeagueOptionCheckbox = styled.input`
  margin-right: .5em;
`
const LeagueOptionPopover = styled(Popover.Content)`
  color: black;
`

const UsernameInput = styled.input`
  margin-right: 1.5em;
`

const ChangeUsername = inject("AppStore")(observer((props) => {
  const newUsername = props.AppStore.userStore.newUsername.get();
  const changeUsernameError = props.AppStore.userStore.changeUsernameError.get();
  const newUsernameSet = props.AppStore.userStore.newUsernameSet.get();
  const newUsernameIsInvalid = newUsername === '';

  const submitNewUsername = async (event) => {
    event.preventDefault();

    try {
      trackUsernameChange();
      const token = props.AppStore.sessionStore.authToken.get();
      const result = await changeUsername(token, newUsername);
      if (result.statusCode === 200) {
        fetchUserInfo(props.AppStore);
        props.AppStore.userStore.newUsername.set('');
        props.AppStore.userStore.changeUsernameError.set('');
        props.AppStore.userStore.newUsernameSet.set(true);
        setTimeout(() => { props.AppStore.userStore.newUsernameSet.set(false) }, 4000);
      } else {
        props.AppStore.userStore.changeUsernameError.set(result.content);
      }
    } catch (error) {
      props.AppStore.userStore.changeUsernameError.set(error);
    }
  };

  const onChange = (event) => {
    props.AppStore.userStore.newUsername.set(event.target.value);
  };

  return (
    <ChangeUsernameWrapper>
      <WidgetTitle>Change Username</WidgetTitle>
      <form onSubmit={e => submitNewUsername(e)}>
        <UsernameInput
          name="newUsername"
          value={newUsername}
          onChange={e => onChange(e)}
          type="text"
          placeholder="New Username"
        />
        <button disabled={newUsernameIsInvalid} type="submit">
          Change Username
        </button>
      </form>
      {newUsernameSet && <p style={{ fontStyle: 'italic' }}>Username changed successfully</p>}
      {changeUsernameError && <p>{changeUsernameError}</p>}
    </ChangeUsernameWrapper>
  )
}));

const ChangeQuickAccessLeagues = inject("AppStore")(observer((props) => {
  const leaguesAreFetched = props.AppStore.leaguesAreFetched.get();
  const leagueOptions = props.AppStore.leagues;
  const changeQuickAccessError = props.AppStore.userStore.changeQuickAccessError.get();

  const selectedLeagues = toJS(props.AppStore.userStore.selectedQuickAccessLeagues);

  const invalidSelection = Object.keys(props.AppStore.userStore.selectedQuickAccessLeagues).length === 0;

  const submitNewQuickAccessLeagues = async (event) => {
    event.preventDefault();

    try {
      trackQuickAccessChange();
      const token = props.AppStore.sessionStore.authToken.get();
      const result = await changeQuickAccess(token, Object.keys(selectedLeagues).join(','));
      if (result.statusCode === 200) {
        await props.AppStore.userStore.resetSelectedLeagues();
        await props.AppStore.userStore.userQuickAccessLeagues.set(null);
        await fetchUserInfo(props.AppStore);
        props.AppStore.userStore.changeQuickAccessError.set('');
        props.AppStore.userStore.quickAccessChanged.set(true);
      } else {
        props.AppStore.userStore.changeQuickAccessError.set(result.content);
      }
    } catch (error) {
      props.AppStore.userStore.changeQuickAccessError.set(error);
    }
  };

  const onChange = (event) => {
    props.AppStore.userStore.toggleSelectedLeagues(event.target.value);
  };

  useEffect(() => {
    if (!leaguesAreFetched && !props.AppStore.leaguesAreFetching.get()) {
      props.AppStore.setLeagues();
    }
  }, [
    props.AppStore,
    leaguesAreFetched
  ])

  return (
    leaguesAreFetched && (
      <ChangeQuickAccessWrapper>
        <WidgetTitle>Customize Quick Access</WidgetTitle>
        <form onSubmit={e => submitNewQuickAccessLeagues(e)}>
          <ChangeQuickAccessOptionsWrapper>
            {leagueOptions.map((league) => {
              return (
                <React.Fragment key={`${league.id}-outside-wrapper`}>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    delay={{ show: 300, hide: 200 }}
                    key={league.fullName}
                    placement='top'
                    overlay={
                      <Popover id={`popover-${league.fullName}`}>
                        <LeagueOptionPopover>
                          {league.fullName}
                        </LeagueOptionPopover>
                      </Popover>
                    }
                  >
                    <LeagueOptionWrapper key={`${league.id}-wrapper`}>
                      <LeagueOptionCheckbox
                        key={league.id}
                        id={league.id}
                        name={league.fullName}
                        value={league.id}
                        onChange={e => onChange(e)}
                        checked={
                          selectedLeagues
                          && selectedLeagues.hasOwnProperty(league.id)
                        }
                        type="checkbox"
                      />
                      <label htmlFor={league.id}> {league.name}</label>
                      <br />
                    </LeagueOptionWrapper>
                  </OverlayTrigger>
                </React.Fragment>
              )
            })}
          </ChangeQuickAccessOptionsWrapper>

          <button type="submit" disabled={invalidSelection}>
            Submit
          </button>
        </form>
        {changeQuickAccessError && <p>{changeQuickAccessError}</p>}
      </ChangeQuickAccessWrapper>
    )
  )
}));

const Profile = (props) => {
  const pageTitle = "League of Legends esports stats - Oracle's Elixir";
  const pageDescription = "Profile page on Oracle's Elixir: advanced League of Legends esports stats from LCS, LEC, LCK, LPL, and the rest of global pro LoL.";

  return (
    <>
      <Helmet>
        <title>Your Profile - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        {!props.AppStore.sessionStore.authStatusHasBeenChecked.get() ? (
          <SpinnerComponent />
        ) : (
          <>
            {props.AppStore.sessionStore.authUser.get() && props.AppStore.userStore.userInfo.get() ? (
              <>
                <h2>{props.AppStore.userStore.userInfo.get().username}'s Profile</h2>
                <ChangeUsername />
                <ChangePasswordWrapper>
                  <Link to="/changePassword">Change your password</Link>
                </ChangePasswordWrapper>
                <ChangeQuickAccessLeagues />
                <DeleteAccountWrapper>
                  Delete your account<br />
                  <span style={{fontSize: ".9em", fontWeight: "normal"}}>
                    Please <Link to="/contact">contact the site admin directly</Link> to have your account and all associated information deleted.
                  </span>
                </DeleteAccountWrapper>
              </>
            ) : (
              <>
                <div>You must be <Link to="/signin">signed in</Link> to view this page</div>
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  )
};

export default inject("AppStore")(withRouter(withFirebase(observer(Profile))));