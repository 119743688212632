import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { withFirebase } from '../Firebase';

import { Helmet } from "react-helmet";
import LeftSidebar from "../Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ResetPasswordPage = (props) => {
  const email = props.AppStore.userStore.resetPasswordEmail.get();
  const error = props.AppStore.userStore.signInError.get();

  const isInvalid = email === '';

  const submitResetPassword = (event) => {
    event.preventDefault();

    props.firebase.auth
      .sendPasswordResetEmail(email)
      .then(() => {
        props.AppStore.userStore.resetPasswordEmailHasBeenSent.set(true);
        setTimeout(() => { props.AppStore.userStore.resetPasswordEmailHasBeenSent.set(false) }, 4000);
        setTimeout(() => { props.history.push("/"); }, 4000);
      })
      .catch(error => {
        props.AppStore.userStore.signInError.set(error.message);
      });
  };

  const onChange = (event) => {
    props.AppStore.userStore[event.target.name].set(event.target.value);
  };

  const pageTitle = "League of Legends esports stats - Oracle's Elixir";
  const pageDescription = "Reset password for Oracle's Elixir: advanced League of Legends esports stats from LCS, LEC, LCK, LPL, and the rest of global pro LoL";

  return (
    <>
      <Helmet>
        <title>League of Legends esports stats - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <h1>Reset Password</h1>
        {!props.AppStore.sessionStore.authUser.get() ? (
          props.AppStore.userStore.resetPasswordEmailHasBeenSent.get() ? (
            <div>Password reset email has been sent</div>
          ) : (
            <div>
              <form onSubmit={e => submitResetPassword(e)}>
                <input
                  name="resetPasswordEmail"
                  value={email}
                  onChange={e => onChange(e)}
                  type="text"
                  placeholder="Email Address"
                />
                <button disabled={isInvalid} type="submit">
                  Reset Password
                </button>

                {error && <p>{error}</p>}
              </form>
            </div>
          )
        ) : (
          <div>Currently signed in as {props.AppStore.sessionStore.authUser.get().email}</div>
        )}
      </Wrapper>
    </>
  )
};

export default inject("AppStore")(withRouter(withFirebase(observer(ResetPasswordPage))));