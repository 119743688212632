import React from 'react';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from './Sidebars/LeftSidebar';

const ContactWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 32em;
  font-size: .85em;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: .5rem;
`

const Content = styled.div`
  font-size: 1em;
`

const About = () => {
  const pageTitle = "Oracle's Elixir - Contact us";
  const pageDescription = "Get in touch with Tim Sevenhuysen about Oracle's Elixir's LoL esports stats and coverage.";

  return (
    <>
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TimSevenhuysen" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
    <LeftSidebar />
    <ContactWrapper className="mainContent">
      <Title>
        Get in Touch
      </Title>
      <Content>
        If you have any questions, comments, or suggestions about the content on this site, including requests
        for specific stats that aren't currently being posted,
        send Magic a note at <a href='mailto:tsevenhuysen@oracleselixir.com'>tsevenhuysen@oracleselixir.com</a>,
        connect on <a href="https://www.linkedin.com/in/tim-sevenhuysen/" target="_blank" rel="noopener noreferrer">LinkedIn</a>, or
        contact him <a href="https://twitter.com/TimSevenhuysen" target="_blank" rel="noopener noreferrer">on Twitter</a>.
      </Content>
    </ContactWrapper>
    </>
  )
}

export default About;
