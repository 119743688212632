import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #ccc;
`

const Disclaimer = () => {
  return (
    <Container>
      Oracle's Elixir isn't endorsed by Riot Games and doesn't reflect the views or opinions of Riot Games 
      or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games 
      are trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
    </Container>
  )
}
 export default Disclaimer;