import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import LeftSidebar from "./Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 45em;
  font-size: 0.85em;
`;

const Content = styled.div`
  text-align: left;
  font-size: 0.9em;
`;

const Banner = styled.img`
  max-width: 100%;
  margin-left: -1em;
`;

const LittleHero = styled.span`
  color: #d86b22;
`;

const Future = () => {
  const pageTitle = "Oracle's Elixir - Welcome to Oracle's Elixir 2.0!";
  const pageDescription =
    "Oracle's Elixir 2.0 is here, completely rebuilt from the ground up to provide broader coverage, deeper interactivity, better site performance, and a solid foundation for continued development.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <Content>
          <Banner src="/OE-Wordmark-Gradient.png" />
          <p>
            <LittleHero>Oracle's Elixir 2.0 is here</LittleHero>, completely
            rebuilt from the ground up to provide broader coverage, deeper
            interactivity, better site performance, and a solid foundation for
            continued development.
          </p>
          <h3>New Features</h3>
          <ul>
            <li>
              <b>Deeply filter stats tables</b> by win/loss, map side, and more
            </li>
            <li>
              <b><Link to="/tools/downloads">Download CSVs</Link></b> of any stats table, using your current
              filters
            </li>
            <li>
              Explore <b>new metrics</b>, like turret plates and counterpick rate (where available)
            </li>
            <li>
              View stats from <b>many more leagues</b>, such as TCL, VCS, LJL, and ERLs
            </li>
          </ul>
          <h3>Discord Community</h3>
          <a
            href="https://discord.gg/KY6XHaF"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/Discord-Logo%2BWordmark-Color.png"
              alt="Join on Discord"
              style={{ maxWidth: "27.5%", float: "right", margin: "0 1em" }}
            />
          </a>
          <p>
            Join the{" "}
            <a
              href="https://discord.gg/KY6XHaF"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>Oracle's Elixir Discord server</b>
            </a>{" "}
            to share feedback and requests, discuss your favourite players and
            teams, and give input on the articles and infographics you want to
            see me work on.
          </p>
          <h3>What's Next?</h3>
          <p>
            Some of the projects I'm working on include pre-match win probabilities,
            individual game and match details with player and team stats and builds,
            solo queue analytics, and a whole lot more.
          </p>
          <h3>Support Oracle's Elixir</h3>
          <p>
            Oracle's Elixir is a lot of work to build and maintain, and the
            amount of time I'm able to put towards new features partly depends on
            your support.
          </p>
          <a
            href="https://patreon.com/oracleselixir"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/become_a_patron_button.png"
              alt="Become a patron"
              style={{ maxWidth: "27.5%", float: "right", margin: "0 1em" }}
            />
          </a>
          <p>
            Please consider subscribing to the{" "}
            <a
              href="https://patreon.com/oracleselixir"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#d86b22" }}
            >
              <b>Oracle's Elixir Patreon</b>
            </a>
            , where you'll get fantasy lineup recommendations, Discord roles, exclusive and
            early-access content, opportunities to contribute questions for the{" "}
            <a
              href="https://anchor.fm/truesight"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>True Sight Podcast</b>
            </a>
            , and more. With enough support, I'll be able to dedicate more time
            to expanding the site and producing more, and higher quality, content.
          </p>
        </Content>
      </Wrapper>
    </>
  );
};

export default Future;
