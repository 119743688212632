import React, { useEffect } from 'react';
import { Router, useLocation } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import styled from 'styled-components';

import { withFirebase } from './Firebase';

import { fetchUserInfo } from '../services/fetchUserInfo';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Navigation from './Navigation';
import Routes from '../routes';
import Footer from './Footer';

library.add(fab, faArrowDown, faExternalLinkAlt)

const Body = styled.div`
  flex: 1;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname + location.search });
  // ReactGA.pageview(location.pathname + location.search);
})

const App = (props) => {
  const authToken = props.AppStore.sessionStore.authToken.get();
  const userInfo = props.AppStore.userStore.userInfo.get();

  const dataLinksAreFetching = props.AppStore.dataLinksAreFetching.get();
  const dataLinksAreFetched = props.AppStore.dataLinksAreFetched.get();

  useEffect(() => {
    props.firebase.auth.onAuthStateChanged(async (authUser) => {
      props.AppStore.sessionStore.authStatusHasBeenChecked.set(true);
      if (authUser) {
        props.AppStore.sessionStore.setAuthUser(authUser);
        const token = await authUser.getIdToken();
        props.AppStore.sessionStore.setAuthToken(token);
      }
    });
    if (!userInfo && props.AppStore.sessionStore.authToken.get()) {
      fetchUserInfo(props.AppStore);
    }

    if (!dataLinksAreFetching && !dataLinksAreFetched) {
      props.AppStore.setMatchDataLinks()
    }
  }, [
    props.AppStore,
    props.firebase.auth,
    authToken,
    userInfo,
    dataLinksAreFetching,
    dataLinksAreFetched
  ])

  return (
    <Router history={history}>
      <ScrollToTop />
      <Navigation />
      <Body className='mainBody'>
        <Routes />
      </Body>
      <Footer />
    </Router>
  )
}

export default withFirebase(inject("AppStore")(observer(App)));