import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { observerBatching } from 'mobx-react-lite';
import store from './stores/index';
import styled from 'styled-components';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

import ReactGA from 'react-ga4';
const GOOGLE_ANALYTICS_CODE = process.env.REACT_APP_GOOGLE_ANALYTICS;
ReactGA.initialize(GOOGLE_ANALYTICS_CODE);
// ReactGA.pageview(window.location.pathname + window.location.search);

observerBatching(ReactDOM.unstable_batchedUpdates);

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`;

ReactDOM.render(
  <React.StrictMode>
    <Provider AppStore={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <AppWrapper>
          <App />
        </AppWrapper>
      </FirebaseContext.Provider>
    </Provider>
    <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
