import request from 'superagent';

const debug = require('debug')('OE Blog API Calls');

// const ENDPOINT = process.env.REACT_APP_API_URL;
// const ENDPOINT = `http://localhost:1337`
const ENDPOINT = `https://lol.timsevenhuysen.com/wp-json/wp/v2`;

/**
 * Get articles
 */
export async function getArticles(page = 1, categories = []) {
  const queryUri = encodeURI(`${ENDPOINT}/posts/?_embed`)
  debug(`Fetching ${queryUri} from blog API`)
  page = parseInt(page);
  try {
    var res = await request
      .get(queryUri)
      .query({ per_page: 5, page: page, categories: categories })
    
    if (res.headers['x-wp-totalpages'] === 0) {
      res = await getArticles(page = page-1);
    }

    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get article
 */
export async function getSingleArticle(id) {
  const queryUri = encodeURI(`${ENDPOINT}/posts/${id}?_embed`)
  debug(`Fetching ${queryUri} from blog API`)
  try {
    const res = await request
      .get(queryUri)
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get article by slug
 */
export async function getSingleArticleBySlug(slug) {
  const queryUri = encodeURI(`${ENDPOINT}/posts?slug=${slug}&_embed`)
  debug(`Fetching ${queryUri} from blog API`)
  try {
    const res = await request
      .get(queryUri)
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get search results
 */
export async function getSearch(searchTerm, page = 1) {
  const queryUri = encodeURI(`${ENDPOINT}/posts?_embed`)
  debug(`Fetching ${queryUri} from blog API`)
  page = parseInt(page);
  try {
    var res = await request
      .get(queryUri)
      .query({
        per_page: 5,
        page: page,
        search: searchTerm,
        subtype: "post",
        orderby: "date"
      })
    
    if (parseInt(res.headers['x-wp-totalpages']) === 0) {
      res = await getSearch(searchTerm, page-1);
    }

    return res;
  } catch (error) {
    return error;
  }
}

// Use _in to check if a specific tag/category is attached http://localhost:1337/articles?categories_in=1
