import React from 'react';
import { inject, observer } from "mobx-react";
 
import { withFirebase } from '../Firebase';

import { trackSignOut } from "../../services/eventTracking"; 

const SignOutButton = ({ firebase, AppStore }) => {
  const executeSignOut = (event) => {
    event.preventDefault();
    trackSignOut();

    AppStore.sessionStore.setAuthUser(null);
    AppStore.sessionStore.setAuthToken(null);
    AppStore.userStore.setUserInfo(null);
    firebase.doSignOut();
  }

  return (
    <span onClick={e => executeSignOut(e)}>
      Sign Out
    </span>
  )
};
 
export default withFirebase(inject("AppStore")(observer(SignOutButton)));