import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import firebase from 'firebase/compat/app';
import { withFirebase } from '../Firebase';

import { trackSignIn } from "../../services/eventTracking";

import { Helmet } from "react-helmet";
import LeftSidebar from "../Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
`

const FormWrapper = styled.div`
  max-width: 45em;
`

const Form = styled.form`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 15em;
    max-height: 7em;
  }
`

const FormInput = styled.input`
  margin-right: 1.5em;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

const FormButton = styled.button`
  width: 8em;
`

const HelpfulLinks = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2em;
`

const HelpfulLinkBox = styled.div`
  margin-right: 2em;
`

const GoogleButton = styled.button`
  padding: 0;
  border: none;
`

const SignInPage = (props) => {
  var provider = new firebase.auth.GoogleAuthProvider();
  const isSignedIn = props.AppStore.userStore.userInfo.get();

  const signInEmail = props.AppStore.userStore.signInEmail.get();
  const signInPassword = props.AppStore.userStore.signInPassword.get();
  const signInError = props.AppStore.userStore.signInError.get();
  const googleSignInError = props.AppStore.userStore.googleSignInError.get();

  const signInIsInvalid = signInPassword === '' || signInEmail === '';

  const submitGoogleLogin = async (event) => {
    event.preventDefault();
    trackSignIn('google');

    try {
      await firebase.auth().signInWithPopup(provider);
      props.AppStore.userStore.signInEmail.set('');
      props.AppStore.userStore.signInPassword.set('');
      props.AppStore.userStore.signInError.set('');
      props.AppStore.userStore.googleSignInError.set('');
    } catch (error) {
      props.AppStore.userStore.googleSignInError.set(error.message);
    }
  }

  const submitLogin = async (event) => {
    event.preventDefault();
    trackSignIn('email');

    try {
      await props.firebase.doSignInWithEmailAndPassword(signInEmail, signInPassword);
      props.AppStore.userStore.signInEmail.set('');
      props.AppStore.userStore.signInPassword.set('');
      props.AppStore.userStore.signInError.set('');
      props.AppStore.userStore.googleSignInError.set('');
      props.history.push("/profile");
    } catch (error) {
      props.AppStore.userStore.signInError.set(error.message);
    };
  };

  props.firebase.auth.onAuthStateChanged(async (authUser) => {
    if (authUser && !props.AppStore.sessionStore.authToken.get()) {
      props.AppStore.sessionStore.setAuthUser(authUser);
      const newToken = await authUser.getIdToken();
      props.AppStore.sessionStore.setAuthToken(newToken);
    }
  });

  const onChange = (event) => {
    props.AppStore.userStore[event.target.name].set(event.target.value);
  };

  useEffect (() => {
    if (isSignedIn !== null) {
      props.history.push("/profile");
    }
  }, [
    props.AppStore,
    props.history,
    isSignedIn
  ])

  const pageTitle = "League of Legends esports stats - Oracle's Elixir";
  const pageDescription = "Sign in to Oracle's Elixir: advanced League of Legends esports stats from LCS, LEC, LCK, LPL, and the rest of global pro LoL.";

  return (
    <>
      <Helmet>
        <title>League of Legends esports stats - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <h2>Sign In</h2>
        {!props.AppStore.sessionStore.authUser.get() ? (
          <>
            <FormWrapper>
              <Form onSubmit={e => submitLogin(e)}>
                <FormInput
                  name="signInEmail"
                  value={signInEmail}
                  onChange={e => onChange(e)}
                  type="text"
                  placeholder="Email Address"
                />
                <FormInput
                  name="signInPassword"
                  value={signInPassword}
                  onChange={e => onChange(e)}
                  type="password"
                  placeholder="Password"
                />
                <FormButton disabled={signInIsInvalid} type="submit">
                  Sign In
                </FormButton>
              </Form>

              {signInError && <p>{signInError}</p>}
            </FormWrapper>

            <form style={{ marginTop: "2rem" }} onSubmit={e => submitGoogleLogin(e)}>
              <GoogleButton type="submit">
                <img src="/btn_google_signin_light_normal_web.png" alt="Sign in with a Google account" />
              </GoogleButton>

              {googleSignInError && <p>{googleSignInError}</p>}
            </form>

            <HelpfulLinks>
              <HelpfulLinkBox style={{marginTop: "1.5em"}}>
                <Link to="/signup">Create an account</Link>
              </HelpfulLinkBox>
              <HelpfulLinkBox style={{marginTop: "1.5em"}}>
                <Link to="/resetPassword">Forgot password?</Link>
              </HelpfulLinkBox>
            </HelpfulLinks>
            <div>
              An <em>Oracle's Elixir</em> user account allows you to customize the Quick Access<br />
              menu and view live game stats on the Home page. More features and<br />
              benefits are being worked on and will be released in the future!
            </div>
          </>
        ) : (
          <>
            <div>Signed in as {props.AppStore.sessionStore.authUser.get().email}</div>
          </>
        )}
      </Wrapper>
    </>
  )
};

export default inject("AppStore")(withRouter(withFirebase(observer(SignInPage))));