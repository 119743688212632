import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import LeftSidebar from "./Sidebars/LeftSidebar";
import BlogFeed from "./Blog/Feed";
import QuickAccess from "./Shared/QuickAccess";
import UpcomingAndRecent from "./Shared/UpcomingAndRecent";

const HomeWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const BlogWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 40em;
  min-height: 50em;
  margin-right: 2em;
`;

const Home = ({ AppStore }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
      )
      ? true
      : false;

  const pageTitle = "LoL esports stats - Oracle's Elixir";
  const pageDescription =
    "Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL.";

  return (
    <>
      <Helmet>
        <title>League of Legends esports stats - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <HomeWrapper className="mainContent">
        <Content>
          <BlogWrapper>
            <BlogFeed />
          </BlogWrapper>
          <UpcomingAndRecent isMobile={isMobile} />
          <QuickAccess />
        </Content>
      </HomeWrapper>
    </>
  );
};

export default inject("AppStore")(observer(Home));
