import React, { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import styled from "styled-components";

import SpinnerComponent from "../Shared/Spinners";

import CQOverview from './CQOverview';
import GameRow from '../Shared/GameRow';

import { Multiselect } from "multiselect-react-dropdown";
import { trackStatsFilter } from "../../services/eventTracking";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 15em;
  font-size: 0.68em;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  overflow-x: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: ${(props) => (props.safari === true ? "none" : "40em")};

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 1330px) {
    flex-direction: column;
    max-width: 100%;
  }
`

const LeftBar = styled.div`
  min-width: 20%;
  max-height: 30em;
  margin-right: 1em;

  @media (max-width: 1330px) {
    width: 100%;
    max-height: 45em;
    margin-right: 0;
    overflow-y: scroll;
  }
`

const LeftBarHeader = styled.div`
  margin-bottom: .5em;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid black;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
`

const RecentGames = styled.div`
  flex: 1;
  min-height: 25em;
  max-height: 45em;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const RecentGamesSpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 15em;
`

const RecentGamesHeader = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid black;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
`

const NoGames = styled.div`
  height: 10em;
  padding-top: 5.5em;
  text-align: center;
  font-size: 1.5em;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1em;

  & .chip {
    border-radius: 1px;
    padding: 2px;
    max-width: 100%;
  }

  & .searchBox {
    color: #999;
  }

  & .icon_cancel {
    margin-left: 1px;
  }

  > div > div > div {
    border-radius: 2px;
  }
`

const FilterBox = styled.div`
  flex: 1;
  position: relative;
  margin: 0 0 5px 0;
  border-radius: 1px;

  > div > div {
    padding: 0;
  }

  > div > div > span {
    min-height: 22px;
    color: #000;
    margin-bottom: 0;
    font-size: 1em;
    font-weight: bold;
  }

  > div > div > input {
    display: none;
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`

const PlayerChampionsQueue = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const splitInView = props.AppStore.cqSplitInView.get() || props.AppStore.cqCurrentSplit.get(); // If this doesn't work, switch to normal ternary

  const splitOptions = toJS(props.AppStore.cqSplits);

  const didSplitChange = props.AppStore.cqFiltersChanged.get();

  const cqSplitsFetching = props.AppStore.cqSplitsFetching.get();
  const filtersSplitSelected = toJS(props.AppStore.cqFiltersSplitSelected);
  const nameOfSplitSelected = filtersSplitSelected.length > 0
    ? filtersSplitSelected[0].value
    : toJS(props.AppStore.cqSplits).length > 0 && toJS(props.AppStore.cqSplits)[0].value
    ;

  const cqGamesAreFetching = props.AppStore.cqGamesAreFetching.get();
  const cqGamesAreFetched = Boolean(props.AppStore.playerProfileDataMap[props.playerId].cqGameDetails);

  const gamesInView = toJS(props.AppStore.gamesInView);

  const leaderboardIsFetching = props.AppStore.cqLeaderboardIsFetching.get();
  const leaderboardContents = toJS(props.AppStore.cqLeaderboard);

  const splitRef = useRef(null);

  const onChangeSplit = (selectedList, selectedItem) => {
    props.AppStore.cqSplitInView.set(selectedItem.id);
    props.AppStore.cqFiltersSplitSelected.replace([selectedItem,])
    trackStatsFilter("changeCqLeaderboardSplit", selectedItem.value);
    props.AppStore.cqFiltersChanged.set(true);
  };

  useEffect(() => {
    if (
      !leaderboardIsFetching
      && (splitInView && !(splitInView in leaderboardContents))
    ) {
      props.AppStore.fetchCQLeaderboard(splitInView);
      props.AppStore.cqFiltersChanged.set(true);
    }

    if (!splitInView && !cqSplitsFetching) {
      props.AppStore.fetchCQSplits();
    }
  }, ([
    props.AppStore,
    splitInView,
    cqGamesAreFetched,
    gamesInView,
    leaderboardIsFetching,
    leaderboardContents,
    cqSplitsFetching,
    splitOptions,
    didSplitChange
  ]))

  return (
    (props.tab === 'championsQueue' && splitOptions.length > 0) ? (
      <Container>
        <Header safari={isSafari} />
        <BodyWrapper safari={isSafari}>
          <Body>
            <LeftBar>
              <LeftBarHeader>
                {nameOfSplitSelected} Overview
              </LeftBarHeader>
              <FiltersWrapper>
                <FilterBox>
                  <Multiselect
                    id="splitFilter"
                    options={splitOptions}
                    singleSelect={true}
                    selectedValues={filtersSplitSelected.length > 0 ? filtersSplitSelected : [splitOptions[0]]}
                    onSelect={onChangeSplit}
                    onRemove={onChangeSplit}
                    closeIcon="cancel"
                    closeOnSelect={true}
                    displayValue="value"
                    ref={splitRef}
                  />
                </FilterBox>
              </FiltersWrapper>
              <CQOverview playerId={props.playerId} split={splitInView} />
            </LeftBar>
            <RecentGames>
              <RecentGamesHeader>
                Recent CQ Games <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}></span>
              </RecentGamesHeader>
              {(!cqGamesAreFetched || cqGamesAreFetching || props.playerId !== props.AppStore.playerProfileInView.get()) ? (
                <RecentGamesSpinnerContainer>
                  <SpinnerComponent />
                </RecentGamesSpinnerContainer>
              ) : (
                (gamesInView.length > 0) ? (
                  gamesInView.map((game, rowIndex) => {
                    return (
                      <React.Fragment key={`${game.primaryDetails.gameId}-row`}>
                        <GameRow
                          key={`${game.primaryDetails.gameId}-game-row`}
                          type='playerChampionsQueue'
                          game={game}
                          rowIndex={rowIndex}
                        />
                      </React.Fragment>
                    )
                  })
                ) : (
                  <NoGames>
                    No games available
                  </NoGames>
                )
              )}
            </RecentGames>
          </Body>
        </BodyWrapper>
      </Container>
    ) : (
      null
    )
  )
}

export default withRouter(inject("AppStore")(observer(PlayerChampionsQueue)));