import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 14rem;

  @media (max-width: 768px) {
    margin-bottom: 2em;
    max-width: 100%;
  }
`

const ContentContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 95px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    text-align: center;
  }
`

const ContainerTitle = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  margin-bottom: 0.1em;

  @media (max-width: 768px) {
    flex: 1;
    flex-direction: column;
    padding-left: 0;
  }
`


export const BlogSidebar = () => {
  /* "news": 1,
"articles": 13,
"theory": 14,
"analysis": 24,
"data": 30
"podcast": 469
*/
  return (
    <>
      <Wrapper>
        <ContentContainer>
          <ContainerTitle>Categories</ContainerTitle>
          <ItemContainer>
            <Link to="/blog/category/analysis">Analysis and Opinion</Link>
          </ItemContainer>
          <ItemContainer>
            <Link to="/blog/category/data">Check the Data</Link>
          </ItemContainer>
          <ItemContainer>
            <Link to="/blog/category/theory">Stats Theory</Link>
          </ItemContainer>
          <ItemContainer>
            <Link to="/blog/category/news">News</Link>
          </ItemContainer>
        </ContentContainer>
      </Wrapper>
    </>
  )
}

export default BlogSidebar;