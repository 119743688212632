import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #ccc;
`

const Gamepedia = () => {
  return (
    <Container>
      Some content is provided courtesy of <a href="https://lol.fandom.com/League_of_Legends_Esports_Wiki" target="_blank" rel="noopener noreferrer">Leaguepedia</a>,
      under a <a href="https://creativecommons.org/licenses/by-sa/3.0/" target="_blank" rel="noopener noreferrer">CC-BY-SA 3.0 license</a>.
    </Container>
  )
}

export default Gamepedia;