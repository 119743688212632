import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

const ColumnHeaderContainer = styled.div`
  flex: ${props => props.flex ? props.flex : 1};
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align ? props.align : 'center'};
  align-items: center;
  padding: 1px;
  padding-left: ${props => props.align === 'left' ? '.5em' : null};
  min-width: ${props => props.minWidth ? props.minWidth : '5em'};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: ${props => props.index === 0 ? '-webkit-sticky' : 'static'};
  position: ${props => props.index === 0 ? 'sticky' : 'static'};
  left: ${props => props.index === 0 ? '0' : 'auto'};
  background: ${props => props.index === 0 ? 'white' : 'inherit'};
  z-index: ${props => props.index === 0 ? '100' : null};
  cursor: ${props => props.sortEnabled === 'false' ? 'default' : 'pointer'};
`

const ColumnNameContainer = styled.div`
  width: 100%;
  text-align: ${props => props.align ? props.align : 'center'};
`

const ColumnHeader = (props) => {
  const ColumnNames = {
    name: 'Team',
    tag: 'Tag',
    league: 'League',
    region: 'Region',
    active: 'Active'
  }

  return (
    <ColumnHeaderContainer
      align={props.align}
      minWidth={props.minWidth}
      index={props.index}
      flex={props.index < 1 ? '2.5' : '1'}
      sortEnabled={props.sortEnabled}
    >
      <ColumnNameContainer align={props.align}>
        {ColumnNames[props.label]}
      </ColumnNameContainer>
    </ColumnHeaderContainer>
  )
}

export default inject('AppStore')(observer(ColumnHeader))
