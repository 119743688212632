import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import Upcoming from "./Upcoming";
import LiveMatches from "./LiveMatches";
import RecentResults from "./RecentResults";

const Wrapper = styled.div`
  margin-left: 1em;
  min-width: 14em;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-bottom: 2em;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
`

const TabNav = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  border: 1px solid #ccc;
  font-size: .7rem;
  font-weight: bold;
  cursor: pointer;
`

const Item = styled.div`
  flex: 1;
  background-color: ${props => props.active ? '#228fd8' : null};
  padding: 3px;
  text-align: center;
  color: ${props => props.active ? 'white' : null};
`

const UpcomingAndRecent = ({ AppStore, isMobile }) => {
  const homeMatchesTabInView = AppStore.homeMatchesTabInView.get();
  const onDefaultTab = AppStore.homeMatchesTabInView.get() === 'default';

  useEffect (() => {
    if (onDefaultTab) {
      switch (isMobile) {
        case true:
          AppStore.setHomeMatchesTabInView('hidden');
          break;
        default:
          AppStore.setHomeMatchesTabInView('upcoming');
      }
    }
  }, [
    AppStore,
    onDefaultTab,
    isMobile
  ]);

  return (
    <Wrapper>
      <Title>
        Matches
      </Title>
      <TabNav>
        {isMobile && (
          <Item
            onClick={() => {
              AppStore.setHomeMatchesTabInView('hidden')
            }}
            active={AppStore.homeMatchesTabInView.get() === 'hidden'}
          >
            Hide
          </Item>
        )}
        <Item
          onClick={() => {
            AppStore.setHomeMatchesTabInView('upcoming')
          }}
          active={AppStore.homeMatchesTabInView.get() === 'upcoming'}
        >
          Upcoming
        </Item>
        <Item
          onClick={() => {
            AppStore.setHomeMatchesTabInView('inProgress')
          }}
          active={AppStore.homeMatchesTabInView.get() === 'inProgress'}
        >
          Live
        </Item>
        <Item
          onClick={() => {
            AppStore.setHomeMatchesTabInView('recent')
          }}
          active={AppStore.homeMatchesTabInView.get() === 'recent'}
        >
          Recent
        </Item>
      </TabNav>
      {homeMatchesTabInView === 'upcoming' ? (
        <Upcoming upcomingStyle='home' />
      ) : homeMatchesTabInView === 'inProgress' ? (
        <LiveMatches />
        ) : homeMatchesTabInView === 'recent' ? (
        <RecentResults />
      ) : (
        null
      )}
    </Wrapper>
  )
}

export default inject("AppStore")(observer(UpcomingAndRecent));