// Note that digitCounts don't work on percentage stats since those come from the API as strings
// Set those digit counts on the API side
export const digitCounts = {
  'GP': 0,
  'W%': 0,
  'K': 0,
  'D': 0,
  'A': 0,
  'KDA': 1,
  'KP': 1,
  'DTH%': 1,
  'KD': 2,
  'FB%': 0,
  'GD10': 0,
  'XDP10': 0,
  'CSD10': 1,
  'GD15': 0,
  'XDP15': 0,
  'CSD15': 1,
  'CSPM': 1,
  'CS%P15': 1,
  'DPM': 0,
  'DMG%': 1,
  'GOLD%': 1,
  'WPM': 2,
  'WCPM': 2,
  'AGT': 1,
  'CKPM': 2,
  'GPR': 2,
  'GSPD': 1,
  'EGR': 1,
  'MLR': 1,
  'FT%': 0,
  'F3T%': 0,
  'PPG': 1,
  'HLD%': 9,
  'FD%': 0,
  'DRG%': 0,
  'ELD%': 0,
  'FBN%': 0,
  'BN%': 0,
  'STL': 0,
  'STLPG': 1,
  'LNE%': 1,
  'JNG%': 1,
  'CWPM': 2,
  'CTR%': 0
}
