import React, { useEffect } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Spinner from '../Shared/Spinners';

import Article from './Article';

const NoArticlesContainer = styled.div`
  width: 100%;
  margin: 3em auto;
  font-size: 1.2em;
  text-align: center;
`

const Pagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 3px solid black;
  text-align: center;
`

const PaginationItem = styled.div`
  height: 2em;
  min-width: 2em;
  line-height: 2em;
  padding: 0 .5em;
  text-transform: uppercase;

  &:hover {
    background: ${props => !props.current ? '#228fd8' : null};
    color: ${props => !props.current ? 'white' : null};
  }
`

const PaginationArrow = styled.span`
  margin: 0 .1em;
  font-size: .7em;
`

const NoArticles = ({ searchTerm }) => {
  return (
    <NoArticlesContainer>
      No articles were found for the search term "{searchTerm}"
    </NoArticlesContainer>
  )
}

const BlogFeed = (props) => {
  const blogCategories = {
    "news": 1,
    "articles": 13,
    "theory": 14,
    "analysis": 24,
    "data": 30,
    "podcast": 469
  };
  let view = "";
  const searchTerm = props.match.params.searchTerm ? props.match.params.searchTerm : null;
  const category = props.match.params.category;
  const categoryId = blogCategories[props.match.params.category];

  const blogIsFetching = props.AppStore.blogFetching.get();

  switch (props.match.url.slice(6, 10)) {
    case "sear":
      view = "search";
      break;
    case "cate":
      view = "category";
      break;
    default:
      view = "feed";
  };

  const page = props.match.params.page ? props.match.params.page : 1;

  const pageNeedsFetching = (
    view === "feed" &&
    !(page in props.AppStore.blogPagesFetched) &&
    (!blogIsFetching)
  );
  const searchPageNeedsFetching = (
    view === "search" &&
    (
      !(searchTerm in props.AppStore.searchesFetched) ||
      !(page in props.AppStore.searchesFetched[searchTerm])
    ) &&
    !(page > props.AppStore.blogSearchPageCount[searchTerm]) &&
    !(blogIsFetching)
  );
  const categoryPageNeedsFetching = (
    view === "category" &&
    (
      !(categoryId in props.AppStore.categoriesFetched) ||
      !(page in props.AppStore.categoriesFetched[categoryId])
    ) &&
    !(page > props.AppStore.categoriesFetched[categoryId]) &&
    (!blogIsFetching)
  );

  useEffect(() => {
    switch (view) {
      case "search":
        if (searchPageNeedsFetching) {
          props.AppStore.fetchSearch(searchTerm, page);
        } else {
          searchTerm in props.AppStore.searchesFetched && props.AppStore.setArticlesInView(props.AppStore.searchesFetched[searchTerm][page]);
          props.AppStore.blogPageInView.set(page);
        }
        break;
      case "category":
        if (categoryPageNeedsFetching) {
          props.AppStore.fetchCategory(categoryId, page);
        } else {
          categoryId in props.AppStore.categoriesFetched && props.AppStore.setArticlesInView(props.AppStore.categoriesFetched[categoryId][page]);
          props.AppStore.blogPageInView.set(page);
        }
        break;
      default:
        if (pageNeedsFetching) {
          props.AppStore.fetchArticles(page);
        } else {
          props.AppStore.setArticlesInView(props.AppStore.blogPagesFetched[page]);
          props.AppStore.blogPageInView.set(page);
        }
    }
  }, [
    view,
    page,
    searchTerm,
    category,
    categoryId,
    props.match,
    props.match.url,
    props.match.params.id,
    props.AppStore,
    blogIsFetching,
    pageNeedsFetching,
    searchPageNeedsFetching,
    categoryPageNeedsFetching,
    props.AppStore.blogPagesFetched,
    props.AppStore.searchesFetched,
    props.AppStore.articlesInView
  ])

  return (
    <>
      {(props.AppStore.blogFetching.get()) && (
        <Spinner size='large' />
      )}
      {!(props.AppStore.blogFetching.get()) && props.AppStore.articlesInView.length === 0 && (
        <NoArticles searchTerm={searchTerm} />
      )}
      {!(props.AppStore.blogFetching.get()) && (
        toJS(props.AppStore.articlesInView).map(articleId => {
          return (
            <Article key={articleId} post={props.AppStore.articleMap[articleId]} excerpt />
          )
        })
      )}
      {!(props.AppStore.blogFetching.get()) && props.AppStore.articlesInView.length > 0 && (
        <Pagination>
          {(page > 1) && (
            <>
              <Link to={`/blog/${view}/${view === "search" ? `${searchTerm}/` : view === "category" ? `${category}/` : ''}${parseInt(props.AppStore.blogPageInView.get()) - 1}`}>
                <PaginationItem>
                  <PaginationArrow><FontAwesomeIcon icon={faArrowLeft} /></PaginationArrow> Prev
                </PaginationItem>
              </Link>
              <Link to={`/blog/${view}/${view === "search" ? `${searchTerm}/` : view === "category" ? `${category}/` : ''}${parseInt(props.AppStore.blogPageInView.get()) - 1}`}>
                <PaginationItem>
                  {parseInt(props.AppStore.blogPageInView.get()) - 1}
                </PaginationItem>
              </Link>
            </>
          )}
          <PaginationItem current>
            {props.AppStore.blogPageInView.get()}
          </PaginationItem>
          {(props.AppStore.blogPageInView.get() < (view === 'feed' ? props.AppStore.blogPageCount.get() : view === 'search' ? props.AppStore.blogSearchPageCount[searchTerm] : view === 'category' ? props.AppStore.blogCategoryPageCount[categoryId] : null)) && (
            <>
              <Link to={`/blog/${view}/${view === "search" ? `${searchTerm}/` : view === "category" ? `${category}/` : ''}${parseInt(props.AppStore.blogPageInView.get()) + 1}`}>
                <PaginationItem>
                  {parseInt(props.AppStore.blogPageInView.get()) + 1}
                </PaginationItem>
              </Link>
              <Link to={`/blog/${view}/${view === "search" ? `${searchTerm}/` : view === "category" ? `${category}/` : ''}${parseInt(props.AppStore.blogPageInView.get()) + 1}`}>
                <PaginationItem>
                  Next <PaginationArrow><FontAwesomeIcon icon={faArrowRight} /></PaginationArrow>
                </PaginationItem>
              </Link>
            </>
          )}
        </Pagination>
      )}
    </>
  )
}

export default withRouter(inject('AppStore')(observer(BlogFeed)));
