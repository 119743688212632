import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import AdSense from 'react-adsense';

const Wrapper = styled.div`
  min-width: 16em;
  background: black;
  color: white;
  font-size: .65em;
  display: flex;
`

const LeftSidebarContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 992px) {
    width: 16em;
  }
`

const SidebarSocialsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #333;
  padding: .2rem 1rem;
  text-decoration: none;
  text-transform: uppercase;

  &.quickAccess {
    padding: 1em 1rem;
  }

  @media (max-width: 768px) {
    width: 20em;
    text-align: center;
    align-items: center;
    border-bottom: none;
  }
`

const SidebarItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #333;
  padding: .2rem 1rem;
  text-decoration: none;
  text-transform: uppercase;

  &.quickAccess {
    padding: 1em 1rem;
  }

  @media (max-width: 992px) {
    width: 16em;
  }

  @media (max-width: 768px) {
    width: 20em;
    text-align: center;
    justify-content: center;
    border-bottom: none;
  }
`

const SidebarItemLabel = styled.div``

const Coachify = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  max-width: 16em;

  & a:hover {
    opacity: .8;
  }
`

const NewNewsIndicator = styled.div`
  margin-left: 1em;
  padding-bottom: .1em;
  font-size: .7em;
  color: #34D822;
`

const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
`

const VALLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1em;
  
  & img {
    width: 8em;
  }

  & a:hover {
    background: none;
    opacity: 0.7;
  }
`

const SocialIcons = () => {
  return (
    <SocialIconsContainer className="sidebarSocialIcons">
      <a href="https://twitter.com/TimSevenhuysen" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={['fab', 'twitter']} />
      </a>
      <a href="https://discord.gg/KY6XHaF" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={['fab', 'discord']} style={{fontSize: "1.9rem"}} />
      </a>
      <a href="https://patreon.com/oracleselixir" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={['fab', 'patreon']} />
      </a>
      <a href="https://www.youtube.com/channel/UCY_YV6Lj-2r0W1OS5KIhmdQ" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={['fab', 'youtube']} />
      </a>
    </SocialIconsContainer>
  )
}

const SidebarItem = (props) => {
  return (
    <NavLink
      to={props.to}
      exact
      activeStyle={{
        color: "white",
        background: props.to === '/future' ? '#D86B22' : '#228fd8'
      }}
      className={props.className ? props.className : null}
      isActive={(match, location) => {
        if (!props.to) {
          return false;
        } else if (props.label === 'News' && window.location.pathname.match(/\/blog\/category\/news.+/)) {
          return true;
        } else {
          return props.to === location.pathname;
        }
      }}
    >
      <SidebarItemContainer>
        <SidebarItemLabel>
          {props.label}
        </SidebarItemLabel>
        {(props.label === 'News' && props.newNews === 'yes') ? (
            <NewNewsIndicator>
              <FontAwesomeIcon icon={faCircle} />
            </NewNewsIndicator>
        ) : (
            null
        )}
      </SidebarItemContainer>
    </NavLink>
  )
}

const LeftSidebar = ({ AppStore }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
    )
    ? true
    : false;

  const newNews = AppStore.newNews.get();
  const blogIsFetching = AppStore.blogFetching.get();
  const newNewsNeedsFetching = newNews === 'unfetched' && !blogIsFetching;

  useEffect(() => {
    if (newNewsNeedsFetching) {
      AppStore.fetchCategory(1, 1, true);
    }
  }, [
    AppStore,
    blogIsFetching,
    newNews,
    newNewsNeedsFetching
  ]);

  return (
    <Wrapper className="leftSidebar">
      <LeftSidebarContainer>
        <SidebarSocialsContainer>
          <SocialIcons />
        </SidebarSocialsContainer>
        <SidebarItem label="About" to="/about" />
        <SidebarItem label="Definitions" to="/definitions" />
        <SidebarItem label="FAQ" to="/faq" />
        <SidebarItem label="Contact" to="/contact" />
        <SidebarItem label="News" to="/blog/category/news" newNews={newNews} />
        <VALLink>
          <a
            href='https://val.oracleselixir.com'
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src='/V_Logotype_Red.png' alt="Oracle's Elixir for Valorant" />
          </a>
        </VALLink>
        {/* <SidebarItem label="OE 2.0" to="/future" className="oeTwoPointOh" /> */}
        {/* <Coachify>
          ADVERTISEMENT
          <a
            href="https://coachify.gg/directory/league-of-legends?utm_source=oracleselixir&utm_campaign=oracles_free_and_paid_coaching"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/coachify-free-paid-coaching-square.png" />
          </a>
        </Coachify> */}
        {/* <AdSense.Google
          client='ca-pub-2614408554247972'
          slot='3059506512'
          format=''
          responsive='true'
          style={{ marginTop: "1em", width: 200, height: 200 }}
        />
        {
          !isMobile && (
            <AdSense.Google
              client='ca-pub-2614408554247972'
              slot='7383781054'
              format=''
              responsive='true'
              style={{ marginTop: "1em", width: '100%', height: '100%', maxWidth: 200, maxHeight: 600 }}
            />
          )
        } */}
      </LeftSidebarContainer>
    </Wrapper>
  )
}

export default inject("AppStore")(observer(LeftSidebar));
