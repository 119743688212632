import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import StatsNav from './StatsNav.js';
import { MajorLeaguesQuickAccess } from '../../Shared/QuickAccess';

const StatsNavContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const StatsNavTableContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 35em;
`

const StatsNavNotice = styled.div`
  font-size: .75em;
  margin-bottom: 0.6em;
`

const ViewRecentEvents = styled.span`
  cursor: pointer;
  color: #228fd8;

  &:hover {
    opacity: .8;
  }
`

const QuickAccessContainer = styled.div`
  margin: 1.2em 0 0 1em;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const StatsNavigation = (props) => {
  return (
    <StatsNavContainer>
      <StatsNavTableContainer>
        <StatsNavNotice>
          Select a league to see a list of its events, or browse the <ViewRecentEvents
            onClick={() => {
              props.AppStore.setStatsNavLeagueSelected('')
            }}
            >most recent events</ViewRecentEvents>
        </StatsNavNotice>
        <StatsNav category={props.category} />
      </StatsNavTableContainer>
      <QuickAccessContainer>
        <b>Quick Access</b>
        <MajorLeaguesQuickAccess />
      </QuickAccessContainer>
    </StatsNavContainer>
  )
}

export default inject('AppStore')(observer(StatsNavigation))