import ReactGA from 'react-ga4';

export const trackStatsFilter = (filterType, value) => {
  ReactGA.event({
    category: 'Filter Stats',
    action: filterType,
    label: value.toString()
  })

  // const yardstick = window.yardstick;
  // yardstick.track('Filter Stats', {type: filterType, value: value.toString()});
}

export const trackDownload = (downloadTarget) => {
  ReactGA.event({
    category: 'Download',
    action: 'Download match data',
    label: downloadTarget.year.toString()
  })

  // const yardstick = window.yardstick;
  // yardstick.track('Download match data', {year: downloadTarget.year.toString()});
}

export const trackTableDownload = (downloadTarget) => {
  ReactGA.event({
    category: 'Download',
    action: 'Download table as CSV',
    label: `${downloadTarget.category} - ${downloadTarget.tournament}`
  })

  // const yardstick = window.yardstick;
  // yardstick.track('Download table', {category: downloadTarget.category, tournament: downloadTarget.tournament});
}

export const trackEGRCalculator = (egrCalculatorInteraction) => {
  const value = egrCalculatorInteraction.value.toString();
  ReactGA.event({
    category: 'EGR Calculator',
    action: `${egrCalculatorInteraction.control}`,
    label: value
  })

  // add yardstick?
}

/**
 * User account interactions
 */
export const trackSignUp = (type) => {
  ReactGA.event({
    category: 'User Account',
    action: `Create Account`,
    label: type
  })
}

export const trackSignIn = (type) => {
  ReactGA.event({
    category: 'User Account',
    action: `Sign In`,
    label: type
  })
}

export const trackSignOut = () => {
  ReactGA.event({
    category: 'User Account',
    action: `Sign Out`
  })
}

export const trackUsernameChange = () => {
  ReactGA.event({
    category: 'User',
    action: `Change username`
  })
}

export const trackQuickAccessChange = () => {
  ReactGA.event({
    category: 'User',
    action: `Change quick access`
  })
}