import { getUserInfo, createAccount } from '../lib/userApi';

export const fetchUserInfo = (async (AppStore) => {
  try {
    const fetchedUserInfo = await getUserInfo(AppStore.sessionStore.authToken.get())
    if (fetchedUserInfo.body && fetchedUserInfo.body.length === 1) {
      AppStore.userStore.setUserInfo(fetchedUserInfo.body[0]);
      return 200
    } else {
      await createAccount(
        AppStore.sessionStore.authToken.get(),
        AppStore.sessionStore.authUser.get().displayName || AppStore.sessionStore.authUser.get().email,
        AppStore.sessionStore.authUser.get().email,
      );
      fetchUserInfo();
    }
  } catch (error) {
    console.log(error)
  }
})