import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { toJS } from "mobx";
import styled from "styled-components";

import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import SpinnerComponent from "../Shared/Spinners";
import ColumnHeader from "../Stats/ColumnHeader";
import StatsBySplitRow from './StatsBySplitRow';

const StatsBySplitContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 15em;
    font-size: 0.68em;
`

const StatsBySplitHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    position: -webkit-sticky;
    position: sticky;
    top: 62px;
    z-index: 1;
    overflow-x: hidden;
    min-height: 1.3em;
    margin-top: 0.5em;
    margin-right: ${(props) =>
        props.safari === true
            ? "0"
            : "17px"}; // To sync with scrollbar width of table below

    @media (max-width: 768px) {
        overflow-y: scroll;
        margin-right: 0;
    }
`

const StatsBySplitBodyWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: scroll;
    max-height: ${(props) => (props.safari === true ? "none" : "40em")};

    @media (max-width: 768px) {
        max-height: 45em;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        max-height: none;
    }
`

const StatsBySplitBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const NoStats = styled.div`
    text-align: center;
    padding-top: 5.5em;
    font-size: 1.5em;
`

const CareerStatsBySplit = (props) => {
    const isSafari =
        navigator.userAgent.indexOf("Safari") > -1 &&
        navigator.userAgent.indexOf("Chrome") === -1;

    const category = 'playerProfile';
    const view = 'statsBySplit';

    const statsAreFetching = props.AppStore.statsAreFetching.get();

    const statsColumnsInView = toJS(props.AppStore.statsColumnsInView);
    const filteredStatsInView = toJS(props.AppStore.filteredStatsInView);

    useEffect(() => {
    }, ([
        props.AppStore,
        statsColumnsInView,
        filteredStatsInView
    ]))

    return (
        (props.tab === 'statsBySplit') ? (
            statsAreFetching ? (
                <SpinnerComponent />
            ) : (
                <ScrollSync>
                    <StatsBySplitContainer>
                        <ScrollSyncPane>
                            <StatsBySplitHeader safari={isSafari}>
                                {
                                    statsColumnsInView.map((stat, index) => {
                                        if (stat === 'tournamentId') {
                                            return null;
                                        } else {
                                            return (
                                                <ColumnHeader
                                                    key={`${stat}`}
                                                    index={index}
                                                    label={`${stat}`}
                                                    category={category}
                                                    view={view}
                                                    sortEnabled='false'
                                                >
                                                    {stat}
                                                </ColumnHeader>
                                            )
                                        }
                                    })
                                }
                            </StatsBySplitHeader>
                        </ScrollSyncPane>
                        <ScrollSyncPane>
                            <StatsBySplitBodyWrapper safari={isSafari}>
                                <StatsBySplitBody>
                                    {(filteredStatsInView.length > 0) ? (
                                        filteredStatsInView.map((row, rowIndex) => {
                                            return (
                                                <React.Fragment key={`${row.Event}-row`}>
                                                    <StatsBySplitRow
                                                        key={row.Event}
                                                        row={row}
                                                        rowIndex={rowIndex}
                                                    />
                                                </React.Fragment>
                                            )
                                        })
                                    ) : (
                                        <NoStats>
                                            No stats available
                                        </NoStats>
                                    )}
                                </StatsBySplitBody>
                            </StatsBySplitBodyWrapper>
                        </ScrollSyncPane>
                    </StatsBySplitContainer>
                </ScrollSync>
            )
        ) : (
            null
        )
    )
}

export default inject("AppStore")(observer(CareerStatsBySplit));