import React from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import LeftSidebar from "../Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 40em;
  font-size: 0.85em;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Content = styled.div`
  font-size: 1em;
`;

const DownloadsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const Preamble = styled.div`
  max-width: 40em;
`

const DownloadsTitle = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`

const DownloadsListContainer = styled.div`
  width: 15em;
`

const Downloads = ({ fetched, matchDataLinks }) => {
  return (
    <DownloadsContainer>
      <DownloadsTitle>Available Downloads</DownloadsTitle>
      <Preamble>
        <p>Game statistics are the property of Riot Games, and any usage of such data must follow <a href="https://developer.riotgames.com/policies/general" target="_blank" rel="noopener noreferrer">Riot Games' terms and policies</a>.</p>
      </Preamble>
      <DownloadsListContainer>
        Access files via <a href="https://drive.google.com/drive/u/1/folders/1gLSw0RLjBbtaNy0dgnGQDAZOHIgCe-HH" target="_blank" rel="noopener noreferrer">Google Drive</a>.
      </DownloadsListContainer>
    </DownloadsContainer>
  );
};

const Tools = ({ AppStore }) => {
  const pageTitle = "Match Data Downloads - Oracle's Elixir";
  const pageDescription = "Oracle's Elixir downloadable match data for LCS, LEC, LCK, LPL, and many more <em>LoL</em> esports leagues.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <Title>Match Data Downloads</Title>
        <Content>
          <p>
            Below you will find a collection of data files containing match data from the LCS, LEC, LCK, LPL, PCS, CBLoL, and many more leagues. Files are in .csv format.
          </p>
          <p>
            All data has been aggregated and released by Tim Sevenhuysen of <em>OraclesElixir.com</em>. It is provided free of charge, and is intended for use by analysts, commentators, and fans.
          </p>
          <p>
            Data files are updated <em>ONCE PER DAY</em>. There is no value in downloading the files more frequently than this.
          </p>
          <p>
            Changelogs, news, and updates are maintained on <a href="https://discord.gg/KY6XHaF" target="_blank" rel="noopener noreferrer">the Oracle's Elixir Discord server</a> in the <em>oe-data-updates</em> channel.
          </p>
          <p>
            Definitions for the data in these files can be found or inferred from the information on the <Link to="/definitions">Definitions page</Link>.
          </p>
          <p>
            Questions or requests? <Link to="/contact">Get in touch</Link>,
            or <a href="https://discord.gg/KY6XHaF" target="_blank" rel="noopener noreferrer">join the Oracle's Elixir Discord server</a>.
          </p>
          <p>
            If you find this downloadable data useful, please consider helping out with the<br />cost of running the site by{" "}
            <a href="https://www.patreon.com/oracleselixir" target="_blank" rel="noopener noreferrer">subscribing on Patreon</a>.
          </p>
          <Downloads fetched={AppStore.dataLinksAreFetched.get()} matchDataLinks={AppStore.matchDataLinks} />
        </Content>
      </Wrapper>
    </>
  );
};

export default inject("AppStore")(observer(Tools));
