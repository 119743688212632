import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import SpinnerComponent from '../Shared/Spinners';

const ArticleContainer = styled.div`
  margin-bottom: ${props => props.isNews ? '1em' : '3em'};
  padding-top: ${props => props.isNews ? '1em' : '3em'};
  border-top: 1px solid #ccc;

  &:first-child {
    padding-top: 0;
    border-top: none;
  }
`

const FeaturedImage = styled.img`
  margin-bottom: .5em;
`

const ArticleTitleContainer = styled.div`
  & a {
    color: black;
  }

  & a:hover {
    opacity: .8;
    color: #228fd8;
  }
`

const ArticleTitle = styled.span`
  font-size: ${props => props.isNews ? '1.4em' : '1.6em'};
`

const Categories = styled.div`
  width: 100%;
  font-size: .7em;
  font-weight: bold;
  text-transform: uppercase;
`

const Category = styled.span`
  margin-right: 1em;
`

const ArticleMetadata = styled.div`
  font-size: .8em;
  margin-bottom: ${props => props.isNews ? '0' : '1.5em'};
`

const AuthorName = styled.span`
  margin-right: .4em;
`

const ArticleDate = styled.span`
  margin-right: .4em;
`

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: solid 3px black;
  padding-top: .2em;
  font-size: .8em;
  font-style: italic;
`

const Tag = styled.a`
  margin-right: 1em;
`

const MetadataDivider = styled.span`
  margin: 0 .5em;
`

const ArticleContent = styled.div`
  font-size: .9em;
  padding-top: .75em;
`

const BioContainer = styled.div`
  border-top: 3px solid #ccc;
  min-height: 96px;
`

const BioImage = styled.img`
  float: left;
  margin: -3px 1em 0 0;
`

const BioText = styled.p`
  padding-top: .5em;
  font-size: .8em;
  font-style: italic;
`

const ReadMoreLink = styled.div`
  font-size: .8em;
  text-transform: uppercase;
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Article = (props) => {
  const articleCategoriesMap = {
    1: "news",
    14: "theory",
    24: "analysis",
    30: "data",
    469: "podcast"
  };
  var isNews = false;
  if (props.post && props.post.categories) {
    for (let category of props.post.categories) {
      if (category.id === 1) {
        isNews = true;
      }
    }
  }

  useEffect(() => {
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  })

  const cleanHtmlForMetaDescription = (html) => {
    var temp = document.createElement("div");
    temp.innerHTML = html;
    return temp.textContent || temp.innerText || "";
  }

  const pageTitle = `${
    props.post ? props.post.title : "Articles and Analysis"
  } - Oracle's Elixir`;
  const pageDescription =
    props.post && props.post.excerpt
      ? `${cleanHtmlForMetaDescription(
          props.post.excerpt.replace(/<[^>]+>/g, "").substring(0, 152)
        )}...`
      : "Oracle's Elixir blog - League of Legends articles, analysis, infographics, stats, and more.";

  return (
    <>
    {!props.excerpt && props.post && (
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
    )}
    {(!props.post || props.AppStore.blogFetching.get()) ? (
      <SpinnerComponent />
    ) : (
      <ArticleContainer key={props.post.id} className='blogPost' isNews={isNews}>
        {props.post.featuredMedia && (
          props.excerpt ? (
            <Link to={`/blog/post/${props.post.id}/${props.post.slug}`}>
              <FeaturedImage
                src={props.post.featuredMedia}
              />
            </Link>
          ) : (
            <FeaturedImage
              src={props.post.featuredMedia}
            />
          )
        )}
        <Categories>
          {props.post.categories && props.post.categories.map(category => {
            return (
              <Category key={category.id}>
                <Link
                  to={`/blog/category/${articleCategoriesMap[category.id]}`}
                  style={category.id === 1 ? {color: '#D86B22'} : {}}
                >
                  {category.name}
                </Link>
              </Category>
            )
          })}
        </Categories>
        {props.excerpt ? (
          <ArticleTitleContainer>
            <Link to={`/blog/post/${props.post.id}/${props.post.slug}`}>
              <ArticleTitle
                dangerouslySetInnerHTML={{ __html: props.post.title }} /* Consider using DOMPurify here */
                isNews={isNews}
              />
            </Link>
          </ArticleTitleContainer>
        ) : (
          <ArticleTitleContainer>
            <ArticleTitle
              dangerouslySetInnerHTML={{ __html: props.post.title }}
              isNews={isNews}
            />
          </ArticleTitleContainer>
        )}
        <ArticleMetadata
          isNews={isNews}
        >
          {props.post.authors.length !== 0 && (
            <>
            {props.post.authors && props.post.authors.map(author => {
              return <AuthorName key={author}>{author.name}</AuthorName>
            })}
            <MetadataDivider>|</MetadataDivider>
            </>
          )}
          <ArticleDate>
            { props.post.date }
          </ArticleDate>
        </ArticleMetadata>
        <ArticleContent
          dangerouslySetInnerHTML={{ __html: !props.excerpt ? props.post.content : props.post.excerpt }}
        />
        {props.excerpt && (
          <ReadMoreLink>
            <Link to={`/blog/post/${props.post.id}/${props.post.slug}`}>
                Continue reading <FontAwesomeIcon icon={faArrowRight} style={{fontSize: ".8em", marginLeft: ".2em", marginBottom: ".15em"}} />
            </Link>
          </ReadMoreLink>
        )}
        {!props.excerpt && (
          props.post.authors && props.post.authors.map(author => {
            let authorDescription = author.description.replace(/<a/g, '<a target="_blank" ');

            return (
              <BioContainer
                key={author}
              >
                <BioImage src={author.avatar_urls['96']} />
                <BioText dangerouslySetInnerHTML={{ __html: authorDescription }} />
              </BioContainer>
            )
          })
        )}
        {!props.excerpt && (
          <Tags>
            {props.post.tags && props.post.tags.map(tag => {
              return (
                <Tag key={tag.id} href={`/blog/search/${tag.name}/1`}>{tag.name}</Tag>
              )
            })}
          </Tags>
        )}
      </ArticleContainer>
    )}
    </>
  )
}

export default inject('AppStore')(observer(Article));