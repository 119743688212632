import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { toJS } from 'mobx';
import * as API from "../../lib/api";
import styled from "styled-components";

import PlayerRow from './PlayerInRoster';
import GameRow from '../Shared/GameRow';

import SpinnerComponent from "../Shared/Spinners";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 15em;
  font-size: 0.68em;
  color: black;
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 30em;

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RostersContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 0.5em;
  border-bottom: 1px solid black;
`

const Rosters = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 10em;
  max-height: 40em;

  @media (max-width: 1330px) {
    flex-direction: column;
    max-height: none;
  }
`

const TeamRoster = styled.div`
  flex: 1;
  min-width: 16em;

  @media (max-width: 1330px) {
    max-height: 30em;
    overflow-y: scroll;

    :first-child {
      border-bottom: 1px solid black;
    }
  }
`

const TeamRosterTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  border-bottom: 1px solid black;
`

const TeamRosterTitle = styled.div`
  width: 35%;
  font-size: 1.4em;
  font-weight: bold;

  & a {
    color: black;
  }
`

const TournamentStatsTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-size: 1.3em;
  font-weight: bold;

  & a {
    color: black;
  }
`

const NoRoster = styled.div`
  height: 10em;
  padding-top: 5.5em;
  text-align: center;
  font-size: 1.5em;
`

const RecentGamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  border-bottom: 1px solid black;
`

const RecentGamesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4em;
  text-transform: uppercase;
`

const RecentGamesContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  @media (max-width: 1330px) {
    flex-direction: column;
  }
`

const RecentGamesTeam1 = styled.div`
  width: 100%;
`

const RecentGamesTeam2 = styled.div`
  width: 100%;
`

const RecentGamesTeamHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: white;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  font-size: 1.4em;

  & a {
    color: black;
  }
`

const RecentGamesGameListContainer = styled.div`
  max-height: 30em;
  overflow-y: scroll;

  @media (max-width: 768px) {
    margin-bottom: 0.5em;
  }
`

const NoGames = styled.div`
  height: 10em;
  padding-top: 5.5em;
  text-align: center;
  font-size: 1.5em;
`


const Overview = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const matchInfo = props.AppStore.matchMap[props.matchId];

  const team1Id = matchInfo.team1Id;
  const team2Id = matchInfo.team2Id;

  const roster1Fetching = props.AppStore.roster1Fetching.get();
  const roster1Fetched = matchInfo
    && (matchInfo.state === 'completed')
    ? Boolean(props.AppStore.teamRosterMap[team1Id] && props.AppStore.teamRosterMap[team1Id][props.matchId])
    : Boolean(props.AppStore.teamRosterMap[team1Id])
    ;
  const roster2Fetching = props.AppStore.roster2Fetching.get();
  const roster2Fetched = matchInfo
    && (matchInfo.state === 'completed')
    ? Boolean(props.AppStore.teamRosterMap[team2Id] && props.AppStore.teamRosterMap[team2Id][props.matchId])
    : Boolean(props.AppStore.teamRosterMap[team2Id])
    ;

  const team1Roster = matchInfo
    && (matchInfo.state === 'completed')
    ? roster1Fetched ? toJS(props.AppStore.teamRosterMap[team1Id][props.matchId]) : false
    : roster1Fetched ? toJS(props.AppStore.teamRosterMap[team1Id]) : false
    ;
  const team2Roster = matchInfo
    && (matchInfo.state === 'completed')
    ? roster2Fetched ? toJS(props.AppStore.teamRosterMap[team2Id][props.matchId]) : false
    : roster2Fetched ? toJS(props.AppStore.teamRosterMap[team2Id]) : false
    ;

  const team1GamesAreFetching = props.AppStore.matchTeam1GamesAreFetching.get();
  const team1GamesAreFetched = matchInfo
    && matchInfo.state === 'completed'
    ? Boolean(
      props.AppStore.teamProfileDataMap[team1Id]
      && props.AppStore.teamProfileDataMap[team1Id].gameDetailsBeforeTime
      && props.AppStore.teamProfileDataMap[team1Id].gameDetailsBeforeTime[matchInfo.startTime]
    ) : Boolean(
      props.AppStore.teamProfileDataMap[team1Id]
      && props.AppStore.teamProfileDataMap[team1Id].gameDetails
    )
    ;
  const team2GamesAreFetching = props.AppStore.matchTeam2GamesAreFetching.get();
  const team2GamesAreFetched = matchInfo
    && matchInfo.state === 'completed'
    ? Boolean(
      props.AppStore.teamProfileDataMap[team2Id]
      && props.AppStore.teamProfileDataMap[team2Id].gameDetailsBeforeTime
      && props.AppStore.teamProfileDataMap[team2Id].gameDetailsBeforeTime[matchInfo.startTime]
    ) : (
      Boolean(
        props.AppStore.teamProfileDataMap[team2Id]
        && props.AppStore.teamProfileDataMap[team2Id].gameDetails
      ) || matchInfo.team2Id === 'TBD'
    )
    ;

  const team1GamesInView = (matchInfo && props.AppStore.teamProfileDataMap[team1Id])
    ? (
      matchInfo.state === 'completed'
        ? toJS(
          props.AppStore.teamProfileDataMap[team1Id].gameDetailsBeforeTime
          && props.AppStore.teamProfileDataMap[team1Id].gameDetailsBeforeTime[matchInfo.startTime]
        )
        : toJS(props.AppStore.teamProfileDataMap[team1Id].gameDetails)
    )
    : []
    ;
  const team2GamesInView = (matchInfo && props.AppStore.teamProfileDataMap[team2Id])
    ? (
      matchInfo.state === 'completed'
        ? toJS(
          props.AppStore.teamProfileDataMap[team2Id].gameDetailsBeforeTime
          && props.AppStore.teamProfileDataMap[team2Id].gameDetailsBeforeTime[matchInfo.startTime]
        )
        : toJS(props.AppStore.teamProfileDataMap[team2Id].gameDetails)
    )
    : []
    ;

  // This search for the tournament's name and ID in the roster members.
  // It goes multiple players deep in case one or more didn't play.
  const team1TournamentName = team1Roster
    && team1Roster.length > 0
    && (
      team1Roster[0].tournamentName
      || team1Roster[1].tournamentName
      || team1Roster[2].tournamentName
    );
  const team2TournamentName = team2Roster
    && team2Roster.length > 0
    && (
      team2Roster[0].tournamentName
      || team2Roster[1].tournamentName
      || team2Roster[2].tournamentName
    );
  const team1TournamentId = team1Roster
    && team1Roster.length > 0
    && (
      team1Roster[0].tournamentId
      || team1Roster[1].tournamentId
      || team1Roster[2].tournamentId
    );
  const team2TournamentId = team2Roster
    && team2Roster.length > 0
    && (
      team2Roster[0].tournamentId
      || team2Roster[1].tournamentId
      || team2Roster[2].tournamentId
    );

  useEffect(() => {
    const getRoster = (async (teamNumber, teamId) => {
      switch (teamNumber) {
        case '1':
          props.AppStore.roster1Fetching.set(true);
          break;
        case '2':
          props.AppStore.roster2Fetching.set(true);
          break;
        default:
        // No need for default
      }

      var rosterData = [];
      if (matchInfo && teamId !== 'TBD' && (matchInfo.state === 'completed')) {
        rosterData = await API.getTeamRoster(teamId, props.matchId, teamNumber);
      } else {
        rosterData = await API.getTeamRoster(teamId);
      }
      props.AppStore.addRosterData(
        teamId,
        rosterData,
        matchInfo.state === 'completed' ? props.matchId : false
      );
      switch (teamNumber) {
        case '1':
          props.AppStore.roster1Fetching.set(false);
          props.AppStore.roster1Fetched.set(true);
          break;
        case '2':
          props.AppStore.roster2Fetching.set(false);
          props.AppStore.roster2Fetched.set(true);
          break;
        default:
        // No need for default
      }
    })

    const getRecentGames = (async (teamNumber, teamId) => {
      // This is firing twice for each team; some kind of race condition.
      switch (teamNumber) {
        case '1':
          props.AppStore.matchTeam1GamesAreFetching.set(true);
          break;
        case '2':
          props.AppStore.matchTeam2GamesAreFetching.set(true);
          break;
        default:
        // No need for default
      }

      const gamesFetchSuccess = teamId === 'TBD'
        ? false
        : await props.AppStore.fetchGameDetails(
          `${matchInfo.state === 'completed' ? 'completedMatch' : 'matchPreview'}`,
          teamId,
          { beforeTime: matchInfo.startTime }
        );

      if (!gamesFetchSuccess) {
        console.log(`Unable to fetch games for team ${teamId}`)
      }

      switch (teamNumber) {
        case '1':
          props.AppStore.matchTeam1GamesAreFetching.set(false);
          props.AppStore.matchTeam1GamesAreFetched.set(true);
          break;
        case '2':
          props.AppStore.matchTeam2GamesAreFetching.set(false);
          props.AppStore.matchTeam2GamesAreFetched.set(true);
          break;
        default:
        // No need for default
      }
    })

    if (!roster1Fetched && !roster1Fetching) {
      getRoster('1', team1Id);
    }
    if (!roster2Fetched && !roster2Fetching) {
      getRoster('2', team2Id);
    }

    if (!team1GamesAreFetching && !team1GamesAreFetched) {
      getRecentGames('1', team1Id);
    }
    if (!team2GamesAreFetching && !team2GamesAreFetched) {
      getRecentGames('2', team2Id);
    }
  }, [
    props.AppStore,
    props.AppStore.teamRosterMap,
    props.matchId,
    team1Id,
    team2Id,
    matchInfo,
    roster1Fetching,
    roster1Fetched,
    roster2Fetching,
    roster2Fetched,
    team1Roster,
    team2Roster,
    team1GamesAreFetching,
    team1GamesAreFetched,
    team2GamesAreFetching,
    team2GamesAreFetched
  ])

  return (
    (props.tab === 'overview') ? (
      <Container>
        <BodyWrapper safari={isSafari}>
          <Body>
            <RostersContainer>
              <Rosters>
                {(roster1Fetched && roster2Fetched) ? (
                  <>
                    <TeamRoster>
                      <TeamRosterTitleContainer>
                        <TeamRosterTitle>
                          <Link to={`/team/${matchInfo.team1Id}`}>
                            {matchInfo.team1Code}
                          </Link>
                        </TeamRosterTitle>
                        <TournamentStatsTitle>
                          <Link to={`/stats/players/byTournament/${encodeURIComponent(team1TournamentId)}`}>
                            {team1TournamentName} Stats
                          </Link>
                        </TournamentStatsTitle>
                      </TeamRosterTitleContainer>
                      {
                        (team1Roster.length > 0) ? (
                          team1Roster.filter(player => player.role !== 'Coach').map(player => {
                            return (
                              <React.Fragment key={`${player.playerId}-in-roster`}>
                                <PlayerRow playerInfo={player} />
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <NoRoster>No roster available</NoRoster>
                        )
                      }
                    </TeamRoster>
                    <TeamRoster>
                      <TeamRosterTitleContainer>
                        <TeamRosterTitle>
                          {
                            (matchInfo.team2Id !== 'TBD') ? (
                              <Link to={`/team/${matchInfo.team2Id}`}>
                                {matchInfo.team2Code}
                              </Link>
                            ) : (
                              'Team TBD'
                            )
                          }
                        </TeamRosterTitle>
                        <TournamentStatsTitle>
                          {
                            (team2Roster.length > 0) ? (
                              <Link to={`/stats/players/byTournament/${encodeURIComponent(team2TournamentId)}`}>
                                {team2TournamentName} Stats
                              </Link>
                            ) : (
                              null
                            )
                          }
                        </TournamentStatsTitle>
                      </TeamRosterTitleContainer>
                      {
                        (team2Roster.length > 0) ? (
                          team2Roster.filter(player => player.role !== 'Coach').map(player => {
                            return (
                              <React.Fragment key={`${player.playerId}-in-roster`}>
                                <PlayerRow playerInfo={player} />
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <NoRoster>No roster available</NoRoster>
                        )
                      }
                    </TeamRoster>
                  </>
                ) : (
                  <SpinnerComponent />
                )}
              </Rosters>
            </RostersContainer>
            <RecentGamesContainer>
              {(team1GamesAreFetched && team2GamesAreFetched) ? (
                <>
                  <RecentGamesHeader>{matchInfo.state === 'completed' ? 'Previous' : 'Recent'} Games</RecentGamesHeader>
                  <RecentGamesContentContainer>
                    <RecentGamesTeam1>
                      <RecentGamesTeamHeader>
                        {
                          matchInfo.team1Id !== 'TBD'
                            ? (
                              <Link to={`/team/${matchInfo.team1Id}`}>
                                {matchInfo.team1Name}
                              </Link>
                            )
                            : 'Team TBD'
                        }
                      </RecentGamesTeamHeader>
                      <RecentGamesGameListContainer>
                        {(team1GamesInView.length > 0) ? (
                          team1GamesInView.map((game, rowIndex) => {
                            return (
                              <React.Fragment key={`${game.gameId}-row`}>
                                <GameRow
                                  key={game.gameId}
                                  type='matchPreviewPerspective'
                                  game={game}
                                  rowIndex={rowIndex}
                                />
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <NoGames>
                            No games available
                          </NoGames>
                        )}
                      </RecentGamesGameListContainer>
                    </RecentGamesTeam1>
                    <RecentGamesTeam2>
                      <RecentGamesTeamHeader>
                        {
                          matchInfo.team2Id !== 'TBD'
                            ? (
                              <Link to={`/team/${matchInfo.team2Id}`}>
                                {matchInfo.team2Name}
                              </Link>
                            )
                            : 'Team TBD'
                        }
                      </RecentGamesTeamHeader>
                      <RecentGamesGameListContainer>
                        {(team2GamesInView.length > 0) ? (
                          team2GamesInView.map((game, rowIndex) => {
                            return (
                              <React.Fragment key={`${game.gameId}-row`}>
                                <GameRow
                                  key={game.gameId}
                                  type='matchPreviewPerspective'
                                  game={game}
                                  rowIndex={rowIndex}
                                />
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <NoGames>
                            No games available
                          </NoGames>
                        )}
                      </RecentGamesGameListContainer>
                    </RecentGamesTeam2>
                  </RecentGamesContentContainer>
                </>
              ) : (
                <SpinnerComponent />
              )}
            </RecentGamesContainer>
          </Body>
        </BodyWrapper>
      </Container>
    ) : (
      null
    )
  )
}

export default inject("AppStore")(observer(Overview));