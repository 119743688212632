import React from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import SpinnerComponent from '../../Shared/Spinners';

import MostRecentEvents from './MostRecentEvents';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  max-height: 30em;

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }

  @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
  { @media {
    max-height: none;
  }}
`

const NavLeagueList = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 8em;
  margin-right: 1em;
  overflow-y: scroll;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  font-size: .8em;
`

const NavTournamentList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  overflow-x: scroll;
  overflow-y: scroll;
  font-size: .8em;
`

const ItemInList = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px dashed #ccc;
  padding: .2em;
  font-weight: ${props => props.title ? 'bold' : null};

  &:last-child {
    border-bottom: none;
  }
`

const Column1 = styled.div`
  flex: 1;
  padding-left: 1em;
`

const RegionContainer = styled.div`
  border-left: #D86B22;
  padding: 0 0 0 .2em;
  background: #D86B22;
  position: ${props => props.active ? '-webkit-sticky' : null};
  position: ${props => props.active ? 'sticky' : null};
  top: ${props => props.active ? '0' : null};
  bottom: ${props => props.active ? '0' : null};
  font-size: .8em;
  text-transform: uppercase;
  color: #fff;
`

const RegionInList = ({ name }) => {
  return (
    <RegionContainer>
      {name}
    </RegionContainer>
  )
}

const LeagueInList = styled.div`
  border-left: ${props => props.active ? '1px solid #228fd8' : '1px solid #ccc'};
  border-bottom: ${props => props.active ? '1px solid #228fd8' : '1px solid #ccc'};
  padding: .2em;
  font-weight: bold;
  background: ${props => props.active ? '#228fd8' : 'inherit'};
  color: ${props => props.active ? '#fff' : 'inherit'};
  position: ${props => props.active ? '-webkit-sticky' : null};
  position: ${props => props.active ? 'sticky' : null};
  top: ${props => props.active ? '0' : null};
  bottom: ${props => props.active ? '0' : null};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
    background: #228fd8;
    border-bottom: 1px solid #228fd8;
    border-left: 1px solid #228fd8;
    color: #fff;
  }

  &:hover&:first-child {
    border-top: 1px solid #228fd8;
  }
`

const LeagueInMenu = (props) => {
  return (
    <LeagueInList
      onClick={() => {
        props.AppStore.setStatsNavLeagueSelected(props.league.id)
      }}
      active={props.active}
    >
      {props.league.name}
    </LeagueInList>
  )
}

/**
 * Display list of leagues, and populate a list of tournaments from that league on click.
 */
const StatsNav = (props) => {
  const tournamentsByLeagueMap = toJS(props.AppStore.tournamentsByLeagueMap);
  const statsNavLeagueSelected = props.AppStore.statsNavLeagueSelected.get();

  if (!props.AppStore.leaguesAreFetched.get() || !props.AppStore.regionsAreFetched.get() || !props.AppStore.tournamentsByLeagueAreFetched.get()) {
    return (
      <SpinnerComponent />
    )
  } else {
    return (
      <Container>
        <NavLeagueList>
        {props.AppStore.regions.map(region => {
          return (
            <React.Fragment key={region.region}>
              <RegionInList name={region.region} />
              {props.AppStore.leagues.filter((league) => league.region === region.region).map((league) => {
                  return (
                    <LeagueInMenu
                      key={league.id}
                      league={league}
                      AppStore={props.AppStore}
                      active={statsNavLeagueSelected === league.id ? true : false}
                    />
                  )
              })}
            </React.Fragment>
          )
        })}
        </NavLeagueList>
        {statsNavLeagueSelected ? (
          <NavTournamentList>
            <ItemInList title="true">
              <Column1>Event Title</Column1>
              {/* <Column2>Most Recent Game</Column2> */}
            </ItemInList>
            {tournamentsByLeagueMap[statsNavLeagueSelected].map((tournament) => {
              return (
                <ItemInList key={tournament.id}>
                  <Column1>
                    <Link to={`/stats/${props.category}/byTournament/${encodeURIComponent(tournament.id)}`}>{tournament.name}</Link>
                  </Column1>
                  {/* <Column2>
                    <Moment
                      date={tournament.mostRecentGame}
                      format="MMM D, YYYY h:mm A"
                    />
                  </Column2> */}
                </ItemInList>
              )
            })}
          </NavTournamentList>
          ) : (
            <MostRecentEvents category={props.category} />
          )}
      </Container>
    )
  }
}

export default inject('AppStore')(observer(StatsNav))
