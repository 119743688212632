import React, { useEffect } from 'react';
import { entries } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from './Sidebars/LeftSidebar';

const Wrapper = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  max-width: 60em;
  font-size: .85em;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: .5rem;
`

const Content = styled.div`
  font-size: 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

const DefinitionsTable = styled.div`
  max-height: 30em;
  overflow-y: scroll;
  border: 1px solid #ccc;
`

const TableTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

const DefinitionTable = styled.table`
  margin: 0;
  border: none;
`
const DefinitionTableBody = styled.tbody`
  border: none;
`
const DefinitionTableRow = styled.tr`
  border-top: 1px dashed #ccc;
  border-left: none;
  border-right: none;
  border-bottom: none;

  &:first-child {
    border-top: none;
  }
`
const DefinitionCell = styled.td`
  min-width: 6em;
  font-weight: ${props => props.type === 'label' ? 'bold' : 'normal'};
`

const Definitions = ({ AppStore }) => {
  const statsDefinitionsAreFetched = AppStore.statsDefinitionsAreFetched.get();
  const statsDefinitionsAreFetching = AppStore.statsDefinitionsAreFetching.get();

  useEffect (() => {
    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      AppStore.getStatsDefinitions();
    }
  }, [AppStore, statsDefinitionsAreFetched, statsDefinitionsAreFetching]);

  const pageTitle = "Oracle's Elixir - Contact us";
  const pageDescription = "Get in touch with Tim Sevenhuysen about Oracle's Elixir's LoL esports stats and coverage.";

  return (
    <>
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TimSevenhuysen" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
    <LeftSidebar />
    <Wrapper className="mainContent">
      <Title>
        Definitions
      </Title>
      <Content>
		    <strong>Note on Use of Averages</strong>
        <p>
          In most of the stats on this site, values are calculated for each individual game, then averaged across games.
          This is true for the "share" and "per minute" numbers for damage to champions, earned gold, wards placed/cleared,
          CKPM, and so on. Some of the numbers used on broadcasts use totals rather than averages, which can make minor differences
          to some values. I prefer averages because they reduce the impact of game length. Bear this in mind when comparing
          between <em>Oracle's Elixir</em> and stats you find elsewhere.
        </p>
        <TableTitle>
          Stats and Definitions
        </TableTitle>
        <DefinitionsTable>
          <DefinitionTable>
            <DefinitionTableBody>
              {statsDefinitionsAreFetched && (
                entries(AppStore.statsDefinitionsMap).map((statDefinition) => {
                  return (
                    <DefinitionTableRow key={`${statDefinition}-row`}>
                      <DefinitionCell type='label'>{statDefinition[0]}</DefinitionCell>
                      <DefinitionCell>{statDefinition[1]}</DefinitionCell>
                    </DefinitionTableRow>
                  )
                })
              )}
            </DefinitionTableBody>
          </DefinitionTable>
        </DefinitionsTable>
      </Content>
    </Wrapper>
    </>
  )
}

export default inject('AppStore')(observer(Definitions));
