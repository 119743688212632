import moment from 'moment';

export const constructArticleForStore = (article) => {
  return {
    id: article.id,
    title: article.title.rendered ? article.title.rendered : article._embedded.self[0].title.rendered,
    slug: article.slug ? article.slug : article._embedded.self[0].slug,
    content: article.content ? article.content.rendered : null,
    excerpt: article.rich_excerpt ? article.rich_excerpt : article._embedded.self[0].rich_excerpt,
    featuredMedia: 'jetpack_featured_media_url' in article ? article.jetpack_featured_media_url : article._embedded.self[0].jetpack_featured_media_url,
    categories: article._embedded['wp:term'] ? article._embedded['wp:term'][0] : null,
    tags: article._embedded['wp:term'] ? article._embedded['wp:term'][1] : null,
    authors: article._embedded.author ? article._embedded.author : [],
    date: article.date_gmt ? moment(`${article.date_gmt}Z`).format('LLL') : moment(article._embedded.self[0].date).format('LLL')
  }
}
