import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Moment from 'react-moment';

import SpinnerComponent from '../../Shared/Spinners';

const MostRecentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  border: 1px solid #ccc;
  font-size: .8em;
`

const ItemInList = styled.div`
  display: flex;
  flex-direction: row;
  padding: .2em;
  border-bottom: 1px dashed #ccc;
  font-weight: ${props => props.title ? 'bold' : null};

  &:last-child {
    border-bottom: none;
  }
`

const Column1 = styled.div`
  flex: 1;
  padding-left: 1em;
`

const Column2 = styled.div`
  flex: 1;
  text-align: center;
`

const MostRecentEvents = (props) => {
  return (
    <MostRecentContainer>
      <ItemInList title="true">
        <Column1>Most Recent Events</Column1>
        <Column2>Most Recent Game</Column2>
      </ItemInList>
      {props.AppStore.latestTournamentsAreFetching.get() && (
        <SpinnerComponent />
      )}
      {!props.AppStore.latestTournamentsAreFetching.get() && (
        props.AppStore.latestTournaments.map(tournament => {
          return (
            <ItemInList key={`${tournament.id}`}>
              <Column1><Link to={`/stats/${props.category}/byTournament/${encodeURIComponent(tournament.id)}`}>{tournament.name}</Link></Column1>
              <Column2>
                <Moment
                  date={tournament.mostRecentGame}
                  format="MMM D, YYYY h:mm A"
                />
              </Column2>
            </ItemInList>
          );
        })
      )}
    </MostRecentContainer>
  )
}

export default inject('AppStore')(observer(MostRecentEvents))
