import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import Home from '../components/Home';
import Profile from '../components/User/Profile';
import SignUpPage from '../components/User/SignUp';
import SignInPage from '../components/User/SignIn';
import ChangePasswordPage from '../components/User/ChangePassword';
import ResetPasswordPage from '../components/User/ResetPassword';
import About from '../components/About';
import Contact from '../components/Contact';
import FAQ from '../components/FAQ';
import Definitions from '../components/Definitions';
import Future from '../components/Future';
import Tools from '../components/Tools/Downloads.js';
import EGR from '../components/Tools/EGRCalculator.js';
import Blog from '../components/Blog';
import PlayersIndex from '../components/Players';
import PlayerProfile from '../components/Players/Player.js';
import TeamsIndex from '../components/Teams';
import TeamProfile from '../components/Teams/Team.js';
import Match from '../components/Matches/Match.js';
import Game from '../components/Games/Game.js';
import Stats from '../components/Stats';
// import CQLeaderboard from '../components/ChampionsQueue/Leaderboard.js';
import FourOhFour from '../components/FourOhFour';
// import Live from '../components/Live';

const Routes = () => {
  return (
    <>
    <Switch>
      {/* <Route path='/live' component={Live} /> */}
      {/* <Route path='/cq/leaderboard/:split?' component={CQLeaderboard} /> */}
      <Route path='/statistics/:oldUrlStructureLeague/:oldUrlStructureSlug' component={withRouter(Stats)} />
      <Route path='/stats/:category/:view/:tournament?' component={withRouter(Stats)} />
      <Route path='/games/:gameId/:tab?' component={Game} />
      <Route path='/matches/:id/:tab?/:param1?/:param2?' component={Match} />
      <Route path='/team/:id/:tab?/:mapSide?' component={TeamProfile} />
      <Route path='/teams/:search?' component={withRouter(TeamsIndex)} />
      <Route path='/player/:id/:tab?/:parameter?' component={PlayerProfile} />
      <Route path='/players/:search?' component={withRouter(PlayersIndex)} />
      <Route path='/20:year/:month/:slug' component={Blog} />
      <Route path='/blog/search/:searchTerm/:page?' component={Blog} />
      <Route path='/blog/category/:category/:page?' component={Blog} />
      <Route path='/blog/feed/:page' component={Blog} />
      <Route path='/blog/post/:id' component={Blog} />
      <Route path='/blog' exact component={Blog} />
      <Route path='/tools/egr' exact component={EGR} />
      <Route path='/tools/downloads' exact component={Tools} />
      <Route path='/future' exact component={Future} />
      <Route path='/definitions' exact component={Definitions} />
      <Route path='/faq' exact component={FAQ} />
      <Route path='/contact' exact component={Contact} />
      <Route path='/about' exact component={About} />
      <Route path='/resetPassword' exact component={ResetPasswordPage} />
      <Route path='/changePassword' exact component={ChangePasswordPage} />
      <Route path='/signin' exact component={SignInPage} />
      <Route path='/signup' exact component={SignUpPage} />
      <Route path='/profile' exact component={Profile} />
      <Route path='/' exact component={Home} />
      <Route path='/404' component={FourOhFour} />
      <Route component={FourOhFour} />
    </Switch>
    </>
  )
}

export default Routes;