import React from 'react';
import { TwitchPlayer } from 'react-twitch-embed';

const TwitchEmbed = ({ url }) => {
  var indexOfVideoIdStart = url.indexOf('/videos') !== -1
    ? url.indexOf('/videos') + 8
    : url.indexOf('/v/') ? url.indexOf('/v/') + 3
      : url.indexOf('/b/') ? url.indexOf('/b/') + 3
        : false;
  if (!indexOfVideoIdStart) {
    console.log('Improperly formed Twitch embed URL')
    return null;
  }

  var indexOfTimeStringStart = (url.indexOf('?t=') !== -1) ? url.indexOf('?t=') + 3 : false;

  var videoId = url.substring(indexOfVideoIdStart, indexOfTimeStringStart ? indexOfTimeStringStart - 3 : 1000);
  var timeString = url.substring(indexOfTimeStringStart, 1000)

  return (
    <TwitchPlayer
      video={videoId}
      time={timeString ? timeString : null}
      autoplay={false}
      width='100%'
      height='100%'
      className='video-responsive'
    />
  );
}

export default TwitchEmbed;