import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import * as API from "../../lib/api";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import { trackEGRCalculator } from "../../services/eventTracking";

import SpinnerComponent from "../Shared/Spinners";
import LeftSidebar from "../Sidebars/LeftSidebar";

import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 40em;
  font-size: 0.85em;
`;

const Title = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30em;
`;

const Calculator = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 1em;
  border: 1px solid #ccc;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Result = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  font-style: italic;
`;

const Percentage = styled.span`
  font-size: 5em;
  margin-bottom: -0.2em;
  font-style: normal;
  color: ${(props) =>
    props.value > 60 ? "#34D822" : props.value < 40 ? "#C622D8" : "#228fd8"};
`;
const PercentageSign = styled.span`
  font-size: 0.5em;
  font-style: italic;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  margin-bottom: 0.5em;
  font-size: 0.9em;
`;

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 10em;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;

  > select {
    height: 2em;
    font-size: 1.2em;
    font-weight: bold;
  }

  > label {
    color: #d86b22;
  }
`;

const SideAndGoldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2em;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;

  > select {
    font-size: 1.2em;
    height: 2em;
    width: 5em;
  }

  > label {
    color: #d86b22;
  }
`;

const GoldContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: #333;

  > input {
    font-size: 1.2em;
    height: 2em;
    text-align: center;
  }

  > label {
    color: #d86b22;
  }
`;

const DragonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DragonTitle = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
  color: #d86b22;
`;

const DragonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.2em 0;

  > div > label {
    background-color: #228fd8;
    border: none;
    opacity: 0.8;
    width: 2.5em;
    height: 1.4em;
    line-height: 0.5em;
    margin-left: 0 !important;

    &:hover,
    :focus {
      background-color: #228fd8;
      opacity: 1;
    }
  }
  > div > label.btn-primary:not(:disabled):not(.disabled).active {
    background-color: #228fd8;
    border: none;
    opacity: 1;
  }
`;

const DragonLabel = styled.div`
  width: 5.5em;
  text-transform: capitalize;
  color: #333;
`;

const Reset = styled.div`
  width: 100%;
  margin-top: 0.2em;
  padding: .2em;
  border: 1px dashed #ccc;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;

  cursor: pointer;

  &:hover,
  :focus {
    background: #228fd8;
    color: white;
  }

  &:active {
    opacity: 0.8;
  }
`;

const EGRCalculator = ({ AppStore }) => {
  const pageTitle = "Early-Game Win Probability Calculator - Oracle's Elixir";
  const pageDescription =
    "Win probability calculator at 15:00 for <em>LoL</em> esports.";

  const coefficientsFetched = AppStore.egrCoefficientsFetched.get();
  const egrSplitSelected = AppStore.egrSplitSelected.get();
  const egrSide = AppStore.egrSide.get();
  const egrGoldDiff = AppStore.egrGoldDiff.get();
  const egrInfernalDiff = AppStore.egrInfernalDiff.get();
  const egrMountainDiff = AppStore.egrMountainDiff.get();
  const egrOceanDiff = AppStore.egrOceanDiff.get();
  const egrCloudDiff = AppStore.egrCloudDiff.get();
  const egrChemtechDiff = AppStore.egrChemtechDiff.get();
  const egrHextechDiff = AppStore.egrHextechDiff.get();

  const logOdds =
    egrSide *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].side
        : 0) +
    egrGoldDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].goldDiff
        : 0) +
    egrInfernalDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].infernalDiff
        : 0) +
    egrMountainDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].mountainDiff
        : 0) +
    egrOceanDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].oceanDiff
        : 0) +
    egrCloudDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].cloudDiff
        : 0) +
    egrChemtechDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].chemtechDiff
        : 0) +
    egrHextechDiff *
      (AppStore.egrCoefficients[egrSplitSelected]
        ? AppStore.egrCoefficients[egrSplitSelected].hextechDiff
        : 0);
  const odds = 2.71828182845904523536 ** logOdds;
  var winProbability = ((odds / (1 + odds)) * 100).toFixed(1);
  if (winProbability === "100.0") {
    winProbability = "100";
  }

  useEffect(() => {
    const fetchEgrCoefficients = async () => {
      const egrCoefficients = await API.getEgrCoefficients();
      AppStore.setEgrCoefficients(egrCoefficients);
    };

    if (!coefficientsFetched) {
      fetchEgrCoefficients();
    }
  }, [
    AppStore,
    coefficientsFetched,
    AppStore.egrCoefficients,
    egrSplitSelected,
    egrSide,
    egrGoldDiff,
    egrInfernalDiff,
    egrMountainDiff,
    egrOceanDiff,
    egrCloudDiff,
    egrChemtechDiff,
    egrHextechDiff,
  ]);

  const handleSplitChange = (event) => {
    event.preventDefault();
    AppStore.setEgrSplit(event.target.value);
    trackEGRCalculator({
      control: "Change split",
      value: event.target.value,
    });
  };
  const handleSideChange = (event) => {
    event.preventDefault();
    AppStore.setEgrSide(event.target.value);
    trackEGRCalculator({
      control: "Change side",
      value: event.target.value,
    });
  };
  const handleGoldDiffChange = (event) => {
    event.preventDefault();
    AppStore.setEgrGoldDiff(event.target.value);
    trackEGRCalculator({
      control: "Change gold",
      value: event.target.value,
    });
  };
  const handleDragonChange = (event, dragonType, value) => {
    event.preventDefault();
    switch (dragonType) {
      case "infernal":
        AppStore.egrInfernalDiff.set(value);
        break;
      case "mountain":
        AppStore.egrMountainDiff.set(value);
        break;
      case "ocean":
        AppStore.egrOceanDiff.set(value);
        break;
      case "cloud":
        AppStore.egrCloudDiff.set(value);
        break;
      case "chemtech":
        AppStore.egrChemtechDiff.set(value);
        break;
      case "hextech":
        AppStore.egrHextechDiff.set(value);
        break;
      default:
        console.log("No valid dragon type provided");
    }
    trackEGRCalculator({
      control: `Change ${dragonType} drakes`,
      value: value,
    });
  };

  const arrayOfDragonTypes = parseInt(egrSplitSelected.substring(0, 4)) > 2021
    ? ["infernal", "mountain", "ocean", "cloud", "chemtech", "hextech"]
    : ["infernal", "mountain", "ocean", "cloud"]
    ;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <Title>Early-Game Win Probability Calculator</Title>
        <Content>
          <Description>
            <p>
              To use the calculator, select a split, choose your map side, and
              enter the gold difference and elemental drake differences as of
              the 15:00 mark of the game.
            </p>
            <p style={{ fontStyle: "italic" }}>
              For more information, read about the{" "}
              <Link to="/blog/post/10689/early-game-rating-2-0">
                early-game rating model
              </Link>
              .
            </p>
          </Description>
          {!AppStore.egrCoefficientsFetched.get() ? (
            <SpinnerComponent />
          ) : (
            <>
              <Calculator>
                <Controls>
                  <SplitContainer>
                    <label htmlFor="split">Split</label>
                    <select
                      name="split"
                      value={egrSplitSelected}
                      onChange={handleSplitChange}
                    >
                      {Object.keys(AppStore.egrCoefficients).map((split) => {
                        return (
                          <option key={split} value={split}>
                            {split}
                          </option>
                        );
                      })}
                    </select>
                  </SplitContainer>
                  <SideAndGoldWrapper>
                    <SideContainer>
                      <label htmlFor="side">Map Side</label>
                      <select
                        name="side"
                        value={egrSide}
                        onChange={handleSideChange}
                      >
                        <option value={0}>Any</option>
                        <option value={-1}>Blue</option>
                        <option value={1}>Red</option>
                      </select>
                    </SideContainer>
                    <GoldContainer>
                      <label htmlFor="goldDiff">Gold Difference</label>
                      <input
                        type="number"
                        name="goldDiff"
                        step="100"
                        value={egrGoldDiff}
                        onFocus={() => {
                          if (parseInt(egrGoldDiff) === 0) {
                            AppStore.setEgrGoldDiff("");
                          }
                        }}
                        onChange={handleGoldDiffChange}
                        style={{ width: "8em" }}
                      />
                    </GoldContainer>
                  </SideAndGoldWrapper>
                  <DragonsWrapper>
                    <DragonTitle>Elemental Drakes Difference</DragonTitle>
                    {arrayOfDragonTypes.map(
                      (dragonType) => {
                        var currentValue = 0;
                        switch (dragonType) {
                          case "infernal":
                            currentValue = egrInfernalDiff;
                            break;
                          case "mountain":
                            currentValue = egrMountainDiff;
                            break;
                          case "cloud":
                            currentValue = egrCloudDiff;
                            break;
                          case "ocean":
                            currentValue = egrOceanDiff;
                            break;
                          case "chemtech":
                            currentValue = egrChemtechDiff;
                            break;
                          case "hextech":
                            currentValue = egrHextechDiff;
                            break;
                          default:
                            currentValue = 0;
                        }
                        return (
                          <DragonBox key={`${dragonType}-selector`}>
                            <DragonLabel>{dragonType}</DragonLabel>
                            <ToggleButtonGroup
                              type="checkbox"
                              value={[currentValue]}
                            >
                              {[-2, -1, 0, 1, 2].map((value) => {
                                return (
                                  <ToggleButton
                                    key={`${dragonType}-toggle-${value}`}
                                    value={value}
                                    onClick={(event) =>
                                      handleDragonChange(
                                        event,
                                        dragonType,
                                        value
                                      )
                                    }
                                  >
                                    {value}
                                  </ToggleButton>
                                );
                              })}
                            </ToggleButtonGroup>
                          </DragonBox>
                        );
                      }
                    )}
                  </DragonsWrapper>
                </Controls>
                <Result>
                  <Percentage value={winProbability}>
                    {winProbability}
                    <PercentageSign>%</PercentageSign>
                  </Percentage>
                  Estimated
                  <br />
                  Win Probability
                </Result>
              </Calculator>
              <Reset
                onClick={() => {
                  AppStore.egrReset();
                  trackEGRCalculator({
                    control: "Reset",
                    value: "reset",
                  });
                }}
              >
                Reset
              </Reset>
            </>
          )}
        </Content>
      </Wrapper>
    </>
  );
};

// Make Tools/index import this component with an appropriate route

export default inject("AppStore")(observer(EGRCalculator));
