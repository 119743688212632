import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import LeftSidebar from "../Sidebars/LeftSidebar";

import BlogFeed from "./Feed";
import Article from "./Article";
import BlogSidebar from "./BlogSidebar";

const BlogContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1em;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const BlogWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 40em;
  min-height: 50em;
  margin-right: 2em;
`;

const Blog = (props) => {
  const blogIsFetching = props.AppStore.blogFetching.get();
  const idOrSlug = props.match.params.id
    ? "id"
    : props.match.params.slug
    ? "slug"
    : false;
  const postId = idOrSlug === "id" ? props.match.params.id : idOrSlug === "slug" ? props.AppStore.idOfSlugInView.get() : null;

  useEffect(() => {
    if (
      idOrSlug === "id" &&
      (!(postId in props.AppStore.articleMap) ||
        props.AppStore.articleMap[postId].content == null) && !blogIsFetching
    ) {
      props.AppStore.fetchSingleArticle(postId);
    } else if (idOrSlug === "slug" && props.match.params.slug !== props.AppStore.slugInView.get()) {
      props.AppStore.fetchSingleArticleBySlug(props.match.params.slug);
    }
  }, [
    idOrSlug,
    postId,
    props.match.params.slug,
    props.AppStore,
    props.AppStore.articleMap,
    props.AppStore.articlesInView,
    blogIsFetching
  ]);

  const pageTitle = "Articles and Analysis - Oracle's Elixir";
  const pageDescription = "Oracle's Elixir blog - League of Legends articles, analysis, infographics, stats, and more.";

  return (
    <>
      <LeftSidebar />
      <BlogContainer className="mainContent">
        <Content>
          <BlogWrapper>
            {idOrSlug ? (
              <Article
                post={
                  props.AppStore.articleMap[
                    postId
                  ]
                }
              />
            ) : (
              <>
              <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@TimSevenhuysen" />
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
              </Helmet>
              <BlogFeed />
              </>
            )}
          </BlogWrapper>
          <BlogSidebar />
        </Content>
      </BlogContainer>
    </>
  );
};

export default inject("AppStore")(observer(Blog));
