export function cleanCategoryName(categoryString) {
  const trimmedCategory = categoryString.substring(0, categoryString.length - 1);
  const capitalizedCategory = trimmedCategory[0].toUpperCase() + trimmedCategory.slice(1);

  return capitalizedCategory
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const championNamesMap = {
  Kaisa: "Kai'Sa",
  Nunu: "Nunu & Willump",
  Khazix: "Kha'Zix",
  MonkeyKing: "Wukong",
  TahmKench: "Tahm Kench",
  TwistedFate: "Twisted Fate",
  XinZhao: "Xin Zhao",
  AurelionSol: "Aurelion Sol",
  Chogath: "Cho'Gath",
  FiddleSticks: "Fiddlesticks",
  LeeSin: "Lee Sin",
  KogMaw: "Kog'Maw",
  Leblanc: "LeBlanc",
  MissFortune: "Miss Fortune",
  DrMundo: "Dr. Mundo",
  RekSai: "Rek'Sai",
  JarvanIV: "Jarvan IV",
  Renata: "Renata Glasc",
  KSante: "K'Sante",
  BelVeth: "Bel'Veth" // This might not be accurate
};

export const countOccurrences = (array, value) => {
  var count = 0;
  for (let item of array) {
    if (item === value) {
      count = count + 1;
    }
  }

  return count;
}

export const winProbCalculation = (stats, whichTeam) => {
  const coefficients = {
    goldPercent: 50.03994,
    infernalsDiff: .376141,
    mountainsDiff: .3570133,
    cloudsDiff: .3391317,
    oceansDiff: .3890975,
    chemtechsDiff: .2746016,
    hextechsDiff: .390727,
    inhibDiff: .6485006,
    activeBaronDiff: .4892219,
    activeElderDiff: .7290458,
    team: -.1999034,
    constant: -24.92012
  }

  const values = {
    goldPercent: null,
    totalGold: null,
    goldDiff: null,
    infernalsDiff: null,
    mountainsDiff: null,
    cloudsDiff: null,
    oceansDiff: null,
    chemtechsDiff: null,
    hextechsDiff: null,
    inhibDiff: null,
    activeBaronDiff: null,
    activeElderDiff: null,
    team: null
  }

  if (stats.blueTeam.totalGold < 9000 || stats.redTeam.totalGold < 9000) {
    return '-'
  }

  var logOdds = null;
  var odds = null;
  var winProb = null;

  switch (whichTeam) {
    case 'blue':
      values.goldPercent = stats.blueTeam.totalGold / (stats.blueTeam.totalGold + stats.redTeam.totalGold);
      values.totalGold = stats.blueTeam.totalGold;
      values.goldDiff = stats.blueTeam.totalGold - stats.redTeam.totalGold;
      values.infernalsDiff = countOccurrences(stats.blueTeam.dragons, 'infernal') - countOccurrences(stats.redTeam.dragons, 'infernal');
      values.mountainsDiff = countOccurrences(stats.blueTeam.dragons, 'mountain') - countOccurrences(stats.redTeam.dragons, 'mountain');
      values.cloudsDiff = countOccurrences(stats.blueTeam.dragons, 'cloud') - countOccurrences(stats.redTeam.dragons, 'cloud');
      values.oceansDiff = countOccurrences(stats.blueTeam.dragons, 'ocean') - countOccurrences(stats.redTeam.dragons, 'ocean');
      values.chemtechsDiff = countOccurrences(stats.blueTeam.dragons, 'chemtech') - countOccurrences(stats.redTeam.dragons, 'chemtech');
      values.hextechsDiff = countOccurrences(stats.blueTeam.dragons, 'hextech') - countOccurrences(stats.redTeam.dragons, 'hextech');
      values.inhibDiff = stats.blueTeam.killedInhibs - stats.redTeam.killedInhibs;
      values.activeBaronDiff = stats.blueTeam.activeBaron - stats.redTeam.activeBaron;
      values.activeElderDiff = stats.blueTeam.activeElder - stats.redTeam.activeElder;
      values.team = 0;
      logOdds = (
        values.goldPercent * coefficients.goldPercent
        + values.infernalsDiff * coefficients.infernalsDiff
        + values.mountainsDiff * coefficients.mountainsDiff
        + values.cloudsDiff * coefficients.cloudsDiff
        + values.oceansDiff * coefficients.oceansDiff
        + values.chemtechsDiff * coefficients.chemtechsDiff
        + values.hextechsDiff * coefficients.hextechsDiff
        + values.inhibDiff * coefficients.inhibDiff
        + values.activeBaronDiff * coefficients.activeBaronDiff
        + values.activeElderDiff * coefficients.activeElderDiff
        + values.team * coefficients.team
        + coefficients.constant
      );
      odds = Math.exp(logOdds);
      winProb = (odds / (1 + odds) * 100).toFixed(0) + '%';
      if (winProb === '100%') {
        winProb = '99%'
      } else if (winProb === '0%') {
        winProb = '1%'
      }
      return winProb;
    case 'red':
      values.goldPercent = stats.redTeam.totalGold / (stats.blueTeam.totalGold + stats.redTeam.totalGold);
      values.totalGold = stats.redTeam.totalGold;
      values.goldDiff = stats.redTeam.totalGold - stats.blueTeam.totalGold;
      values.infernalsDiff = countOccurrences(stats.redTeam.dragons, 'infernal') - countOccurrences(stats.blueTeam.dragons, 'infernal');
      values.mountainsDiff = countOccurrences(stats.redTeam.dragons, 'mountain') - countOccurrences(stats.blueTeam.dragons, 'mountain');
      values.cloudsDiff = countOccurrences(stats.redTeam.dragons, 'cloud') - countOccurrences(stats.blueTeam.dragons, 'cloud');
      values.oceansDiff = countOccurrences(stats.redTeam.dragons, 'ocean') - countOccurrences(stats.blueTeam.dragons, 'ocean');
      values.chemtechsDiff = countOccurrences(stats.redTeam.dragons, 'chemtech') - countOccurrences(stats.blueTeam.dragons, 'chemtech');
      values.hextechsDiff = countOccurrences(stats.redTeam.dragons, 'hextech') - countOccurrences(stats.blueTeam.dragons, 'hextech');
      values.inhibDiff = stats.redTeam.killedInhibs - stats.blueTeam.killedInhibs;
      values.activeBaronDiff = stats.redTeam.activeBaron - stats.blueTeam.activeBaron;
      values.activeElderDiff = stats.redTeam.activeElder - stats.blueTeam.activeElder;
      values.team = 1;
      logOdds = (
        values.goldPercent * coefficients.goldPercent
        + values.infernalsDiff * coefficients.infernalsDiff
        + values.mountainsDiff * coefficients.mountainsDiff
        + values.cloudsDiff * coefficients.cloudsDiff
        + values.oceansDiff * coefficients.oceansDiff
        + values.chemtechsDiff * coefficients.chemtechsDiff
        + values.hextechsDiff * coefficients.hextechsDiff
        + values.inhibDiff * coefficients.inhibDiff
        + values.activeBaronDiff * coefficients.activeBaronDiff
        + values.activeElderDiff * coefficients.activeElderDiff
        + values.team * coefficients.team
        + coefficients.constant
      );
      odds = Math.exp(logOdds);
      winProb = (odds / (1 + odds) * 100).toFixed(0) + '%';
      if (winProb === '100%') {
        winProb = '99%'
      } else if (winProb === '0%') {
        winProb = '1%'
      }
      return winProb;
    default:
      return '-';
  }
}

export const prepItems = (itemsArray) => {
  const Trinkets = [
    3330,
    3340,
    3363,
    3364,
    3513
  ];
  const Consumables = [
    2003,
    2010,
    2031,
    2033,
    2055,
    2138,
    2139,
    2140,
    2403,
    2419,
    2420,
    2421,
    2423,
    2424
  ];

  if (
    !itemsArray.includes(3363)
    && !itemsArray.includes(3364)
    && !itemsArray.includes(3513)
    && !itemsArray.includes(3330)
    && !itemsArray.includes(3340)
  ) {
    itemsArray.push(3340);
  }

  itemsArray.sort((itemA, itemB) => {
    if (Trinkets.includes(itemA)) {
      return 1;
    }
    if (Trinkets.includes(itemB)) {
      return -1;
    }
    if (Consumables.includes(itemA)) {
      return 1;
    };
    if (Consumables.includes(itemB)) {
      return -1;
    };
    return 0;
  })

  return itemsArray;
}

export const gradeMap = {
  20: 'S',
  19: 'A+',
  18: 'A',
  17: 'A',
  16: 'A-',
  15: 'A-',
  14: 'B+',
  13: 'B+',
  12: 'B',
  11: 'B',
  10: 'B',
  9: 'B',
  8: 'B',
  7: 'B-',
  6: 'B-',
  5: 'C+',
  4: 'C+',
  3: 'C',
  2: 'C',
  1: 'C-'
}