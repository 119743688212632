import React, { Component, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import SignOutButton from '../User/SignOut';

const SearchContainer = styled.form`
  max-width: 10em;
  margin-left: auto;

  @media (max-width: 992px) {
    float: none;
    margin: 0 auto;
  }
`

const SearchInput = styled.input`
  font-size: .8em;
  width: 100%;

  @media (max-width: 992px) {
    text-align: center;
  }
`

const AccountDropdownWrapper = styled.div`
  & div > div.dropdown-menu {
    left: -80px; // 76 is perfect
  }
`

class StatsNavDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <NavDropdown
        title="Stats"
        id="basic-nav-dropdown"
        className={this.props.location.pathname.substring(0, 6) === "/stats" ? "active" : null}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        show={this.state.isOpen}
      >
        <Nav className="dropdownNavLink">
          <NavLink to="/stats/players/byTournament" onClick={() => {this.props.onSelect(); this.handleClose();}}>Players</NavLink>
        </Nav>
        <Nav className="dropdownNavLink">
          <NavLink to="/stats/teams/byTournament" onClick={() => {this.props.onSelect(); this.handleClose();}}>Teams</NavLink>
        </Nav>
        <Nav className="dropdownNavLink">
          <NavLink to="/stats/champions/byTournament" onClick={() => {this.props.onSelect(); this.handleClose();}}>Champions</NavLink>
        </Nav>
        <Nav className="dropdownNavLink">
          <NavLink to="/stats/champions/explorer" onClick={() => {this.props.onSelect(); this.handleClose();}}>Champions Explorer</NavLink>
        </Nav>
        {
          process.env.REACT_APP_ENVIRONMENT === "DEV" ? (
            <Nav className="dropdownNavLink">
              <NavLink to="/stats/champions/soloQueueMatchups" onClick={() => {this.props.onSelect(); this.handleClose();}}>
                Solo Queue Matchups
              </NavLink>
            </Nav>
          ) : (
            null
          )
        }
      </NavDropdown>
    )
  }
}

class ToolsNavDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <NavDropdown
        title="Tools & Downloads"
        id="basic-nav-dropdown"
        className={this.props.location.pathname.substring(0, 6) === "/tools" ? "active" : null}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        show={this.state.isOpen}
      >
        <Nav className="dropdownNavLink">
          <NavLink to="/tools/downloads" onClick={() => {this.props.onSelect(); this.handleClose();}}>Downloads</NavLink>
        </Nav>
        <Nav className="dropdownNavLink">
          <NavLink to="/tools/egr" onClick={() => {this.props.onSelect(); this.handleClose();}}>Early-Game Win%</NavLink>
        </Nav>
      </NavDropdown>
    )
  }
}

class AccountNavDropdown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <AccountDropdownWrapper>
        <NavDropdown
          title={`Account` /* Change to show username? */}
          id="basic-nav-dropdown"
          className={this.props.location.pathname.substring(0, 6) === "/tools" ? "active" : null}
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
          show={this.state.isOpen}
        >
          {this.props.AppStore.sessionStore.authStatusHasBeenChecked.get() ? (
            this.props.AppStore.userStore.userInfo.get() ? (
              <>
                <span style={{ width: '10em', fontSize: '.6em', color: '#fff' }}>
                  Signed in as {this.props.AppStore.userStore.userInfo.get().username}
                </span>
                <Nav className="dropdownNavLink">
                  <NavLink to="/profile" onClick={() => {this.props.onSelect(); this.handleClose();}}>Your Profile</NavLink>
                </Nav>
                <Nav className="dropdownNavLink" onClick={() => {this.props.onSelect(); this.handleClose();}}>
                  <SignOutButton />
                </Nav>
              </>
            ) : (
              <>
                <Nav className="dropdownNavLink">
                  <NavLink to="/signin" onClick={() => {this.props.onSelect(); this.handleClose();}}>Sign In</NavLink>
                </Nav>
                <Nav className="dropdownNavLink">
                  <NavLink to="/signup" onClick={() => {this.props.onSelect(); this.handleClose();}}>Create an Account</NavLink>
                </Nav>
              </>
            )) : (
            <Nav className="dropdownNavLink">
              <NavLink to="/signin" onClick={() => {this.props.onSelect(); this.handleClose();}}>
                Checking login status
              </NavLink>
            </Nav>
          )}
        </NavDropdown>
      </AccountDropdownWrapper>
    )
  }
}

const Navigation = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [blogSearchTerm, setBlogSearchTerm] = useState("");
  const [playerSearchTerm, setPlayerSearchTerm] = useState("");
  const [teamSearchTerm, setTeamSearchTerm] = useState("");

  const doBlogSearch = (e) => {
    e.preventDefault();
    if (blogSearchTerm !== "") {
      props.history.push(`/blog/search/${encodeURIComponent(blogSearchTerm)}/1`);
    }
    setBlogSearchTerm("");
  }

  const doPlayerSearch = (e) => {
    e.preventDefault();
    if (playerSearchTerm !== "") {
      props.history.push(`/players/${encodeURIComponent(playerSearchTerm)}`);
    }
    setPlayerSearchTerm("");
    console.log(playerSearchTerm)
  }

  const doTeamSearch = (e) => {
    e.preventDefault();
    if (teamSearchTerm !== "") {
      props.history.push(`/teams/${encodeURIComponent(teamSearchTerm)}`);
    }
    setTeamSearchTerm("");
  }

  const onSelectItem = () => {
    setExpanded(false);
  }

  return (
    <Navbar
      expand="lg"
      expanded={expanded}
      sticky="top"
      variant="dark"
    >
      <NavLink
        to="/"
        exact
        className="logo"
      >
        <img
          alt="Oracle's Elixir"
          src="/navbar-logo.ico"
          width="44"
          height="44"
        />
      </NavLink>
      <img
        alt='League of Legends'
        src='/LoL_Icon_Flat_GOLD.png'
        width='24'
        height='24'
        style={{'marginLeft': '-.5em'}}
      />
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={
          () => setExpanded(expanded ? false : "expanded")
        }
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <StatsNavDropdown location={props.location} onSelect={onSelectItem} />
          <NavLink
            to="/players"
            exact
            activeStyle={{
              color: "#228fd8"
            }}
            onClick={() => onSelectItem()}
          >
            Players
          </NavLink>
          <NavLink
            to="/teams"
            exact
            activeStyle={{
              color: "#228fd8"
            }}
            onClick={() => onSelectItem()}
          >
            Teams
          </NavLink>
          {/* <NavLink
            to="/cq/leaderboard"
            exact
            activeStyle={{
              color: "#228fd8"
            }}
            onClick={() => onSelectItem()}
          >
            Champions Queue
          </NavLink> */}
          <NavLink
            to="/blog"
            exact
            activeStyle={{
              color: "#228fd8"
            }}
            onClick={() => onSelectItem()}
          >
            Blog
          </NavLink>
          <a
            href="https://anchor.fm/truesight"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => onSelectItem()}
          >
            Podcast
          </a>
          <ToolsNavDropdown location={props.location} onSelect={onSelectItem} />
        </Nav>
        <SearchContainer
          onSubmit={e => {doPlayerSearch(e); onSelectItem();}}
        >
          <SearchInput
            type="text"
            placeholder="Search Players"
            value={playerSearchTerm}
            onChange={e => { setPlayerSearchTerm(e.target.value) }}
          />
        </SearchContainer>
        <SearchContainer
          onSubmit={e => {doTeamSearch(e); onSelectItem();}}
        >
          <SearchInput
            type="text"
            placeholder="Search Teams"
            value={teamSearchTerm}
            onChange={e => { setTeamSearchTerm(e.target.value) }}
          />
        </SearchContainer>
        <SearchContainer
          onSubmit={e => {doBlogSearch(e); onSelectItem();}}
        >
          <SearchInput
            type="text"
            placeholder="Search Blog"
            value={blogSearchTerm}
            onChange={e => { setBlogSearchTerm(e.target.value) }}
          />
        </SearchContainer>
        <Nav className="ml-auto">
          <AccountNavDropdown AppStore={props.AppStore} location={props.location} onSelect={onSelectItem} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default withRouter(inject('AppStore')(observer(Navigation)));
