import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from '../Sidebars/LeftSidebar';
import TeamSearch from './TeamSearch';
import TeamRow from './TeamRow';
import ColumnHeader from './ColumnHeader';

import SpinnerComponent from "../Shared/Spinners";

const Wrapper = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  max-width: 60em;
`;

const Title = styled.div`
  margin-bottom: 0.1em;
  font-size: 1.2em;
  font-weight: bold;
`

const Content = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

const TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: .85em;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  overflow-x: hidden;
  min-height: 1.3em;
  margin-top: 0.5em;
  margin-right: ${(props) =>
    props.safari === true
      ? "0"
      : "17px"}; // To sync with scrollbar width of table below
  font-size: .85em;

  @media (max-width: 768px) {
    overflow-y: scroll;
    margin-right: 0;
  }
`;

const TeamListContainer = styled.div`
  max-height: 35em;
  overflow-y: scroll;
`

const TeamListEmptyContainer = styled.div`
  height: 15em;
  line-height: 15em;
  font-size: 1.2em;
  text-align: center;
`

const Teams = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;
  const searchTerm = props.match.params.search;
  const teamsInView = props.AppStore.teamsInView;
  const teamsAreFetching = props.AppStore.teamsAreFetching.get();

  const columns = ['name', 'tag', 'league', 'region', 'active'];

  useEffect(() => {
    if ((searchTerm && !(searchTerm in props.AppStore.teamSearchesFetched)) && !teamsAreFetching) {
      props.AppStore.fetchTeams(searchTerm);
    } else if ((searchTerm && (searchTerm in props.AppStore.teamSearchesFetched))) {
      props.AppStore.setTeamsInView(props.AppStore.teamSearchesFetched[searchTerm])
    }
  }, [
    props.AppStore,
    searchTerm,
    teamsAreFetching,
    teamsInView
  ]);

  const pageTitle = "Teams - Oracle's Elixir";
  const pageDescription = "Find teams from the LCS, LEC, LCK, LPL, and the rest of global League of Legends.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <Title>
          Teams
        </Title>
        <Content>
          <TeamSearch />
          {searchTerm ? (
            (teamsAreFetching) ? (
              <SpinnerComponent />
            ) : (
              <>
                <TeamsContainer>
                  {(!teamsAreFetching && teamsInView) && (
                    <>
                      <Header safari={isSafari}>
                        {columns.map(
                          (column, index) => {
                            return (
                              <ColumnHeader
                                key={`${column}`}
                                index={index}
                                label={`${column}`}
                                align={index > 0 ? 'center' : 'left'}
                                sortEnabled='false'
                              />
                            );
                          }
                        )}
                      </Header>
                      <TeamListContainer>
                        {teamsInView.length > 0 ? (
                          teamsInView.map(
                            (TeamId, rowIndex) => {
                              return (
                                <TeamRow
                                  teamId={TeamId}
                                  rowIndex={rowIndex}
                                  key={`${TeamId}-row`}
                                />
                              );
                            }
                          )
                        ) : (
                          <TeamListEmptyContainer>
                            No Teams found. Try another search?
                          </TeamListEmptyContainer>
                        )}
                      </TeamListContainer>
                    </>
                  )}
                </TeamsContainer>
              </>
            )
          ) : (
            <TeamListEmptyContainer>
              Search for Teams using the search box above.
            </TeamListEmptyContainer>
          )
          }
        </Content>
      </Wrapper>
    </>
  )
}

export default inject('AppStore')(observer(Teams));
