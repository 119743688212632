import React from 'react';
import { useLocation } from 'react-router-dom'
import styled from 'styled-components';

import { Helmet } from "react-helmet";

import LeftSidebar from './Sidebars/LeftSidebar';

const FourOhFourWrapper = styled.div`
  flex: 1;
  padding: 1em;
  padding-top: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6em;
  text-align: center;
`;


const FourOhFour = (props) => {
  const location = useLocation();

  var redirectPath = false;
  if (props.location.search) {
    redirectPath = location.search.slice(1).split('&');
  }

  const pageTitle = "Oracle's Elixir - Page not found";
  const pageDescription = `Cannot reject the null hypothesis that there is no page at ${redirectPath ? redirectPath : location.pathname}`;

  return (
    <>
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@TimSevenhuysen" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
    </Helmet>
    <LeftSidebar />
    <FourOhFourWrapper className="mainContent">
      Cannot reject the null hypothesis<br />
      that there is no page at<br />
      <span style={{fontWeight: "bold", display: "inline"}}>{redirectPath ? redirectPath : location.pathname}</span> 
      {'(p < .05).'}
    </FourOhFourWrapper>
    </>
  )
}

export default FourOhFour;
