import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { digitCounts } from '../../static/fixedDigits';

const StatsRowContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const StatsBySplitStatBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.index === 0 ? 'flex-start' : 'center'};
    padding-left: ${props => props.index === 0 ? '3px' : null};
    align-items: center;
    text-align: ${props => props.index === 0 ? 'left' : 'center'};
    min-width: ${props => props.index === 0 ? '12em' : props.index === 1 ? '9em' : '5em'};
    min-height: 3em;
    border-bottom: 1px solid #ccc;
    border-right: 1px dashed #ccc;
    background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};}
    font-weight: ${props => props.index === 0 ? 'bold' : 'normal'};
    position: ${props => props.index === 0 ? '-webkit-sticky' : 'static'};
    position: ${props => props.index === 0 ? 'sticky' : 'static'};
    left: ${props => props.index === 0 ? '0' : 'auto'};

    & a {
        color: black;
    }

    & a:hover {
        color: #228fd8;
    }
`

const StatsBySplitRow = (props) => {
    const [hovered, setHovered] = useState(false);

    const tournamentId = encodeURIComponent(props.row.tournamentId);

    return (
        <StatsRowContainer
            key={props.row.Champion}
            rowIndex={props.rowIndex}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {props.AppStore.statsColumnsInView.map((stat, index) => {
                if (stat === 'tournamentId') {
                    return null;
                }

                var statValue = null;
                const statIsPopulated = props.row[stat] != null;
                if (!statIsPopulated) {
                    statValue = "-";
                } else if (
                    typeof props.row[stat] === "number" &&
                    stat in digitCounts
                ) {
                    statValue = props.row[stat].toFixed(digitCounts[stat]);
                } else {
                    statValue = props.row[stat];
                }
                return (
                    <React.Fragment key={`${props.row.Event}-${stat}-box`}>
                        <StatsBySplitStatBox
                            key={`${props.row.Event}-${stat}`}
                            index={index}
                            rowIndex={props.rowIndex}
                            hovered={hovered}
                        >
                            {index === 0 ? (
                                <Link to={`/stats/players/byTournament/${tournamentId}`}>
                                    {statValue}
                                </Link>
                            ) : (
                                statValue
                            )}
                        </StatsBySplitStatBox>
                    </React.Fragment>
                )
            })}
        </StatsRowContainer>
    )
}

export default inject("AppStore")(observer(StatsBySplitRow));