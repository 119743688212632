import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import DraftRow from './DraftRow';

import SpinnerComponent from "./Spinners";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.singleGame ? '6em' : '15em'};
  font-size: 0.68em;
  color: black;
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  min-width: 30em;

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const DraftsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4em;
  border-bottom: 1px solid black;
`

const TeamTab = styled.div`
  padding: 0 1em;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;
  cursor: pointer;

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`

const DraftsContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const DraftListContainer = styled.div`
  max-height: 40em;
  overflow-y: scroll;

  @media (max-width: 768px) {
    margin-bottom: 0.5em;
  }
`

const NoDrafts = styled.div`
  height: 10em;
  padding-top: 5.5em;
  text-align: center;
  font-size: 1.5em;
`

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 20em;
  border: 1px dashed #aaa;
`

const FiltersMapSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5em 0;
  color: #777;

  :first-child {
    border-left: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  }
`

const MapSideTab = styled.div`
  width: 5em;
  padding: 0 1em;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;
  cursor: pointer;

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`

const FiltersShowHideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0.5em 0 0.5em 2em;
  color: #777;
  cursor: pointer;
`
const ShowHideOption = styled.div`
  width: 8em;
  padding: 0 1em;
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  border-right: 1px solid ${props => !props.active ? '#ccc' : 'rgb(34, 143, 216)'};
  text-align: center;
  cursor: pointer;

  :first-child {
    border-left: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  }

  :last-child {
    border-right: 1px solid ${props => !props.active ? '#ccc' : 'rgb(34, 143, 216)'};
  }

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid rgb(34, 143, 216);
  }

  @media (max-width: 768px) {
    flex: 1;
  }
`


const Drafts = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const [showRoles, setShowRoles] = useState(true);
  const [showBlinds, setShowBlinds] = useState(false);

  const matchInfo = props.AppStore.matchMap[props.matchId];

  var filterLinksString = '';
  var teamInView = null;
  switch (props.view) {
    case 'matchPage':
      filterLinksString = `/matches/${matchInfo.matchId}/drafts`
      teamInView = matchInfo[`team${props.teamToView || 1}Id`];
      break;
    case 'teamProfile':
      filterLinksString = `/team/${encodeURIComponent(props.teamProfileId)}/drafts`;
      teamInView = props.teamProfileId;
      break;
    default:
    // Default not needed
  };

  const mapSide = props.AppStore.draftsMapSideInView.get() || 'all';

  const filters = useMemo(() => {
    switch (props.view) {
      case 'matchPage':
        return {
          before: matchInfo.state === 'completed' ? matchInfo.startTime : 'now',
          mapSide: mapSide ? mapSide : 'all'
        }
      case 'teamProfile':
        return {
          before: 'now',
          mapSide: mapSide ? mapSide : 'all'
        }
      case 'singleGame':
        return {
          gameId: props.gameId
        }
      default:
      // No default needed
    }
  }, [
    props.view,
    props.gameId,
    matchInfo,
    mapSide
  ]);
  const filterString = JSON.stringify(filters);

  const draftsAreFetching = props.AppStore.draftsAreFetching.get();
  const draftsAreFetched = props.view === 'singleGame' ? props.AppStore.gameDrafts[props.gameId] : Boolean(props.AppStore.teamDrafts[`${teamInView}-${filterString}`]);

  const draftsInView = props.view === 'singleGame' ? [props.AppStore.gameDrafts[props.gameId]] : props.AppStore.teamDrafts[`${teamInView}-${filterString}`];

  useEffect(() => {
    const getDrafts = (async (teamId) => {
      props.AppStore.draftsAreFetching.set(true);

      const draftsFetchSuccess = teamId === 'TBD'
        ? false
        : await props.AppStore.getTeamDrafts(
          teamId,
          filters
        );

      if (!draftsFetchSuccess) {
        console.log(`Unable to fetch drafts for team ${teamId}`)
      }

      props.AppStore.draftsAreFetching.set(false);
    })

    const getSingleGameDraft = (async (gameId) => {
      props.AppStore.draftsAreFetching.set(true);

      const draftFetchSuccess = await props.AppStore.getSingleGameDraft(gameId);

      if (!draftFetchSuccess) {
        console.log(`Unable to fetch draft for game ${gameId}`)
      }

      props.AppStore.draftsAreFetching.set(false);
    })

    if (!draftsAreFetching && !draftsAreFetched) {
      if (props.view === 'singleGame') {
        getSingleGameDraft(props.gameId)
      } else {
        getDrafts(teamInView);
      }
    }
  }, [
    props.view,
    props.AppStore,
    props.matchId,
    props.gameId,
    teamInView,
    matchInfo,
    filters,
    draftsAreFetching,
    draftsAreFetched
  ])

  return (
    (props.tab === 'drafts' || props.view === 'singleGame') ? (
      <Container singleGame={Boolean(props.view === 'singleGame')}>
        <BodyWrapper safari={isSafari}>
          <Body>
            {
              props.view === 'matchPage' && (
                <DraftsHeader>
                  <Link to={`${filterLinksString}/1/${mapSide}`}>
                    <TeamTab
                      active={teamInView === matchInfo.team1Id}
                    >
                      {isMobile ? matchInfo.team1Code : matchInfo.team1Name}
                    </TeamTab>
                  </Link>
                  <Link to={`${filterLinksString}/2/${mapSide}`}>
                    <TeamTab
                      active={teamInView === matchInfo.team2Id}
                    >
                      {isMobile ? matchInfo.team2Code : matchInfo.team2Name}
                    </TeamTab>
                  </Link>
                </DraftsHeader>
              )
            }
            {props.view !== 'singleGame' && (
              <FiltersContainer>
                <FiltersMapSideContainer>
                  <Link to={`${filterLinksString}${props.view === 'matchPage' ? `/${props.teamToView}` : ''}/all`}>
                    <MapSideTab
                      active={mapSide === 'all'}
                    >
                      All
                    </MapSideTab>
                  </Link>
                  <Link to={`${filterLinksString}${props.view === 'matchPage' ? `/${props.teamToView}` : ''}/blue`}>
                    <MapSideTab
                      active={mapSide === 'blue'}
                    >
                      Blue
                    </MapSideTab>
                  </Link>
                  <Link to={`${filterLinksString}${props.view === 'matchPage' ? `/${props.teamToView}` : ''}/red`}>
                    <MapSideTab
                      active={mapSide === 'red'}
                    >
                      Red
                    </MapSideTab>
                  </Link>
                </FiltersMapSideContainer>
                <FiltersShowHideContainer
                  active={!showRoles}
                >
                  <ShowHideOption
                    onClick={() => setShowRoles(true)}
                    active={showRoles}
                  >
                    Show Roles
                  </ShowHideOption>
                  <ShowHideOption
                    onClick={() => setShowRoles(false)}
                    active={!showRoles}
                  >
                    Hide Roles
                  </ShowHideOption>
                </FiltersShowHideContainer>
                <FiltersShowHideContainer
                  active={!showRoles}
                >
                  <ShowHideOption
                    onClick={() => setShowBlinds(true)}
                    active={showBlinds}
                  >
                    Show Blinds
                  </ShowHideOption>
                  <ShowHideOption
                    onClick={() => setShowBlinds(false)}
                    active={!showBlinds}
                  >
                    Hide Blinds
                  </ShowHideOption>
                </FiltersShowHideContainer>
              </FiltersContainer>
            )}
            {(draftsAreFetched && !draftsAreFetching) ? (
              <DraftsContentContainer>
                <DraftListContainer>
                  {(draftsInView.length > 0) ? (
                    draftsInView.map((draft, rowIndex) => {
                      return (
                        <React.Fragment key={`${draft.gameId}-row`}>
                          <DraftRow
                            key={`${draft.gameId}-draft`}
                            draftData={draft}
                            rowIndex={rowIndex}
                            showRoles={showRoles}
                            showBlinds={showBlinds}
                            singleGame={Boolean(props.view === 'singleGame')}
                          />
                        </React.Fragment>
                      )
                    })
                  ) : (
                    <NoDrafts>
                      No drafts available
                    </NoDrafts>
                  )}
                </DraftListContainer>
              </DraftsContentContainer>
            ) : (
              <SpinnerComponent />
            )}
          </Body>
        </BodyWrapper>
      </Container>
    ) : (
      null
    )
  )
}

export default inject("AppStore")(observer(Drafts));