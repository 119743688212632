import { observable, action } from 'mobx';
 
class SessionStore {
  authStatusHasBeenChecked = observable.box(false);
  authUser = observable.box(null);
  authToken = observable.box(null);
 
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
 
  setAuthUser = action((authUser) => {
    this.authUser.set(authUser);
  });

  setAuthToken = action((authToken) => {
    this.authToken.set(authToken);
  });
}
 
export default SessionStore;