import React from 'react';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${props => props.height ? props.height : 'auto'};
  justify-content: ${props => props.justify ? props.justify : 'center'};
  align-items: ${props => props.align ? props.align : 'center'};
`

/* BOOTSTRAP SPINNER
    <SpinnerContainer>
      <Spinner
        animation="grow"
        role="status"
        style={{
          height: props.size ? props.size : '100px',
          width: props.size ? props.size : '100px'}}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </SpinnerContainer>
*/

const SpinnerComponent = (props) => {
  var className = "lds-dual-ring";
  if (props.size) {
    className = className + "-" + props.size;
  }
  return (
    <SpinnerContainer
      className={className}
      height={props.height}
      justify={props.justify}
      align={props.align}
    />
  )
}

export default SpinnerComponent;
