import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import Moment from "react-moment";

import SpinnerComponent from "../Shared/Spinners";

import LeftSidebar from "../Sidebars/LeftSidebar";
import StatsNavigation from "./StatsNavigation";
import Filters from "./Filters";
import ColumnHeader from "./ColumnHeader";
import StatsRow from "./StatsRow";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

import { parseAsync } from "json2csv";
import { trackTableDownload } from "../../services/eventTracking";

import { cleanCategoryName } from "../../helpers";

const StatsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  max-width: 70em;
`;

const StatsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  margin-bottom: 0.1em;
  font-size: 1.2em;
  font-weight: bold;

  @media (max-width: 768px) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0;
  font-size: 1em;
  }
`;

const StatsTitleCategory = styled.div`
  @media (max-width: 768px) {
  font-size: 0.9em;
  }
`;
const StatsTitleDivider = styled.div`
  font-size: 0.8em;
  margin: 0 0.2em;
  padding-top: 0.2em;
`;
const StatsTitleTournament = styled.div``;

const SubNavAndDownloadContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
`;

const DownloadButtonContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 1.4em;
`;
const DownloadButton = styled.a`
  font-size: 0.6em;
  cursor: pointer;

  &:not([href]) {
  color: #d86b22;
  }

  &:not([href]):active,
  &:not([href]):hover,
  &:not([href]):visited,
  &:not([href]):focus {
  color: #d86b22;
  opacity: 0.8;
  }

  @media (max-width: 768px) {
  font-size: 0.8em;
  font-weight: normal;
  }
`;

const TournamentSubNav = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  font-size: 0.5em;

  @media (max-width: 768px) {
  flex-direction: row;
  font-size: 0.8em;
  margin-left: 0;
  }
`;

const TournamentSubNavItem = styled.div`
  flex: 1;
  margin: -0.15em 0;
  font-weight: normal;

  @media (max-width: 768px) {
  margin: 0 1em 0 0;
  }
`;

const StatsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
  min-height: 10em;
`;

const StatsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 62px;
  z-index: 1;
  overflow-x: hidden;
  min-height: 1.3em;
  margin-top: 0.5em;
  margin-right: ${(props) =>
    props.safari === true
      ? "0"
      : "17px"}; // To sync with scrollbar width of table below
  font-size: .65em;

  @media (max-width: 768px) {
    overflow-y: scroll;
    margin-right: 0;
  }
`;

const StatsContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  max-height: ${(props) => (props.safari === true ? "none" : "30em")};

  @media (max-width: 768px) {
    max-height: 45em;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    max-height: none;
  }

  /*
  @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      max-height: none;
    }
  }
  */
`;

const StatsContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LastUpdatedContainer = styled.div`
  margin-top: 0.5em;
  font-size: 0.7em;
  font-style: italic;
`;

const Explanation = styled.div`
  margin-top: 1em;
  font-size: .65em;
`

const EmptyMessage = styled.div`
  flex: 1;
  text-align: center;
  padding-top: 3em;
`;

const LastUpdated = (props) => {
  return (
    <LastUpdatedContainer>
      Most recent game: <Moment date={props.date} format="MMM D, YYYY h:mm A" />
    </LastUpdatedContainer>
  );
};

const Stats = (props) => {
  const isSafari =
    navigator.userAgent.indexOf("Safari") > -1 &&
    navigator.userAgent.indexOf("Chrome") === -1;

  const oldUrlStructure = props.match.params.oldUrlStructureSlug;
  const oldUrlStructureCategory =
    oldUrlStructure &&
    (oldUrlStructure.search("player") !== -1
      ? "players"
      : oldUrlStructure.search("team") !== -1
        ? "teams"
        : "unknown");

  const route = "stats";
  const view = oldUrlStructure ? "byTournament" : props.match.params.view;
  const cleanedCategoryName =
    props.AppStore.statsCategoryInView.get() &&
    cleanCategoryName(props.AppStore.statsCategoryInView.get());

  const isViewingATournament =
    view === "byTournament" && props.match.params.tournament;
  const isViewingExplorer = view === "explorer";
  const isViewingSoloQueueMatchups = view === "soloQueueMatchups";
  var segment = "all";
  if (isViewingATournament) {
    segment = decodeURIComponent(props.match.params.tournament);
  }

  const didRouteChange = route !== props.AppStore.currentRoute.get();
  const didViewChange = view !== props.AppStore.statsCurrentView.get();
  const didCategoryChange =
    props.match.params.category !== props.AppStore.statsCategoryInView.get();
  const didTournamentChange =
    isViewingATournament &&
    decodeURIComponent(props.match.params.tournament) !==
    props.AppStore.tournamentIdInView.get();
  const didQueryFiltersChange = props.AppStore.statsQueryFiltersChanged.get();
  const didSoloQueueQueryFiltersChange = props.AppStore.soloQueueQueryFiltersChanged.get();

  const isPatchRangeFetched =
    (isViewingATournament &&
      props.AppStore.statsFiltersPatchRanges[
      decodeURIComponent(props.match.params.tournament)
      ]) ||
    (isViewingExplorer && props.AppStore.statsFiltersPatchRanges.all) ||
    (isViewingSoloQueueMatchups && props.AppStore.soloQueueStatsFiltersPatchRange);
  const isPatchRangeFetching = props.AppStore.statsFiltersPatchRangeFetching.get();
  const isSoloQueuePatchRangeFetching = props.AppStore.soloQueueStatsFiltersPatchRangeFetching.get();
  const isSoloQueuePatchRangeFetched = props.AppStore.soloQueueStatsFiltersPatchRangeFetched.get();
  const isSoloQueueChampionListFetching = props.AppStore.isSoloQueueChampionListFetching.get();
  const isSoloQueueChampionListFetched = props.AppStore.isSoloQueueChampionListFetched.get();

  const explorerDefaultsAreReady = view === "explorer" && props.AppStore.explorerDefaultLeaguesAreReady.get() && props.AppStore.explorerPatchRangeIsReady.get();
  const explorerInitialStatsAreFetched = props.AppStore.explorerInitialStatsAreFetched.get();

  const doStatsInViewNeedUpdating =
    !props.AppStore.statsAreFetching.get()
    && (
      (isViewingExplorer && explorerDefaultsAreReady) || isViewingATournament
    )
    && isPatchRangeFetched
    && (
      (didRouteChange || didViewChange || didCategoryChange || didTournamentChange || didQueryFiltersChange)
      ||
      (isViewingExplorer && !explorerInitialStatsAreFetched)
    );

  const soloQueuePosition = props.AppStore.soloQueueStatsFiltersPosition.get();
  const soloQueueChampions = props.AppStore.soloQueueStatsFiltersChampions;
  const soloQueuePatch = props.AppStore.soloQueueStatsFiltersPatch.get();

  const doSoloQueueMatchupStatsNeedUpdating =
    !props.AppStore.statsAreFetching.get() &&
    isViewingSoloQueueMatchups &&
    soloQueuePosition &&
    soloQueueChampions.length > 0 &&
    soloQueuePatch &&
    didSoloQueueQueryFiltersChange;

  const statsDefinitionsAreFetched = props.AppStore.statsDefinitionsAreFetched.get();
  const statsDefinitionsAreFetching = props.AppStore.statsDefinitionsAreFetching.get();

  const leaguesAreFetched = props.AppStore.leaguesAreFetched.get();
  const regionsAreFetched = props.AppStore.regionsAreFetched.get();
  const tournamentsByLeagueAreFetched = props.AppStore.tournamentsByLeagueAreFetched.get();
  const latestTournamentsAreFetched = props.AppStore.latestTournamentsAreFetched.get();
  const latestTournamentsByLeagueAreFetched = props.AppStore.latestTournamentsByLeagueAreFetched.get();

  useEffect(() => {
    if (didRouteChange) {
      props.AppStore.setCurrentRoute(route);
    }

    if (!leaguesAreFetched && !props.AppStore.leaguesAreFetching.get()) {
      props.AppStore.setLeagues();
    }
    if (!regionsAreFetched && !props.AppStore.regionsAreFetching.get()) {
      props.AppStore.setLeagueRegions();
    }
    if (
      !tournamentsByLeagueAreFetched &&
      !props.AppStore.tournamentsByLeagueAreFetching.get()
    ) {
      props.AppStore.setTournamentsByLeague();
    }
    if (
      !latestTournamentsAreFetched &&
      !props.AppStore.latestTournamentsAreFetching.get()
    ) {
      props.AppStore.setListOfLatestTournaments();
    }
    if (
      !latestTournamentsByLeagueAreFetched &&
      !props.AppStore.latestTournamentsByLeagueAreFetching.get()
    ) {
      props.AppStore.setLatestTournamentsByLeague();
    }
    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      props.AppStore.getStatsDefinitions();
    }

    if (didViewChange || (didRouteChange && !didViewChange)) {
      props.AppStore.setStatsCurrentView(view);
      props.AppStore.explorerInitialStatsAreFetched.set(false);
    }

    if (didCategoryChange) {
      props.AppStore.setStatsCategoryInView(
        oldUrlStructureCategory && oldUrlStructureCategory !== "unknown"
          ? oldUrlStructureCategory
          : props.match.params.category
      );
    }

    if (isViewingATournament && didTournamentChange) {
      props.AppStore.setTournamentIdInView(segment);
      props.AppStore.setTournamentMetadataInView(
        decodeURIComponent(props.match.params.tournament)
      );
    }

    if (
      didRouteChange ||
      didViewChange ||
      didCategoryChange ||
      didTournamentChange ||
      (view === "byTournament" && !isViewingATournament)
    ) {
      props.AppStore.resetBasicFilters();
      props.AppStore.resetQueryFilters();
      props.AppStore.resetSoloQueueQueryFilters();
      props.AppStore.resetSort();
    }

    if (!isPatchRangeFetched && !isPatchRangeFetching) {
      if (isViewingATournament) props.AppStore.setPatchRange({tournament: segment});
      if (isViewingExplorer) props.AppStore.setPatchRange({});
    }

    if (isViewingSoloQueueMatchups && !isSoloQueuePatchRangeFetching && !isSoloQueuePatchRangeFetched) {
      props.AppStore.setSoloQueuePatchRange();
    }

    if (
      isViewingSoloQueueMatchups &&
      isSoloQueuePatchRangeFetched &&
      !(
        props.AppStore.soloQueueStatsFiltersPatch.get() in
        props.AppStore.soloQueueChampions
      )
    ) {
      props.AppStore.setSoloQueueChampionsForPatch(
        props.AppStore.soloQueueStatsFiltersPatch.get()
      );
    }

    var filters = {}
    if (doStatsInViewNeedUpdating) {
      filters.mapSide = props.AppStore.statsFiltersMapSide.get();
      filters.winLoss = props.AppStore.statsFiltersWinLoss.get();
      filters.dateStart = props.AppStore.statsFiltersDateStart.get()
        ? props.AppStore.statsFiltersDateStart.get().format('YYYY-MM-DD')
        : null;
      filters.dateEnd = props.AppStore.statsFiltersDateEnd.get()
        ? props.AppStore.statsFiltersDateEnd.get().format('YYYY-MM-DD')
        : null;

      if (view === "byTournament") {
        if (props.AppStore.patchStartFilterActivated.get() || props.AppStore.patchStartFilterActivated.get()) {
          filters.patchStart = props.AppStore.statsFiltersPatchStart.get()
            ? props.AppStore.statsFiltersPatchStart.get()
            : props.AppStore.statsFiltersPatchRanges[segment].length > 0
              ? props.AppStore.statsFiltersPatchRanges[segment][
                props.AppStore.statsFiltersPatchRanges[segment].length - 1
              ].patch
              : null;
          filters.patchEnd = props.AppStore.statsFiltersPatchEnd.get()
            ? props.AppStore.statsFiltersPatchEnd.get()
            : props.AppStore.statsFiltersPatchRanges[segment].length > 0
              ? props.AppStore.statsFiltersPatchRanges[segment][0].patch
              : null;
        }
      }
      if (view === "explorer") {
        filters.leagues = props.AppStore.statsFiltersSelectedLeagues.map(
          (league) => {
            return league.id;
          }
        );
        filters.patchStart = props.AppStore.statsFiltersPatchStart.get()
          ? props.AppStore.statsFiltersPatchStart.get()
          : props.AppStore.statsFiltersPatchRanges[segment][0].patch;
        filters.patchEnd = props.AppStore.statsFiltersPatchEnd.get()
          ? props.AppStore.statsFiltersPatchEnd.get()
          : props.AppStore.statsFiltersPatchRanges[segment][0].patch;
      }

      props.AppStore.setStatsInView(
        view,
        props.AppStore.statsCategoryInView.get(),
        isViewingATournament ? props.AppStore.tournamentIdInView.get() : null,
        isViewingATournament &&
            (props.AppStore.tournamentIdInView.get().slice(0, 3) === "LPL" ||
                props.AppStore.tournamentIdInView.get().slice(0, 3) === "LDL" ||
                props.AppStore.tournamentIdInView.get().slice(0, 4) === "LSPL" ||
                props.AppStore.tournamentIdInView.get().slice(0, 7) === "Demacia")
            ? "china"
            : null,
        null,
        null,
        null,
        filters
      );
    }

    if (isViewingSoloQueueMatchups && doSoloQueueMatchupStatsNeedUpdating) {
      filters = {
        position: soloQueuePosition,
        champions: soloQueueChampions.map(champion => {
          return encodeURIComponent(champion.id)
        }),
        patch: soloQueuePatch
      };
      props.AppStore.setStatsInView(
        view,
        props.AppStore.statsCategoryInView.get(),
        isViewingATournament ? props.AppStore.tournamentIdInView.get() : null,
        isViewingATournament &&
          (props.AppStore.tournamentIdInView.get().slice(0, 3) === "LPL" ||
            props.AppStore.tournamentIdInView.get().slice(0, 3) === "LDL" ||
            props.AppStore.tournamentIdInView.get().slice(0, 4) === "LSPL" ||
            props.AppStore.tournamentIdInView.get().slice(0, 7) === "Demacia")
          ? "china"
          : null,
        null,
        null,
        null,
        filters
      );
    }
  }, [
    view,
    props.AppStore,
    props.match.params.category,
    props.match.params.tournament,
    isViewingATournament,
    isViewingExplorer,
    isViewingSoloQueueMatchups,
    segment,
    leaguesAreFetched,
    regionsAreFetched,
    latestTournamentsAreFetched,
    tournamentsByLeagueAreFetched,
    latestTournamentsByLeagueAreFetched,
    statsDefinitionsAreFetched,
    statsDefinitionsAreFetching,
    didRouteChange,
    didViewChange,
    didCategoryChange,
    didTournamentChange,
    didQueryFiltersChange,
    doStatsInViewNeedUpdating,
    isPatchRangeFetched,
    isPatchRangeFetching,
    isSoloQueuePatchRangeFetched,
    isSoloQueuePatchRangeFetching,
    soloQueuePatch,
    isSoloQueueChampionListFetching,
    isSoloQueueChampionListFetched,
    soloQueueChampions,
    soloQueuePosition,
    didSoloQueueQueryFiltersChange,
    doSoloQueueMatchupStatsNeedUpdating,
    oldUrlStructureCategory,
  ]);

  const download = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const downloadTable = async (e) => {
    e.preventDefault();
    const fields = props.AppStore.statsColumnsInView;
    const opts = { fields };

    trackTableDownload({
      category: cleanedCategoryName,
      tournament: props.AppStore.tournamentNameInView.get(),
    });
    const csv = await parseAsync(props.AppStore.filteredStatsInView, opts);
    const fileNameFirstPart = isViewingATournament ? `${props.AppStore.tournamentNameInView.get()} - ` : ``;
    download(
      `${fileNameFirstPart}${cleanedCategoryName} Stats - OraclesElixir.csv`,
      csv
    );
  };

  if (view === "byTournament" && !props.match.params.tournament) {
    const pageTitle = `${cleanedCategoryName} Stats - Oracle's Elixir`;
    const pageDescription = `${cleanedCategoryName} stats for League of Legends from Oracle's Elixir, the premier source for LoL esports statistics.`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@TimSevenhuysen" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <LeftSidebar />
        <StatsWrapper className="mainContent">
          {props.AppStore.statsCategoryInView.get() && (
            <StatsTitleContainer>
              <StatsTitleCategory>
                {`${cleanedCategoryName} Stats`}
              </StatsTitleCategory>
            </StatsTitleContainer>
          )}
          <StatsNavigation
            category={props.AppStore.statsCategoryInView.get()}
          />
        </StatsWrapper>
      </>
    );
  }

  if (view === "byTournament" && props.match.params.tournament) {
    const pageTitle = `${props.AppStore.tournamentNameInView.get()
        ? props.AppStore.tournamentNameInView.get() + " "
        : ""
      }${cleanedCategoryName} Stats - Oracle's Elixir`;
    const pageDescription = `${props.AppStore.tournamentNameInView.get()
        ? props.AppStore.tournamentNameInView.get() + " "
        : ""
      } ${cleanedCategoryName} stats for League of Legends from Oracle's Elixir, the premier source for LoL esports statistics.`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@TimSevenhuysen" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <LeftSidebar />
        <StatsWrapper className="mainContent">
          <StatsTitleContainer>
            <StatsTitleCategory>
              <Link
                to={`/stats/${props.AppStore.statsCategoryInView.get()}/byTournament`}
              >
                {props.AppStore.statsCategoryInView.get() &&
                  `${cleanedCategoryName} Stats`}
              </Link>
            </StatsTitleCategory>
            <StatsTitleDivider className="hideOnMobile">
              {">"}
            </StatsTitleDivider>
            {props.AppStore.tournamentNameInView.get() &&
              !props.AppStore.tournamentMetadataIsFetching.get() && (
                <>
                  <StatsTitleTournament>
                    {props.AppStore.tournamentNameInView.get()}
                  </StatsTitleTournament>
                  <SubNavAndDownloadContainer>
                    <TournamentSubNav>
                      {props.AppStore.statsCategoryInView.get() !==
                        "players" && (
                          <TournamentSubNavItem>
                            <Link
                              to={`/stats/players/byTournament/${props.match.params.tournament}`}
                            >
                              Players
                            </Link>
                          </TournamentSubNavItem>
                        )}
                      {props.AppStore.statsCategoryInView.get() !== "teams" && (
                        <TournamentSubNavItem>
                          <Link
                            to={`/stats/teams/byTournament/${props.match.params.tournament}`}
                          >
                            Teams
                          </Link>
                        </TournamentSubNavItem>
                      )}
                      {props.AppStore.statsCategoryInView.get() !==
                        "champions" && (
                          <TournamentSubNavItem>
                            <Link
                              to={`/stats/champions/byTournament/${props.match.params.tournament}`}
                            >
                              Champions
                            </Link>
                          </TournamentSubNavItem>
                        )}
                    </TournamentSubNav>
                    <DownloadButtonContainer>
                      <DownloadButton onClick={downloadTable}>
                        Download This Table
                      </DownloadButton>
                    </DownloadButtonContainer>
                  </SubNavAndDownloadContainer>
                </>
              )}
          </StatsTitleContainer>
          {isPatchRangeFetched && <Filters segment={segment} />}
          {props.AppStore.statsInView && (
            <ScrollSync>
              <StatsTableContainer>
                {props.AppStore.statsAreFetching.get() ||
                  !isPatchRangeFetched ? (
                    <SpinnerComponent />
                  ) : props.AppStore.statsColumnsInView.length === 0 ? (
                    <StatsContentContainer>
                      <EmptyMessage>
                        No stats available meeting these criteria -- try removing
                        some filters
                      </EmptyMessage>
                    </StatsContentContainer>
                  ) : (
                      <>
                        <ScrollSyncPane>
                          <StatsHeader safari={isSafari}>
                            {props.AppStore.statsColumnsInView.map(
                              (stat, index) => {
                                return (
                                  <ColumnHeader
                                    key={`${stat}`}
                                    index={index}
                                    label={`${stat}`}
                                    category={props.AppStore.statsCategoryInView.get()}
                                  />
                                );
                              }
                            )}
                          </StatsHeader>
                        </ScrollSyncPane>
                        <ScrollSyncPane>
                          <StatsContentContainer safari={isSafari}>
                            <StatsContent>
                              {props.AppStore.filteredStatsInView.map(
                                (row, index) => {
                                  return (
                                    <StatsRow
                                      key={index}
                                      stats={row}
                                      index={index}
                                      category={props.AppStore.statsCategoryInView.get()}
                                    />
                                  );
                                }
                              )}
                            </StatsContent>
                          </StatsContentContainer>
                        </ScrollSyncPane>
                      </>
                    )}
                {!props.AppStore.statsAreFetching.get() &&
                  props.AppStore.tournamentLatestGameMap[
                  props.AppStore.tournamentIdInView.get()
                  ] && (
                    <LastUpdated
                      date={
                        props.AppStore.tournamentLatestGameMap[
                        props.AppStore.tournamentIdInView.get()
                        ]
                      }
                    />
                  )}
              </StatsTableContainer>
            </ScrollSync>
          )}
        </StatsWrapper>
      </>
    );
  }

  if (view === "explorer" && props.match.params.category !== "champions") {
    return (
      <Redirect to={`/404?stats/${props.match.params.category}/${view}`} />
    );
  }

  if (view === "explorer" && props.match.params.category === "champions") {
    const pageTitle = `${cleanedCategoryName} Stats Explorer - Oracle's Elixir`;
    const pageDescription = `Oracle's Elixir ${cleanedCategoryName} stats explorer for League of Legends from Oracle's Elixir, the premier source for LoL esports statistics.`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@TimSevenhuysen" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <LeftSidebar />
        <StatsWrapper className="mainContent">
          <StatsTitleContainer>
            <StatsTitleCategory>
              {props.AppStore.statsCategoryInView.get() &&
                `${cleanedCategoryName} Stats Explorer`}
            </StatsTitleCategory>
            <DownloadButtonContainer>
              <DownloadButton onClick={downloadTable}>
                Download This Table
              </DownloadButton>
            </DownloadButtonContainer>
          </StatsTitleContainer>
          {isPatchRangeFetched && <Filters segment={segment} />}
          {props.AppStore.statsInView && (
            <ScrollSync>
              <StatsTableContainer>
                {props.AppStore.statsAreFetching.get() ||
                  !isPatchRangeFetched ? (
                    <SpinnerComponent />
                  ) : props.AppStore.statsColumnsInView.length === 0 ? (
                    <StatsContentContainer>
                      <EmptyMessage>
                        If you selected a large range of patches or dates, the server may be timing out. You could try a narrower search.
                        <br /><br />
                        It's also possible that the selected leagues have not played on this patch yet, or that there are no
                        games meeting the other filters. You could try selecting a previous patch or loosening
                        some of the other filters.
                      </EmptyMessage>
                    </StatsContentContainer>
                  ) : (
                      <>
                        <ScrollSyncPane>
                          <StatsHeader safari={isSafari}>
                            {props.AppStore.statsColumnsInView.map(
                              (stat, index) => {
                                return (
                                  <ColumnHeader
                                    key={`${stat}`}
                                    index={index}
                                    label={`${stat}`}
                                    category={props.AppStore.statsCategoryInView.get()}
                                  />
                                );
                              }
                            )}
                          </StatsHeader>
                        </ScrollSyncPane>
                        <ScrollSyncPane>
                          <StatsContentContainer safari={isSafari}>
                            <StatsContent>
                              {props.AppStore.filteredStatsInView.map(
                                (row, index) => {
                                  return (
                                    <StatsRow
                                      key={index}
                                      stats={row}
                                      index={index}
                                      category={props.AppStore.statsCategoryInView.get()}
                                    />
                                  );
                                }
                              )}
                            </StatsContent>
                          </StatsContentContainer>
                        </ScrollSyncPane>
                      </>
                    )}
              </StatsTableContainer>
            </ScrollSync>
          )}
        </StatsWrapper>
      </>
    );
  }

  if (isViewingSoloQueueMatchups) {
    const pageTitle = `Solo Queue Matchup Stats BETA - Oracle's Elixir`;
    const pageDescription = `Oracle's Elixir solo queue matchup stats for League of Legends from Oracle's Elixir, the premier source for LoL esports statistics.`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@TimSevenhuysen" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
        </Helmet>
        <LeftSidebar />
        <StatsWrapper className="mainContent">
          <StatsTitleContainer>
            <StatsTitleCategory>
              Solo Queue Stats - BETA
            </StatsTitleCategory>
            <DownloadButtonContainer>
              <DownloadButton onClick={downloadTable}>
                Download This Table
              </DownloadButton>
            </DownloadButtonContainer>
          </StatsTitleContainer>
          {isSoloQueuePatchRangeFetched && isSoloQueueChampionListFetched && <Filters segment={segment} />}
          {props.AppStore.statsInView && (
            <ScrollSync>
              <StatsTableContainer>
                {props.AppStore.statsAreFetching.get() ||
                  !isPatchRangeFetched ? (
                    <SpinnerComponent />
                  ) : (soloQueueChampions.length < 1 || !soloQueuePosition) ? (
                    <StatsContentContainer>
                      <EmptyMessage>
                        <p>Select a position and up to 3 champions at a time</p>
                      </EmptyMessage>
                    </StatsContentContainer>
                  ) : props.AppStore.statsColumnsInView.length === 0 ? (
                    <StatsContentContainer>
                      <EmptyMessage>
                        <p>No matchups available for these selections -- try choosing a different position or champion</p>
                        <p>Select up to 3 champions at a time</p>
                      </EmptyMessage>
                    </StatsContentContainer>
                  ) : (
                        <>
                          <ScrollSyncPane>
                            <StatsHeader safari={isSafari}>
                              {props.AppStore.statsColumnsInView.map(
                                (stat, index) => {
                                  return (
                                    <ColumnHeader
                                      key={`${stat}`}
                                      index={index}
                                      label={`${stat}`}
                                      category={props.AppStore.statsCategoryInView.get()}
                                      view={view}
                                    />
                                  );
                                }
                              )}
                            </StatsHeader>
                          </ScrollSyncPane>
                          <ScrollSyncPane>
                            <StatsContentContainer safari={isSafari}>
                              <StatsContent>
                                {props.AppStore.filteredStatsInView.map(
                                  (row, index) => {
                                    return (
                                      <StatsRow
                                        key={index}
                                        stats={row}
                                        index={index}
                                        category={props.AppStore.statsCategoryInView.get()}
                                        view={view}
                                      />
                                    );
                                  }
                                )}
                              </StatsContent>
                            </StatsContentContainer>
                          </ScrollSyncPane>
                        </>
                      )}
              </StatsTableContainer>
            </ScrollSync>
          )}
          <Explanation>
            Solo queue matchup stats are based on global Diamond 1+ games. Stats are shown for matchups played at least 30 times on the selected patch.
          </Explanation>
        </StatsWrapper>
      </>
    );
  }
};

export default inject("AppStore")(observer(Stats));
