import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { Multiselect } from "multiselect-react-dropdown";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import { trackStatsFilter } from "../../services/eventTracking";

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 20em;
  border: 1px dashed #aaa;
  font-size: 0.7em;

  & .chip {
    border-radius: 1px;
    padding: 2px;
    max-width: 100%;
  }

  & .searchBox {
    color: #999;
  }

  & .icon_cancel {
    margin-left: 1px;
  }

  > div > div > div {
    border-radius: 2px;
  }
`;

const FiltersLeagueContainer = styled.div`
  flex: 1;
  max-width: 10em;
  margin: 5px;
`;

const FiltersPositionContainer = styled.div`
  flex: 1;
  max-width: ${(props) => (props.soloQueue ? "10em" : "8em")};
  margin: 5px;

  > div > div {
    ${(props) => (props.activated ? "padding: 0" : "")}
  }

  > div > div > span {
    margin-bottom: 0;
    padding: 9px 7px 6px 7px;
    font-size: 1em;
  }

  > div > div > input {
    display: ${(props) => (props.activated ? "none" : "inherit")};
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;

const FiltersPlayerContainer = styled.div`
  flex: 1;
  max-width: 12.5em;
  margin: 5px;
`;

const FiltersTeamContainer = styled.div`
  flex: 1;
  max-width: 15em;
  margin: 5px;
`;

const FiltersGPContainer = styled.div`
  margin: 5px;
`;

const FiltersGPInput = styled.input`
  max-width: 6em;
  min-height: 22px;
  padding: 9px 7px 6px 7px;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #777;
  background: transparent;

  &:focus {
    outline: none;
  }
`;

const FiltersMapSideContainer = styled.div`
  position: relative;
  width: 8em;
  margin: 5px;
  color: #777;

  > div > div {
    padding: 0;
  }

  > div > div > span {
    min-height: 22px;
    color: #777;
    margin-bottom: 0;
    font-size: 1em;
  }

  > div > div > input {
    display: none;
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;

const FiltersWinLossContainer = styled.div`
  position: relative;
  width: 8em;
  margin: 5px;
  color: #777;

  > div > div {
    padding: 0;
  }

  > div > div > span {
    min-height: 22px;
    color: #777;
    margin-bottom: 0;
    font-size: 1em;
  }

  > div > div > input {
    display: none;
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;

const FiltersPatchContainer = styled.div`
  flex: 1;
  max-width: 14em;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  color: #777;

  & .toPatch {
    margin: auto 0.5em;
    font-style: italic;
  }
`;

const FiltersPatchStartContainer = styled.div`
  flex: 1;
  width: 5.5em;

  > div > div {
    ${(props) => (props.activated ? "padding: 0" : "")}
  }

  > div > div > span {
    margin-bottom: 0;
    padding: 9px 7px 6px 7px;
    font-size: 1em;
  }

  > div > div > input {
    display: ${(props) => (props.activated ? "none" : "inherit")};
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;

const FiltersPatchEndContainer = styled.div`
  flex: 1;
  width: 5.5em;

  > div > div {
    ${(props) => (props.activated ? "padding: 0" : "")}
  }

  > div > div > span {
    margin-bottom: 0;
    padding: 9px 7px 6px 7px;
    font-size: 1em;
  }

  > div > div > input {
    display: ${(props) => (props.activated ? "none" : "inherit")};
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;

/*
const FiltersChampionContainer = styled.div`
  flex: 1;
  max-width: 14em;
  margin: 5px;

  > div > div {
    ${(props) => (props.activated ? "padding: 0" : "")}
  }

  > div > div > span {
    margin-bottom: 0;
    padding: 9px 7px 6px 7px;
    font-size: 1em;
  }

  > div > div > input {
    display: ${(props) => (props.activated ? "none" : "inherit")};
  }

  & .chip {
    padding: 9px 7px 6px 7px;
  }
`;
*/
const FiltersChampionContainer = styled.div`
  flex: 1;
  max-width: 12.5em;
  margin: 5px;
`;


const FiltersDateRangeContainer = styled.div`
  color: #777;
  font-size: 0.75em;
  border: 1px solid #cccccc;
  border-radius: 2px;
  max-height: 37px;
  margin: 5px;
  padding: 9px 7px 6px 7px;
`;

// Change the defaultLeagues to be set based on a URI param
const defaultLeagues = [
{ name: "LCK", id: "LoL Champions Korea" },
{ name: "LCS", id: "League of Legends Championship Series" },
{ name: "LEC", id: "LoL European Championship" },
{ name: "LPL", id: "Tencent LoL Pro League" },
];



const Filters = (props) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;
  const [state, setState] = useState({ focusedInput: null });
  const view = props.match.params.view || props.match.params.tab;
  const category = props.match.params.category;
  const isViewingATournament =
    view === "byTournament" && props.match.params.tournament;
  const tournament =
    isViewingATournament && decodeURIComponent(props.match.params.tournament);
  const isViewingChampionsExplorer = view === "explorer" && category === "champions";
  const isViewingSoloQueueMatchups = view === "soloQueueMatchups";

  const didViewChange = props.match.params.view && (view !== props.AppStore.statsCurrentView.get());
  const didCategoryChange = props.match.params.view && category !== props.AppStore.statsCategoryInView.get();
  const didTournamentChange =
    isViewingATournament &&
    tournament !== props.AppStore.tournamentIdInView.get();

  const filterPositionOptions = [
    { position: "Top", id: "Top" },
    { position: "Jungle", id: "Jungle" },
    { position: "Middle", id: "Middle" },
    { position: "ADC", id: "ADC" },
    { position: "Support", id: "Support" },
  ];

  const leagueOptions = props.AppStore.statsFiltersLeagueOptions;

  const filterMapSideOptions = [
    { value: "all", id: "Both Sides" },
    { value: "100", id: "Blue" },
    { value: "200", id: "Red" },
  ];

  const filterWinLossOptions = [
    { value: "all", id: "All Results" },
    { value: "1", id: "Wins" },
    { value: "0", id: "Losses" },
  ];

  const leaguesRef = useRef(null);
  const teamRef = useRef(null);
  const playerRef = useRef(null);
  const positionRef = useRef(null);
  const gamesPlayedRef = useRef(null);
  const mapSideRef = useRef(null);
  const winLossRef = useRef(null);
  const fromPatchRef = useRef(null);
  const toPatchRef = useRef(null);
  const soloQueuePatchRef = useRef(null);
  const soloQueueChampionRef = useRef(null);

  const patchRange = isViewingSoloQueueMatchups
    ? props.AppStore.soloQueueStatsFiltersPatchRange
    : props.AppStore.statsFiltersPatchRanges[props.segment];

  const firstPatch =
    patchRange &&
    patchRange.length > 0 &&
    patchRange[
      patchRange.length - 1
    ].patch;
  const lastPatch =
    patchRange &&
    patchRange.length > 0 &&
    patchRange[0].patch;

  const maxPatch = props.AppStore.statsFiltersPatchEnd.get()
    ? props.AppStore.statsFiltersPatchEnd.get()
    : lastPatch;
  const lowerPatchFilterOptions = patchRange
    .filter((patch) => {
      if (patch.patch && patch.patch <= maxPatch) return true;
      return false;
    })
    .map((patch) => {
      return { value: patch.patch, id: patch.patch.toFixed(2) };
    });

  var minPatch = null;
  if (view === "byTournament") {
    minPatch = props.AppStore.statsFiltersPatchStart.get()
      ? props.AppStore.statsFiltersPatchStart.get()
      : firstPatch;
  }
  if (view === "explorer") {
    minPatch = props.AppStore.statsFiltersPatchStart.get()
      ? props.AppStore.statsFiltersPatchStart.get()
      : lastPatch;
  }

  const higherPatchFilterOptions = patchRange
    .filter((patch) => {
      if (patch.patch && patch.patch >= minPatch) return true;
      return false;
    })
    .map((patch) => {
      return { value: patch.patch, id: patch.patch.toFixed(2) };
    });

  var defaultPatchStart = [
    { value: firstPatch, id: firstPatch && firstPatch.toFixed(2) },
  ];
  if (view === "explorer")
    defaultPatchStart = [
      { value: lastPatch, id: lastPatch && lastPatch.toFixed(2) },
    ];
  var defaultPatchEnd = [
    { value: lastPatch, id: lastPatch && lastPatch.toFixed(2) },
  ];

  const soloQueuePatchFilterOptions = patchRange
    .map((patch) => {
      return { value: patch.patch, id: `Patch ${patch.patch.toFixed(2)}` };
    });
  
  const championList =
    (props.AppStore.soloQueueStatsFiltersPatch.get() &&
    props.AppStore.soloQueueStatsFiltersPatch.get() in
      props.AppStore.soloQueueChampions)
      ? props.AppStore.soloQueueChampions[
          props.AppStore.soloQueueStatsFiltersPatch.get()
        ]
      : [];

  const soloQueueChampionFilterOptions = championList
    .map((champion) => {
      return { value: champion.name, id: champion.name };
    });

  const startDate = props.AppStore.statsFiltersDateStart.get();
  const endDate = props.AppStore.statsFiltersDateEnd.get();

  const statsQueryFiltersChanged = props.AppStore.statsQueryFiltersChanged.get();
  const soloQueueQueryFiltersChanged = props.AppStore.soloQueueQueryFiltersChanged.get();

  useEffect(() => {
    if (isViewingChampionsExplorer && !props.AppStore.leagueFilterActivated.get()) {
      props.AppStore.setLeagueFilter(defaultLeagues);
      props.AppStore.explorerDefaultLeaguesAreReady.set(true);
    }

    if (didViewChange || didCategoryChange || didTournamentChange) {
      !isViewingChampionsExplorer &&
        leaguesRef.current &&
        leaguesRef.current.onSelectItem(defaultLeagues);
      teamRef.current &&
        teamRef.current.hasOwnProperty("resetSelectedValues") &&
        teamRef.current.resetSelectedValues();
      playerRef.current &&
        playerRef.current.hasOwnProperty("resetSelectedValues") &&
        playerRef.current.resetSelectedValues();
      positionRef.current &&
        positionRef.current.hasOwnProperty("resetSelectedValues") &&
        positionRef.current.resetSelectedValues();
      gamesPlayedRef.current &&
        gamesPlayedRef.current.hasOwnProperty("resetSelectedValues") &&
        gamesPlayedRef.current.resetSelectedValues();
      !isViewingSoloQueueMatchups && mapSideRef.current.onSelectItem({ value: "all", id: "Both Sides" });
      !isViewingSoloQueueMatchups && winLossRef.current.onSelectItem({ value: "all", id: "All Results" });
      /* fromPatchRef.current.onSelectItem(defaultPatchStart[0]);
      toPatchRef.current.onSelectItem(defaultPatchEnd[0]); */
      fromPatchRef.current &&
        fromPatchRef.current.hasOwnProperty("resetSelectedValues") &&
        fromPatchRef.current.resetSelectedValues();
      toPatchRef.current &&
        toPatchRef.current.hasOwnProperty("resetSelectedValues") &&
        toPatchRef.current.resetSelectedValues();
    }

    props.AppStore.statsQueryFiltersChanged.set(false);
    props.AppStore.soloQueueQueryFiltersChanged.set(false);
  }, [
    props.AppStore,
    props.AppStore.statsFiltersSelectedLeagues,
    view,
    didViewChange,
    didCategoryChange,
    didTournamentChange,
    isViewingChampionsExplorer,
    isViewingSoloQueueMatchups,
    startDate,
    endDate,
    statsQueryFiltersChanged,
    soloQueueQueryFiltersChanged
  ]);

  const handleFilterSubmit = () => {
    var positions = [];
    for (let position of props.AppStore.statsFiltersSelectedPositions) {
      positions.push(position.id);
    }
    if (positions > 0) {
      props.AppStore.statsFiltersPositionPlaceholder.set("");
    } else {
      props.AppStore.statsFiltersPositionPlaceholder.set("Filter Positions");
    }

    var teams = [];
    for (let team of props.AppStore.statsFiltersSelectedTeams) {
      teams.push(team.id);
    }
    if (teams > 0) {
      props.AppStore.statsFiltersTeamPlaceholder.set("");
    } else {
      props.AppStore.statsFiltersTeamPlaceholder.set("Filter Teams");
    }

    var players = [];
    for (let player of props.AppStore.statsFiltersSelectedPlayers) {
      players.push(player.id);
    }
    if (players > 0) {
      props.AppStore.statsFiltersPlayerPlaceholder.set("");
    } else {
      props.AppStore.statsFiltersPlayerPlaceholder.set("Filter Players");
    }

    var minGP = parseInt(props.AppStore.statsFiltersMinGP.get());
    if (isNaN(minGP)) {
      minGP = 0;
    }

    props.AppStore.filterStatsTable({
      positions: positions,
      teams: teams,
      players: players,
      minGP: minGP,
    });
    props.AppStore.setStatsColumnsInView();
    props.AppStore.setTeamFilterOptions();
    props.AppStore.setPlayerFilterOptions();
  };

  const onPositionSelect = (selectedList, selectedItem) => {
    if (!isViewingSoloQueueMatchups) {
      props.AppStore.statsFiltersSelectedPositions = selectedList;
      handleFilterSubmit();
    }
    if (isViewingSoloQueueMatchups) {
      const POSITIONS = {
        Top: "top",
        Jungle: "jng",
        Middle: "mid",
        ADC: "bot",
        Support: "sup"
      }
      let position = POSITIONS[selectedList[0].id]
      props.AppStore.soloQueueStatsFiltersPosition.set(position);
      props.AppStore.soloQueuePositionFilterActivated.set(true);
      props.AppStore.soloQueueQueryFiltersChanged.set(true);
    }
    trackStatsFilter("selectPosition", selectedItem.position);
  };
  const onPositionRemove = (selectedList, removedItem) => {
    props.AppStore.statsFiltersSelectedPositions = selectedList;
    handleFilterSubmit();
    trackStatsFilter("removePosition", removedItem.position);
  };

  const onTeamSelect = (selectedList, selectedItem) => {
    props.AppStore.statsFiltersSelectedTeams = selectedList;
    handleFilterSubmit();
    trackStatsFilter("selectTeam", selectedItem.team);
  };
  const onTeamRemove = (selectedList, removedItem) => {
    props.AppStore.statsFiltersSelectedTeams = selectedList;
    handleFilterSubmit();
    trackStatsFilter("removeTeam", removedItem.team);
  };

  const onPlayerSelect = (selectedList, selectedItem) => {
    props.AppStore.statsFiltersSelectedPlayers = selectedList;
    handleFilterSubmit();
    trackStatsFilter("selectPlayer", selectedItem.player);
  };
  const onPlayerRemove = (selectedList, removedItem) => {
    props.AppStore.statsFiltersSelectedPlayers = selectedList;
    handleFilterSubmit();
    trackStatsFilter("removePlayer", removedItem.player);
  };

  const onFocusMinGP = () => {
    if (props.AppStore.statsFiltersMinGP.get() === "Min. GP") {
      props.AppStore.statsFiltersMinGP.set("");
    } else if (props.AppStore.statsFiltersMinGP.get() === "") {
      props.AppStore.statsFiltersMinGP.set("Min. GP");
    }
  };

  const onChangeMinGP = (e) => {
    props.AppStore.statsFiltersMinGP.set(e.target.value);
    handleFilterSubmit();
    trackStatsFilter("changeMinGP", e.target.value);
  };

  const onChangeMapSide = (selectedList, selectedItem) => {
    props.AppStore.setMapSideFilter([selectedItem,]);
    if (!(didViewChange || didCategoryChange || didTournamentChange)) {
      // Ensures won't be tracked in analytics on routine filter-resets
      trackStatsFilter("changeMapSide", selectedItem.id);
    }
  };

  const onChangeWinLoss = (selectedList, selectedItem) => {
    props.AppStore.setWinLossFilter([selectedItem,]);
    if (!(didViewChange || didCategoryChange || didTournamentChange)) {
      // Ensures won't be tracked in analytics on routine filter-resets
      trackStatsFilter("changeWinLoss", selectedItem.id);
    }
  };

  const onChangePatchStart = (selectedList, selectedItem) => {
    props.AppStore.setPatchStartFilter([selectedItem,]);
    trackStatsFilter("changePatchStart", selectedItem.value);
  };
  const onChangePatchEnd = (selectedList, selectedItem) => {
    props.AppStore.setPatchEndFilter([selectedItem,]);
    trackStatsFilter("changePatchEnd", selectedItem.value);
  };
  const onChangeSoloQueuePatch = (selectedList, selectedItem) => {
    props.AppStore.setSoloQueuePatch(selectedItem.value);
    props.AppStore.soloQueueQueryFiltersChanged.set(true);
    trackStatsFilter("changeSoloQueuePatch", selectedItem.value);
  };

  const onSoloQueueChampionSelect = (selectedList, selectedItem) => {
    props.AppStore.setSoloQueueChampions(selectedList);
    // props.AppStore.soloQueueChampionFilterActivated.set(true);
    trackStatsFilter("selectSoloQueueChampion", selectedItem.value);
  };
  const onSoloQueueChampionRemove = (selectedList, removedItem) => {
    props.AppStore.setSoloQueueChampions(selectedList);
    // props.AppStore.soloQueueChampionFilterActivated.set(true);
    trackStatsFilter("removeSoloQueueChampion", removedItem.value);
  };

  const onLeagueSelect = (selectedList, selectedItem) => {
    props.AppStore.setLeagueFilter(selectedList);
    if (!(didViewChange || didCategoryChange || didTournamentChange)) {
      // Ensures won't be tracked in analytics on routine filter-resets
      trackStatsFilter("selectLeague", selectedItem.name);
    }
  };

  const onLeagueRemove = (selectedList, removedItem) => {
    props.AppStore.setLeagueFilter(selectedList);
    trackStatsFilter("removeLeague", removedItem.name);
  };

  return (
    <FiltersWrapper>
      {view === "explorer" && props.AppStore.leaguesAreFetched.get() && (
        <FiltersLeagueContainer>
          <Multiselect
            id="leagueFilter"
            options={leagueOptions}
            selectedValues={defaultLeagues}
            placeholder={props.AppStore.statsFiltersLeaguePlaceholder.get()}
            onSelect={onLeagueSelect}
            onRemove={onLeagueRemove}
            closeIcon="cancel"
            closeOnSelect={false}
            displayValue="name"
            ref={leaguesRef}
          />
        </FiltersLeagueContainer>
      )}
      {/*view === 'explorer' && (
        <FiltersYearContainer>
          <Multiselect
            id='yearFilter'
            options={filterYearOptions}
            selectedValues={defaultYear}
            singleSelect={true}
            placeholder='Year'
            onSelect={onChangeYear}
            onRemove={onChangeYear}
            closeIcon='cancel'
            closeOnSelect={false}
            displayValue='year'
          />
        </FiltersYearContainer>
      )*/}
      {(props.match.params.category !== "teams" && view !== "championPool") && (
        <FiltersPositionContainer
          activated={isViewingSoloQueueMatchups && props.AppStore.soloQueuePositionFilterActivated.get()}
          soloQueue={isViewingSoloQueueMatchups}
        >
          <Multiselect
            id="positionFilter"
            options={filterPositionOptions}
            singleSelect={isViewingSoloQueueMatchups ? true : false}
            placeholder={isViewingSoloQueueMatchups ? props.AppStore.soloQueueStatsFiltersPositionPlaceholder.get() : props.AppStore.statsFiltersPositionPlaceholder.get()}
            onSelect={onPositionSelect}
            onRemove={onPositionRemove}
            closeIcon="cancel"
            closeOnSelect={false}
            displayValue="position"
            ref={positionRef}
          />
        </FiltersPositionContainer>
      )}
      {(props.match.params.category !== "champions" && view !== "championPool") && (
        <FiltersTeamContainer>
          <Multiselect
            id="teamFilter"
            options={props.AppStore.statsFiltersTeamOptions.map((team) => {
              return { team: team, id: team };
            })}
            placeholder={props.AppStore.statsFiltersTeamPlaceholder.get()}
            onSelect={onTeamSelect}
            onRemove={onTeamRemove}
            closeIcon="cancel"
            closeOnSelect={false}
            displayValue="team"
            ref={teamRef}
          />
        </FiltersTeamContainer>
      )}
      {props.match.params.category === "players" && (
        <FiltersPlayerContainer>
          <Multiselect
            id="playerFilter"
            options={props.AppStore.statsFiltersPlayerOptions.map((player) => {
              return { player: player, id: player };
            })}
            placeholder={props.AppStore.statsFiltersPlayerPlaceholder.get()}
            onSelect={onPlayerSelect}
            onRemove={onPlayerRemove}
            closeIcon="cancel"
            closeOnSelect={false}
            displayValue="player"
            ref={playerRef}
          />
        </FiltersPlayerContainer>
      )}
      {props.match.params.category !== "teams" && !isViewingSoloQueueMatchups && (
        <FiltersGPContainer>
          <FiltersGPInput
            type="text"
            value={props.AppStore.statsFiltersMinGP.get()}
            onFocus={onFocusMinGP}
            onBlur={onFocusMinGP}
            onChange={onChangeMinGP}
            ref={gamesPlayedRef}
          />
        </FiltersGPContainer>
      )}
      {!isViewingSoloQueueMatchups && (
        <FiltersMapSideContainer
          activated={props.AppStore.mapSideFilterActivated.get()}
        >
          <Multiselect
            id="mapSideFilter"
            options={filterMapSideOptions}
            singleSelect={true}
            selectedValues={props.AppStore.statsFiltersMapSideCurrentMultiselect}
            onSelect={onChangeMapSide}
            onRemove={onChangeMapSide}
            closeIcon="cancel"
            closeOnSelect={true}
            // avoidHighlightFirstOption={true} NOT SURE HOW I FEEL ABOUT THIS
            displayValue="id"
            ref={mapSideRef}
          />
        </FiltersMapSideContainer>
      )}
      {!isViewingSoloQueueMatchups && (
        <FiltersWinLossContainer
          activated={props.AppStore.winLossFilterActivated.get()}
        >
          <Multiselect
            id="winLossFilter"
            options={filterWinLossOptions}
            singleSelect={true}
            selectedValues={props.AppStore.statsFiltersWinLossCurrentMultiselect}
            onSelect={onChangeWinLoss}
            onRemove={onChangeWinLoss}
            closeIcon="cancel"
            closeOnSelect={true}
            displayValue="id"
            ref={winLossRef}
          />
        </FiltersWinLossContainer>
      )}
      {isViewingSoloQueueMatchups && (
        <FiltersChampionContainer
          // activated={props.AppStore.soloQueueChampionFilterActivated.get()}
        >
          <Multiselect
            id="championFilter"
            options={soloQueueChampionFilterOptions}
            // singleSelect={true}
            selectionLimit={3}
            placeholder={props.AppStore.soloQueueStatsFiltersChampionPlaceholder.get()}
            onSelect={onSoloQueueChampionSelect}
            onRemove={onSoloQueueChampionRemove}
            closeIcon="cancel"
            closeOnSelect={true}
            displayValue="id"
            ref={soloQueueChampionRef}
          />
        </FiltersChampionContainer>
      )}
      {!isViewingSoloQueueMatchups && firstPatch && lastPatch && (
        <FiltersPatchContainer>
          <FiltersPatchStartContainer
            activated={props.AppStore.patchStartFilterActivated.get()}
          >
            <Multiselect
              id="patchStartFilter"
              options={lowerPatchFilterOptions}
              singleSelect={true}
              placeholder={defaultPatchStart[0].id}
              selectedValues={props.AppStore.statsFiltersPatchStartCurrentMultiselect}
              onSelect={onChangePatchStart}
              onRemove={onChangePatchStart}
              closeIcon="cancel"
              closeOnSelect={true}
              displayValue="id"
              ref={fromPatchRef}
            />
          </FiltersPatchStartContainer>
          <div className="toPatch">to</div>
          <FiltersPatchEndContainer
            activated={props.AppStore.patchEndFilterActivated.get()}
          >
            <Multiselect
              id="patchEndFilter"
              options={higherPatchFilterOptions}
              singleSelect={true}
              placeholder={defaultPatchEnd[0].id}
              selectedValues={props.AppStore.statsFiltersPatchEndCurrentMultiselect}
              onSelect={onChangePatchEnd}
              onRemove={onChangePatchEnd}
              closeIcon="cancel"
              closeOnSelect={true}
              displayValue="id"
              ref={toPatchRef}
            />
          </FiltersPatchEndContainer>
        </FiltersPatchContainer>
      )}
      {isViewingSoloQueueMatchups && firstPatch && lastPatch && (
        <FiltersPatchContainer>
          <FiltersPatchStartContainer
            activated={props.AppStore.soloQueuePatchFilterActivated.get()}
          >
            <Multiselect
              id="soloQueuePatchFilter"
              options={soloQueuePatchFilterOptions}
              singleSelect={true}
              placeholder={`Patch ${props.AppStore.soloQueueStatsFiltersPatchRange[0].patch}`}
              onSelect={onChangeSoloQueuePatch}
              onRemove={onChangeSoloQueuePatch}
              closeIcon="cancel"
              closeOnSelect={true}
              displayValue="id"
              ref={soloQueuePatchRef}
            />
          </FiltersPatchStartContainer>
        </FiltersPatchContainer>
      )}
      {!isViewingSoloQueueMatchups && (
        <FiltersDateRangeContainer>
          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId="start_date" // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId="end_date" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) =>
              props.AppStore.setDateRangeFilter(startDate, endDate)
            } // PropTypes.func.isRequired,
            focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) =>
              setState({ focusedInput: focusedInput })
            } // PropTypes.func.isRequired,
            isOutsideRange={() => false}
            displayFormat={() => "MMM DD, YYYY"}
            numberOfMonths={1}
            minimumNights={0}
            horizontalMargin={55}
            withFullScreenPortal={isMobile ? true : false}
          />
        </FiltersDateRangeContainer>
      )}
    </FiltersWrapper>
  );
};

export default withRouter(inject("AppStore")(observer(Filters)));
