import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import LeftSidebar from "./Sidebars/LeftSidebar";

const AboutWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 45em;
  font-size: 0.85em;
`;

const AboutContent = styled.div`
  text-align: left;
`;

const Banner = styled.img`
  max-width: 100%;
  margin-left: -1em;
`;

const AboutHero = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1em;
`;

const About = () => {
  const pageTitle = "About - Oracle's Elixir";
  const pageDescription =
    "About Oracle's Elixir, the premier source for advanced League of Legends esports stats.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <AboutWrapper className="mainContent">
        <AboutContent>
          <Banner src="/OE-Wordmark-Gradient.png" />
          <AboutHero>
            The premier source for advanced League of Legends esports stats.
          </AboutHero>
          <p>
            Since 2015, stats and analysis from <em>Oracle's Elixir</em> have
            been relied on by professional coaches, analysts, broadcasters,
            writers, daily fantasy competitors, and hardcore fans. Analytics
            provided by or innovated on <em>Oracle's Elixir</em> are routinely
            featured on professional broadcasts around the world and used in
            coverage from ESPN Esports, Dot Esports, Inven Global, theScore
            esports and many other outlets.
          </p>
          <p>
            Whether you follow the LCS, LEC, LCK, LPL, or other professional
            leagues, <em>Oracle's Elixir</em> is where you will find the most
            comprehensive, in-depth player, team, and champion analytics.
          </p>
          <p>
            If you have questions about this site and its contents, feel free to{" "}
            <Link to="/contact">contact the founder</Link>, Tim Sevenhuysen, or{" "}
            <a
              href="https://discord.gg/KY6XHaF"
              target="_blank"
              rel="noopener noreferrer"
            >
              join the Oracle's Elixir Discord server
            </a>
            .
          </p>
          <p>
            Some content is provided courtesy of{" "}
            <a
              href="https://lol.fandom.com/League_of_Legends_Esports_Wiki"
              target="_blank"
              rel="noopener noreferrer"
            >
              Leaguepedia
            </a>
            , under a{" "}
            <a
              href="https://creativecommons.org/licenses/by-sa/3.0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CC-BY-SA 3.0 license
            </a>
            .
          </p>
          <p>
            If usage terms regarding specific <em>Oracle's Elixir</em> content are unclear,{" "}
            <Link to="/contact">contact Tim Sevenhuysen</Link> with your questions to clarify what
            is and is not permitted.
          </p>
          <p>
            <br />
            <em>Oracle's Elixir</em> is not endorsed by Riot Games and doesn't
            reflect the views or opinions of Riot Games or anyone officially
            involved in producing or managing League of Legends. League of
            Legends and Riot Games are trademarks or registered trademarks of
            Riot Games, Inc. League of Legends © Riot Games, Inc.
          </p>
        </AboutContent>
      </AboutWrapper>
    </>
  );
};

export default About;
