import request from 'superagent';
// import * as APIFactory from '../factories/api';

const ENDPOINT = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

/**
 * Create a user
 * @param {*} authToken Session auth token of user to create
 * @param {*} username Username of account to create
 * @param {*} email Email address of account to create
 */
 export async function createAccount(authToken, username, email) {
  const queryUri = encodeURI(`${ENDPOINT}/users/createUser`)
  try {
    const res = await request
      .post(queryUri)
      .send({
        username: username,
        email: email
      })
      .set('X-Api-Key', API_KEY)
      .set('authorization', `Bearer ${authToken}`)
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Change your username
 * @param {*} authToken Session auth token of user to create
 * @param {*} username New username
 */
 export async function changeUsername(authToken, username) {
  const queryUri = encodeURI(`${ENDPOINT}/users/changeUsername`)
  try {
    const res = await request
      .post(queryUri)
      .send({
        username: username
      })
      .set('X-Api-Key', API_KEY)
      .set('authorization', `Bearer ${authToken}`)
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get a user's info
 * @param {*} authToken Session auth token of user to fetch
 */
export async function getUserInfo(authToken) {
  const queryUri = encodeURI(`${ENDPOINT}/users/userInfo`)
  try {
    const res = await request
      .get(queryUri)
      .set('X-Api-Key', API_KEY)
      .set('authorization', `Bearer ${authToken}`);
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get a user's info
 * @param authToken Session auth token of user to fetch
 * @param leagues Comma-separated string of league IDs
 */
 export async function changeQuickAccess(authToken, leagues) {
  const queryUri = encodeURI(`${ENDPOINT}/users/changeQuickAccessLeagues`);
  try {
    const res = await request
      .post(queryUri)
      .send({leagues: leagues})
      .set('X-Api-Key', API_KEY)
      .set('authorization', `Bearer ${authToken}`);
    return res;
  } catch (error) {
    return error;
  }
}

/**
 * Get a user's info
 * @param leagues Comma-separated string of league IDs
 */
 export async function getQuickAccess(leagues) {
  const queryUri = encodeURI(`${ENDPOINT}/tournaments/quickAccess?${leagues.split(",").map(league => `leagues=${league}`).join('&')}`);
  try {
    const res = await request
      .get(queryUri)
      .set('X-Api-Key', API_KEY);
    return res;
  } catch (error) {
    return error;
  }
}