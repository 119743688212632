import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { withFirebase } from '../Firebase';

import { Helmet } from "react-helmet";
import LeftSidebar from "../Sidebars/LeftSidebar";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const PasswordInput = styled.input`
  margin-right: 1em;
`

const ResetPasswordPage = (props) => {
  const passwordOne = props.AppStore.userStore.passwordOne.get();
  const passwordTwo = props.AppStore.userStore.passwordTwo.get();
  const error = props.AppStore.userStore.changePasswordError.get();
  const passwordHasBeenChanged = props.AppStore.userStore.passwordHasBeenChanged.get();

  const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '';

  const submitChangePassword = (event) => {
    event.preventDefault();

    props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        props.AppStore.userStore.passwordHasBeenChanged.set(true);
        setTimeout(() => { props.AppStore.userStore.passwordHasBeenChanged.set(false) }, 4000);
        setTimeout(() => { props.history.push("/profile"); }, 4000);
      })
      .catch(error => {
        props.AppStore.userStore.changePasswordError.set(error.message);
      });
  };

  const onChange = (event) => {
    props.AppStore.userStore[event.target.name].set(event.target.value);
  };

  const pageTitle = "League of Legends esports stats - Oracle's Elixir";
  const pageDescription = "Change password for Oracle's Elixir: advanced League of Legends esports stats from LCS, LEC, LCK, LPL, and the rest of global pro LoL";

  return (
    <>
      <Helmet>
        <title>League of Legends esports stats - Oracle's Elixir</title>
        <meta
          name="description"
          content="Advanced League of Legends esports stats, with statistics and analytics from LCS, LEC, LCK, LPL, and the rest of global pro LoL."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        <h1>Change Password</h1>
        {passwordHasBeenChanged ? (
          <div>Password has been changed. Redirecting to <Link to="/profile">user profile</Link>.</div>
        ) : (
          <div>
            <form onSubmit={e => submitChangePassword(e)}>
              <PasswordInput
                name="passwordOne"
                value={passwordOne}
                onChange={e => onChange(e)}
                type="password"
                placeholder="Password"
              />
              <PasswordInput
                name="passwordTwo"
                value={passwordTwo}
                onChange={e => onChange(e)}
                type="password"
                placeholder="Confirm Password"
              />
              <button disabled={isInvalid} type="submit">
                Change Password
              </button>

              {error && <p>{error}</p>}
            </form>
          </div>
        )}
      </Wrapper>
    </>
  )
};

export default inject("AppStore")(withRouter(withFirebase(observer(ResetPasswordPage))));