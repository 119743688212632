import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import styled from 'styled-components';
import { getQuickAccess } from '../../lib/userApi';

import RecentVods from './RecentVods';
import SpinnerComponent from "../Shared/Spinners";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 14rem;
  max-width: 14rem;
  margin-left: 2em;

  @media (max-width: 768px) {
    margin-bottom: 2em;
    margin-left: 0;
    max-width: 100%;
  }
`

const ContainerTitle = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
`

const QuickAccessItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: .25rem;

  @media (max-width: 768px) {
    flex: 1;
    flex-direction: column;
    padding-left: 0;
  }
`

const LeaguesQuickAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.height ? `max-height: ${props.height}` : null};
  padding: .25rem .25rem .25rem 0;
  font-size: .8rem;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    padding: 0;
  }
`

const QuickAccessTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: 768px) {
    display: none;
  }
`

const QuickAccessItemName = styled.div`
  font-family: 'Roboto Mono', 'Lato', 'sans-serif';
  font-weight: bold;
  line-height: 1.5em;
  margin-right: .25rem;
  width: 3em;
`

const QuickAccessItemContent = styled.div`
  flex: 1;
  line-height: 1.2rem;

  @media (max-width: 768px) {
    text-align: left;
  }
`

export const MajorLeaguesQuickAccess = inject("AppStore")(observer(({ AppStore }) => {
  const quickAccessUsingDefault = AppStore.quickAccessUsingDefault.get();
  const userQuickAccessIsFetching = AppStore.userStore.userQuickAccessIsFetching.get();
  const userQuickAccessHasBeenFetched = AppStore.userStore.userQuickAccessHasBeenFetched.get();
  const userQuickAccessLeagues = AppStore.userStore.userQuickAccessLeagues.get();
  const quickAccessInView = AppStore.quickAccessInView.get();

  const wasQuickAccessChanged = AppStore.userStore.quickAccessChanged.get();

  useEffect(() => {
    const fetchUserQuickAccess = (async (leagues) => {
      AppStore.userStore.userQuickAccessIsFetching.set(true);
      AppStore.userStore.quickAccessChanged.set(false);
      const userQuickAccessResponse = await getQuickAccess(leagues);
      if (userQuickAccessResponse.body.length > 0) {
        AppStore.setQuickAccessInView(userQuickAccessResponse.body);
        AppStore.quickAccessUsingDefault.set(false);
        AppStore.userStore.userQuickAccessIsFetching.set(false);
        AppStore.userStore.userQuickAccessHasBeenFetched.set(true);
      }
    })

    const defaultQuickAccessLeagues = [
      // {league: 'WLDs', tournament: encodeURIComponent('2021%20Season%20World%20Championship%2FMain%20Event')},
      {league: 'LCS', tournament: encodeURIComponent('LCS%2F2024%20Season%2FSummer%20Season')},
      {league: 'LEC', tournament: encodeURIComponent('LEC%2F2024%20Season%2FSummer%20Season')},
      {league: 'LCK', tournament: encodeURIComponent('LCK%2F2024%20Season%2FSummer%20Season')},
      {league: 'LPL', tournament: encodeURIComponent('LPL%2F2024%20Season%2FSummer%20Placements')}
    ];

    if (AppStore.userStore.userInfo.get() && userQuickAccessLeagues && (quickAccessUsingDefault || wasQuickAccessChanged) && !userQuickAccessIsFetching) {
      fetchUserQuickAccess(userQuickAccessLeagues);
    } else if (quickAccessInView === null || (!quickAccessUsingDefault && !userQuickAccessLeagues)) {
      AppStore.setQuickAccessInView(defaultQuickAccessLeagues);
      AppStore.quickAccessUsingDefault.set(true);
    }
  }, [
    AppStore,
    userQuickAccessIsFetching,
    quickAccessUsingDefault,
    userQuickAccessLeagues,
    quickAccessInView,
    wasQuickAccessChanged
  ])

  return (
    (
      AppStore.sessionStore.authStatusHasBeenChecked.get()
      && !userQuickAccessIsFetching
      && (
        (AppStore.sessionStore.authUser.get() && userQuickAccessHasBeenFetched)
        || !AppStore.sessionStore.authUser.get()
      )
    ) ? (
      <>
        <LeaguesQuickAccessContainer>
          <QuickAccessTitle>Most Recent Split</QuickAccessTitle>
          {quickAccessInView && quickAccessInView.map((league) => {
            return (
              <React.Fragment key={`${league.league}-row`}>
                <QuickAccessItemContainer>
                  <QuickAccessItemName>{league.league}</QuickAccessItemName>
                  <QuickAccessItemContent>
                    <Link to={`/stats/players/byTournament/${league.tournament}`}>Players</Link>
                    <span className='hideOnMobile'>/</span>
                    <br className='showOnMobile' />
                    <Link to={`/stats/teams/byTournament/${league.tournament}`}>Teams</Link>
                    <span className='hideOnMobile'>/</span>
                    <br className='showOnMobile' />
                    <Link to={`/stats/champions/byTournament/${league.tournament}`}>Champs</Link>
                  </QuickAccessItemContent>
                </QuickAccessItemContainer>
              </React.Fragment>
            )
          })}
        </LeaguesQuickAccessContainer>
      </>
    ) : (
      <LeaguesQuickAccessContainer height='10em'>
        <SpinnerComponent />
      </LeaguesQuickAccessContainer>
    )
  )
}))

const QuickAccess = ({ AppStore }) => {
  const quickAccessReady = AppStore.dataLinksAreFetched.get()

  return (
    quickAccessReady && (
      <Container>
        <ContainerTitle>Quick Access</ContainerTitle>
        <MajorLeaguesQuickAccess />
        <RecentVods />
      </Container>
    )
  )
}

export default inject("AppStore")(observer(QuickAccess));
